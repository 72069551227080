import React, { memo, useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Table, Modal, Checkbox } from "antd";
import { useIntl } from "react-intl";
import {
    colLeft,
} from "@iso/assets/styles/form.style";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import selectApiRoomActions from "@iso/redux/selectApiRoom/actions";
import SelectApiRoom from "@iso/containers/SelectApiRoom/SelectApiRoom";
import TableSettingPriceByPerson from "./TableSettingPriceByPerson";
import TableSettingPriceByRoom from "./TableSettingPriceByRoom";
import { isEmpty } from "lodash";
import selectApiRoomService from "@iso/redux/selectApiRoom/actions";
import {
    OTA_PLAN_RAKUTEN
} from "@iso/constants/api.constant";

const RoomRakutenExtendFields = ({
    storeId,
    otaId,
    dataOtas,
    setFieldValue,
    tabKey,
    setEventKodomoSubmit,
    isCloneData,
    activeTabKey
}) => {
    const { messages } = useIntl();
    const [listApiRoom, setListApiRoom] = useState([]);
    const [listApiRoomCurrent, setListApiRoomCurrent] = useState([]);
    const [dataApiRooms, setDataApiRooms] = useState([]);
    const [isCancelInsertKodomo, setIsCancelInsertKodomo] = useState(false);
    const dispatch = useDispatch();
    const {
        SelectApiRoom: { apiRooms },
    } = useSelector((state) => state);

    useEffect(() => {
        if (Array.isArray(apiRooms) && apiRooms.length > 0) {
            let filteredArray = apiRooms.filter(item => item.siteRoomId !== null);
            setDataApiRooms(filteredArray);
        }
    }, [apiRooms])

    useEffect(() => {
        if (dataOtas.otaFieldContent
            && dataOtas.otaFieldContent[tabKey]
            && typeof dataOtas.otaFieldContent[tabKey] === "object"
            && dataOtas.otaFieldContent[tabKey] !== null
            && dataOtas.otaFieldContent[tabKey] !== undefined) {

            let apiRoomOtas = dataOtas.otaFieldContent[tabKey].apiRoomOtas;
            if (apiRoomOtas != null && apiRoomOtas.length > 0) {
                setListApiRoom(apiRoomOtas);
                setListApiRoomCurrent(apiRoomOtas.map(x => x.apiRoomOtaId))
                if (isCloneData) {
                    setFieldValue(`otaFieldContent.${tabKey}.roomOtaToAdd`, apiRoomOtas.map(x => x.apiRoomOtaId))
                }
            }

            delete dataOtas.otaFieldContent[tabKey].apiRoomOtas;
        }
    }, [dispatch, dataOtas.otaFieldContent[tabKey]]);

    useEffect(() => {
        if (dataOtas.otaFieldContent[tabKey] != null
            && dataOtas.otaFieldContent[tabKey] != undefined
        ) {
            if (!dataOtas.otaFieldContent[tabKey].kodomo
                || dataOtas.otaFieldContent[tabKey].kodomo == null
                || dataOtas.otaFieldContent[tabKey].kodomo == undefined
                || isEmpty(dataOtas.otaFieldContent[tabKey].kodomo)
            ) {
                const arrayKodomo = {}
                listApiRoom.map((apiRoom) => {
                    arrayKodomo[`${apiRoom.siteRoomId}`] = {}
                })

                setFieldValue(`otaFieldContent.${tabKey}.kodomo`, arrayKodomo)
            }

            if (!isEmpty(dataOtas.otaFieldContent[tabKey].kodomo)) {
                const dataUpdateKodomo = listApiRoom.reduce((item, apiRoom) => {
                    const key = `${apiRoom.siteRoomId}`;
                    const content = dataOtas.otaFieldContent[tabKey]?.kodomo[key];
                    if (content) {
                        item[key] = content;
                    }
                    return item;
                }, {});

                setFieldValue(`otaFieldContent.${tabKey}.kodomo`, dataUpdateKodomo)
            }
        }
    }, [listApiRoom])

    const showApiRoomSelectPopup = useCallback(() => {
        dispatch(selectApiRoomActions.toggleSelectApiRoomOta());
        dispatch(selectApiRoomService.fetchApiRooms({ storeId, otaId }));
    }, [dispatch, otaId, storeId]);

    const handleFieldChange = useCallback(
        (setFieldValue, name) => (value) => {
            if (!Array.isArray(value)) {
                value = [];
            }
            let elementsToDelete = listApiRoomCurrent.filter(element => !value.includes(element));
            let elementsToAdd = isCloneData ? value : value.filter(element => !listApiRoomCurrent.includes(element));
            setFieldValue(`otaFieldContent.${tabKey}.roomOtaToDelete`, elementsToDelete)
            setFieldValue(`otaFieldContent.${tabKey}.roomOtaToAdd`, elementsToAdd)
        },
        [listApiRoomCurrent]
    );

    const sortRoom = (dataSource) => {
        return dataSource.sort((a, b) => b.apiRoomOtaId - a.apiRoomOtaId)
    }

    const [moneyRegisterVisible, setMoneyRegisterVisible] = useState(false);
    const [editingRow, setEditingRow] = useState(null);

    const showMoneyRegisterPopup = (index) => {
        setMoneyRegisterVisible(true);
        setEditingRow(index)
    };

    const closeMoneyRegisterPopup = () => {
        setMoneyRegisterVisible(false);
        setIsCancelInsertKodomo(true);
        if (isCancelInsertKodomo
            && dataOtas.otaFieldContent
            && dataOtas.otaFieldContent[tabKey]
            && typeof dataOtas.otaFieldContent[tabKey] === "object"
            && dataOtas.otaFieldContent[tabKey] !== null
            && dataOtas.otaFieldContent[tabKey] !== undefined) {
            setFieldValue(`otaFieldContent.${tabKey}.kodomo`, dataOtas.otaFieldContent[tabKey].kodomo)
            setIsCancelInsertKodomo(false);
        }
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const handleSelect = (record) => {
        if (record.target.checked) {
            setSelectedRowKeys((keys) => [...keys, record.target.value]);
        } else {
            setSelectedRowKeys((keys) => {
                const index = keys.indexOf(record.target.value);
                return [...keys.slice(0, index), ...keys.slice(index + 1)];
            });
        }
    };

    const toggleSelectAll = () => {
        setSelectedRowKeys((keys) =>
            keys.length === listApiRoom.length ? [] : listApiRoom.map((r) => r.siteRoomId)
        );
    };

    return (
        <>
            <Col xs={24} lg={{ span: 4, offset: 0 }}>
                <label style={{ color: '#000000d9' }} >{messages["page.apiPlanAddAndEdit.apiRoom.titleRoom"]}</label>
            </Col>
            <Col xs={24} lg={{ span: 18, offset: 0 }}>
                <Row>
                    <Col xs={24} lg={{ span: 18, offset: 0 }}>
                        <Table
                            columns={generateColumns(
                                messages,
                                moneyRegisterVisible,
                                showMoneyRegisterPopup,
                                closeMoneyRegisterPopup,
                                editingRow,
                                setFieldValue,
                                tabKey,
                                dataOtas,
                                setEventKodomoSubmit,
                                otaId,
                                isCancelInsertKodomo,
                                selectedRowKeys,
                                handleSelect,
                                toggleSelectAll,
                                listApiRoom
                            )}
                            bordered
                            pagination={false}
                            dataSource={sortRoom(listApiRoom)}
                            scroll={listApiRoom !== null && listApiRoom !== undefined && listApiRoom.length > 6 ? { y: "330px" } : ''}
                        >
                        </Table>
                    </Col>

                    <Col xs={24} lg={{ span: 3, offset: 0 }} className="ml-2">
                        <Button
                            type="primary"
                            name="roomIds"
                            className="btnSelectApiRoom"
                            onClick={showApiRoomSelectPopup}
                        >
                            {messages["page.apiPlanAddAndEdit.apiRoom.button"]}
                        </Button>
                    </Col>
                </Row>
            </Col>


            <SelectApiRoom
                onSelect={handleFieldChange(setFieldValue, `otaFieldContent.${tabKey}.roomOtaIds`)}
                selected={listApiRoom.map(x => x.apiRoomOtaId)}
                storeId={storeId}
                otaId={otaId}
                listApiRoom={listApiRoom}
                setListApiRoom={setListApiRoom}
                apiRooms={dataApiRooms}
                activeTabKey={activeTabKey}
                setFieldValue={setFieldValue}
            />
        </>
    );
};

const generateColumns = (
    messages,
    moneyRegisterVisible,
    showMoneyRegisterPopup,
    closeMoneyRegisterPopup,
    editingRow,
    setFieldValue,
    tabKey,
    dataOtas,
    setEventKodomoSubmit,
    otaId,
    isCancelInsertKodomo,
    selectedRowKeys,
    handleSelect,
    toggleSelectAll,
    listApiRoom
) => {
    const colums = [
        {
            title: "No.",
            dataIndex: "apiRoomOtaId",
            key: "apiRoomOtaId",
            align: "center",
            width: 70,
        },
        {
            title: messages["page.apiPlanAddAndEdit.apiRoom.roomName"],
            dataIndex: "roomName",
            key: "roomName",
            align: "center",
        },
        {
            title: messages["page.apiPlanAddAndEdit.apiRoom.createdAt"],
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
        },
        {
            title: messages["page.apiPlanAddAndEdit.apiRoom.createdName"],
            dataIndex: "createdName",
            key: "createdName",
            align: "center",
        }
    ];

    if (otaId == OTA_PLAN_RAKUTEN) {
        const checkBoxGroupColumn = {
            title: () => {
                return (
                    <Checkbox
                        checked={selectedRowKeys.length}
                        indeterminate={
                            selectedRowKeys.length > 0 && selectedRowKeys.length < listApiRoom.length
                        }
                        onChange={toggleSelectAll}
                    />
                )
            },
            dataIndex: "siteRoomId",
            key: "siteRoomId",
            className: "button-check-box",
            align: "center",
            render: (id) => {
                return (
                    <Checkbox
                        value={id}
                        onChange={handleSelect}
                        checked={selectedRowKeys.includes(id)}
                    />
                );
            },
        }

        const kodomoColumn = {
            title: messages["page.room.moneyRegister.basicPriceSetting"],
            dataIndex: "roomPriceTypeFormat",
            key: "roomPriceTypeFormat",
            align: "center",
            render: (values, row, index) => {
                const siteRoomId = row.siteRoomId;
                const typeMoneyPlan = dataOtas?.otaFieldContent?.[tabKey]?.kodomo?.[siteRoomId]?.priceTypeFormat;
                const typeMoney = typeMoneyPlan != null ? typeMoneyPlan : values;

                return (
                    <>
                        <Button
                            type="primary"
                            name="roomIds"
                            className="btnSelectApiRoom"
                            onClick={() => showMoneyRegisterPopup(index)}
                        >
                            {messages["page.room.moneyRegister.basicPriceSetting"]}
                        </Button>

                        <Modal
                            visible={(editingRow == index) && moneyRegisterVisible}
                            title={messages["page.room.moneyRegister.childSetting"]}
                            onCancel={closeMoneyRegisterPopup}
                            width={900}
                            footer={null}
                        >
                            {(typeMoney == messages["page.apiPlan.rakuten.perPerson"]) && (
                                <TableSettingPriceByPerson
                                    setFieldValue={setFieldValue}
                                    dataRoom={row}
                                    tabKey={tabKey}
                                    dataOtaTab={{ ...dataOtas.otaFieldContent[tabKey] }}
                                    closeMoneyRegisterPopup={closeMoneyRegisterPopup}
                                    setEventKodomoSubmit={setEventKodomoSubmit}
                                    isCancelInsertKodomo={isCancelInsertKodomo}
                                    selectedRowKeys={selectedRowKeys}
                                    listApiRoom={listApiRoom}
                                />
                            )}

                            {(typeMoney == messages["page.apiPlan.rakuten.perRoom"]) && (
                                <TableSettingPriceByRoom
                                    setFieldValue={setFieldValue}
                                    dataRoom={row}
                                    tabKey={tabKey}
                                    dataOtaTab={{ ...dataOtas.otaFieldContent[tabKey] }}
                                    closeMoneyRegisterPopup={closeMoneyRegisterPopup}
                                    setEventKodomoSubmit={setEventKodomoSubmit}
                                    isCancelInsertKodomo={isCancelInsertKodomo}
                                    selectedRowKeys={selectedRowKeys}
                                    listApiRoom={listApiRoom}
                                />
                            )}

                            {(typeMoney != messages["page.apiPlan.rakuten.perPerson"] && typeMoney != messages["page.apiPlan.rakuten.perRoom"]) && (
                                messages["page.apiPlan.rakuten.noDetail"]
                            )}
                        </Modal>
                    </>
                )
            }
        }

        colums.unshift(checkBoxGroupColumn)
        colums.push(kodomoColumn);
    }


    return colums;
};

RoomRakutenExtendFields.propTypes = {
    storeId: PropTypes.any,
    otaId: PropTypes.any,
    values: PropTypes.object,
    previewMode: PropTypes.bool,
    setFieldValue: PropTypes.any,
    tabKey: PropTypes.any,
    isCloneData: PropTypes.any
};
export default memo(RoomRakutenExtendFields);
