import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import {apiCrawlRoomService, taskService} from "@iso/services";
import actions from "@iso/redux/apiCrawlRoom/actions";
import {LIST_OPEN_SALE_STATUS} from "@iso/constants/api.constant";

function* getListCrawlRoom() {
  yield takeEvery(actions.GET_CRAWL_ROOM_REQUEST, function* ({ payload }) {
    const {
      storeIds,
      keyword,
      keywordTypes,
      otaIds,
      createdUsers,
      createdAtStart,
      createdAtEnd,
      size,
      page,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlRoomService.getListCrawlRoom, {
        storeIds,
        keyword,
        keywordTypes,
        otaIds,
        createdUsers,
        createdAtStart,
        createdAtEnd,
        size,
        page,
        resolve,
        reject,
      });

      if (response && response.apiCrawlRooms) {
        response.apiCrawlRooms.map((crawlRoom) => {
          if (crawlRoom.openSaleStatus !== ""
              && crawlRoom.openSaleStatus !== null
              && crawlRoom.openSaleStatus !== undefined
          ) {
            let arrayOpenSale = crawlRoom.openSaleStatus.split(",");
            crawlRoom.openSaleStatus = arrayOpenSale.map(item => {
              const match = LIST_OPEN_SALE_STATUS.find(obj => obj.id === parseInt(item));
              return match ? match.name : item;
            }).join(", ");
          }
        })
      }
      yield put({
        type: actions.GET_CRAWL_ROOM_SUCCESS,
        apiCrawlRooms: response ? response.apiCrawlRooms : [],
        total: response ? response.total : 0
      });
      yield resolve(response.apiCrawlRooms);
    } catch (e) {
      yield put({
        type: actions.GET_CRAWL_ROOM_ERROR,
        error: e.message,
      });
    }
  });
}


function* crawlRoom() {
  yield takeEvery(actions.CRAWL_ROOM_REQUEST, function* ({ payload }) {
    const {
      storeId,
      keyword,
      keywordType,
      otaId,
      columnCrawls,
      typeCrawl,
      crawlRoomId,
      openSaleStatusSearch,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlRoomService.crawlRoom, {
        storeId,
        keyword,
        keywordType,
        otaId,
        columnCrawls,
        typeCrawl,
        crawlRoomId,
        openSaleStatusSearch,
        resolve,
        reject,
      });
      yield put({
        type: actions.CRAWL_ROOM_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.CRAWL_ROOM_ERROR,
        error: e.message,
      });
      reject(e)
    }
  });
}

function* getListUser() {
  yield takeEvery(actions.GET_LIST_USER_REQUEST, function* ({ payload }) {
    const { list_role } = payload;
    try {
      const [assignees] = yield all([
        call(taskService.getAssignees, { list_role }),
      ]);
      yield put({
        type: actions.GET_LIST_USER_SUCCESS,
        listUser: assignees ? assignees.accounts : [],
      });
    } catch (e) {
      yield put({
        type: actions.GET_LIST_USER_ERROR,
        error: e.message,
      });
    }
  });
}

function* crawlRoomAll() {
  yield takeEvery(actions.CRAWL_ROOM_ALL_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaIds,
      openSaleStatus,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlRoomService.crawlRoomAll, {
        storeId,
        otaIds,
        openSaleStatus,
        resolve,
        reject,
      });
      yield put({
        type: actions.CRAWL_ROOM_ALL_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.CRAWL_ROOM_ALL_ERROR,
        error: e.message,
      });
      reject(e)
    }
  });
}

function* crawlRoomDetails() {
  yield takeEvery(actions.CRAWL_ROOM_DETAILS_REQUEST, function* ({ payload }) {
    const {
      arrayApiRoomOtaId,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlRoomService.crawlRoomDetails, {
        arrayApiRoomOtaId,
        resolve,
        reject,
      });
      yield put({
        type: actions.CRAWL_ROOM_DETAILS_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.CRAWL_ROOM_DETAILS_ERROR,
        error: e.message,
      });
      reject(e)
    }
  });
}

function* openSaleMultipleRooms() {
  yield takeEvery(actions.OPEN_SALE_MULTIPLE_ROOMS_REQUEST, function* ({ payload }) {
    const {
      apiRoomOtaIds,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlRoomService.openSaleMultipleRooms, { apiRoomOtaIds });
      yield put({
        type: actions.OPEN_SALE_MULTIPLE_ROOMS_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.OPEN_SALE_MULTIPLE_ROOMS_ERROR,
        error: e.message,
      });
      reject(e)
    }
  });
}

function* stopSellingMultipleRooms() {
  yield takeEvery(actions.STOP_SELLING_MULTIPLE_ROOMS_REQUEST, function* ({ payload }) {
    const {
      apiRoomOtaIds,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlRoomService.stopSellingMultipleRooms, { apiRoomOtaIds });
      yield put({
        type: actions.STOP_SELLING_MULTIPLE_ROOMS_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.STOP_SELLING_MULTIPLE_ROOMS_ERROR,
        error: e.message,
      });
      reject(e)
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getListCrawlRoom),
    fork(crawlRoom),
    fork(getListUser),
    fork(crawlRoomAll),
    fork(crawlRoomDetails),
    fork(openSaleMultipleRooms),
    fork(stopSellingMultipleRooms),
  ]);
}
