import { all, put, fork, call, takeLatest } from "redux-saga/effects";

import { teamService } from "@iso/services";
import actions from "@iso/redux/team/actions";

export function* getTeams() {
  yield takeLatest(actions.GET_TEAM_REQUEST, function* ({payload}) {
    const {
      searchKeyword,
      parentTeamId,
      isActive,
      size,
      page,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(teamService.getTeams, {
        searchKeyword,
        parentTeamId,
        isActive,
        size,
        page,
        resolve,
        reject,
      })
      resolve(response)
      yield put({
        type: actions.GET_TEAM_SUCCESS,
        teams: response ? response.teams : [],
        total: response ? response.total : 0
      });
    } catch (e) {
      yield put({type: actions.GET_TEAM_ERROR});
      reject(e)
    }
  })
}

function* createTeam() {
  yield takeLatest(actions.CREATE_TEAM_REQUEST, function* ({ payload }) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(teamService.createTeam, {...data});
      yield put({
        type: actions.CREATE_TEAM_SUCCESS,
      });
      resolve(response);
    } catch (error) {
      yield put({
        type: actions.CREATE_TEAM_ERROR,
        error: error.response,
      });
      reject(error);
    }
  });
}

function* updateTeam() {
  yield takeLatest(actions.UPDATE_TEAM_REQUEST, function* ({ payload }) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(teamService.updateTeam, {...data});
      yield put({
        type: actions.UPDATE_TEAM_SUCCESS,
      });
      resolve(response);
    } catch (error) {
      yield put({
        type: actions.UPDATE_TEAM_ERROR,
        error: error.response,
      });
      reject(error);
    }
  });
}

function* deleteTeam() {
  yield takeLatest(actions.DELETE_TEAM_REQUEST, function* ({ payload }) {
    const { teamId, resolve, reject } = payload;
    try {
      const response = yield call(teamService.deleteTeam, teamId);
      yield put({
        type: actions.DELETE_TEAM_SUCCESS,
      });
      resolve(response);
    } catch (error) {
      yield put({
        type: actions.DELETE_TEAM_ERROR,
        error: error.response,
      });
      reject(error);
    }
  });
}

export function* getTeamDetail() {
  yield takeLatest(actions.DETAIL_TEAM_REQUEST, function* ({payload}) {
    const {resolve, reject, teamId} = payload;
    try {
      let response = null;
      if (teamId !== null && teamId !== undefined) {
        response = yield call(teamService.fetchTeam, {
          teamId
        })

        if (response.executedTeam !== null && response.executedTeam !== undefined) {
          response.executedTeam = response.executedTeam.split(",")
        } else {
          response.executedTeam = []
        }
      }
      resolve(response)
      yield put({
        type: actions.DETAIL_TEAM_SUCCESS,
        team: response
      });
    } catch (e) {
      yield put({type: actions.DETAIL_TEAM_ERROR});
      reject(e)
    }
  })
}

export function* getParentTeams() {
  yield takeLatest(actions.GET_PARENT_TEAM_REQUEST, function* () {
    try {
      const response = yield call(teamService.getParentTeams)
      yield put({
        type: actions.GET_PARENT_TEAM_SUCCESS,
        teams: response ? response.teams : []
      });
    } catch (e) {
      yield put({type: actions.GET_PARENT_TEAM_ERROR});
    }
  })
}

export function* getTeamOptions() {
  yield takeLatest(actions.GET_TEAM_OPTION_REQUEST, function* () {
    try {
      const response = yield call(teamService.getTeamOptions)
      yield put({
        type: actions.GET_TEAM_OPTION_SUCCESS,
        teams: response ? response.teams : []
      });
    } catch (e) {
      yield put({type: actions.GET_TEAM_OPTION_ERROR});
    }
  })
}

export function* getListPersonInCharge() {
  yield takeLatest(actions.GET_PERSON_IN_CHARGE_REQUEST, function* () {
    try {
      const response = yield call(teamService.getListPersonInCharge)
      yield put({
        type: actions.GET_PERSON_IN_CHARGE_SUCCESS,
        personInChargeList: response.personInCharge ? response.personInCharge : []
      });
    } catch (e) {
      yield put({type: actions.GET_PERSON_IN_CHARGE_ERROR});
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getTeams),
    fork(createTeam),
    fork(updateTeam),
    fork(deleteTeam),
    fork(getTeamDetail),
    fork(getParentTeams),
    fork(getTeamOptions),
    fork(getListPersonInCharge),
  ]);
}
