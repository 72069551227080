export const API_ENDPOINTS = {
  LOGIN: "/login",
  CURRENT_USER: "/currentuser",
  ACCOUNT_SETTING: "/accountsetting",
  ACCOUNTS: "/accounts",
  RESET_PASS: "/resetpass",
  ACCOUNT_PASSWORD: "/account/password",
  CONFIRM_EMAIL: "/account/confirm-email",
  ACCOUNT: "/account",
  CHAINS: "/chains",
  DELETE_ACCOUNT:"/delete",
  STORES: "/stores",
  ACCOUNT_IN_STORES: "/store/accounts",
  PASSWORD_HISTORIES: "/passwordhistories",
  LIST_OTA: "/otas",
  OTA_ACCOUNTS: "otaaccounts",
  STORE_SEARCH: "/store/search",
  CHAIN_SEARCH: "/chains/search",
  CHAIN: "/chain",
  DELETE_CHAIN: "/chain/delete",
  DIRECTORS: "/directors",
  SITE_CONTROLLERS: "/sitecontrollers",
  STORE: "/store",
  ALL_STORES: "/store/all",
  LIST_DIRECTORS: "/listdirectors/{currentDirector}",
  SELECT_STORES: "/selectstores",
  TASKS_SEARCH: "/tasks/search",
  TASKS_GANTT_CHART: "/tasks/taskGanttChart",
  LIST_USERS: "/listusers",
  LIST_REGISTERS: "/listregisters",
  TASK_ATTACH_UPLOAD: "/tasks/attach/upload",
  TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY: "/tasks/{taskId}/attach/upload-by-sub-category",
  TASK_ATTACH_DOWNLOAD: "/tasks/{taskId}/attach/download?attachId=",
  TASK_ATTACH_DELETE: "/tasks/{taskId}/attach/{taskAttachId}/type/{typeAttach}",
  TASK_TASKLOGS: "/tasks/{taskId}/tasklogs",
  TASK_TASKLOG_SUMMARIES: "/tasks/{taskId}/usertasksummaries",
  TASK: "/task",
  TASKS: "/tasks",
  CATEGORIES: "/categories",
  TASK_COMMENTS: "/tasks/{taskId}/comments",
  USERS_MENTION_TASK: "tasks/{taskId}/usermentions",
  ADD_COMMENTS: "/comment",
  EDIT_COMMENT: "/editcomment/{commentId}",
  USERS: "/users",
  TASK_CHILDREN: "/tasks/{taskId}/childs",
  TASK_TASKLOGS_LATEST: "/tasks/{taskId}/tasklogs/latest",
  TASK_STOPWATCH: "/tasks/{taskId}/stopwatch",
  TASK_TOTAL_TIMES: "/tasks/{taskId}/totaltimes",
  TASK_EDIT_TASKLOG: "/tasks/{taskId}/tasklogs/{taskLogId}",
  TASK_EDIT_STATUS: "/tasks/{task_id}/status",
  HELP_MANUAL_UPLOAD: "/help/manual/upload",
  HELP_MANUAL_DOWNLOAD: "/help/manual/download?jwt=",
  DASHBOARD_PENDING_TASKS: "/dashboard/pendingtasks",
  DASHBOARD_STOPWATCHS: "/dashboard/stopwatchs",
  DASHBOARD_CHILDREN_TASK: "/dashboard/pendingtasks/{taskId}/childs",
  HELP_HELP_CHAIN_INFO: "/help/manual/chain-info",
  GET_HELP_STORE_INFO: "/help/manual/store-info",
  NOTIFICATION: "/notifications",
  SET_IS_CHECK_NOTIFICATION: "/setchecknotification",
  SETSEENNOTIFI: "/seenNotifi",
  CHECK_NOTIFICATION: "/notifications",
  GANTT_CHART: "/ganttchart",
  HELP_MANUAL_FILE_DETAIL: "/help/manual/filedetail",
  HELP_MANUAL_FILE: "/help/manual/file",
  ADMIN_TASKLOGS: "/admin/tasklogs",
  TASKLOGS_TASKS: "/tasklogs/tasks",
  CREATE_OTA: "/ota",
  OTA_TYPES: "/otatypes",
  OTA_DETAIL_UPDATE: "/otas/{otaId}",
  OTA_UPDATE_STATUS: "/otas/{otaId}/status",
  UPDATE_STORE_OTA_EXPIRED_DATE: "/stores/{storeId}/otas/{otaId}/renewalPassDate",
  TASK_TEMPLATES: "/items",
  PLANS: "/plans",
  PLAN: "/plans/{planId}",
  ADD_PLAN: "/plan",
  PLAN_REORDER: "/plans/reorder",
  AMOUNT_GROUPS: "/amountgroups",
  AMOUNT_GROUP: "/amountgroups/{amountId}",
  ADD_AMOUNT_GROUP: "/amountgroup",
  AMOUNT_RANKS: "/amountranks",
  STORE_OTAS: "/stores/{storeId}/otas",
  STORE_BY_ID: "/store/{storeId}",
  AMOUNT_GROUPS_BY_STORE_IDS: "/amountgroups/storeids",
  OTA_BY_STORE_IDS: "/ota/storeids",
  OTA_RETAIL_BY_STORE_IDS: "/ota/retail/storeids",
  EXPORT_TASKS_LIST: "/export",
  MEMBER_LIST: "/account/members",
  LIST_ALL_OTAS: "/otas/get-all",
  GET_SITE_CONTROLLERS: "/otas/site_controllers",
  CONTENT_LIST: "/content/get-list",
  CREATE_CONTENT: "content/create",
  TASK_CONTENTS: "/task-contents/{taskId}",
  TASK_CONTENT_TASKLOGS_LATEST: "/task-contents/{taskId}/tasklogs/latest",
  TASK_CONTENT_TOTAL_TIMES: "/task-contents/{taskId}/totaltimes",
  TASK_CONTENT_STOPWATCH: "/task-contents/{taskId}/stopwatch",
  MANUAL_DOWNLOAD: "/help/{helpId}/manual/download",
  HELP_REMOVE_MANUAL_FILE: "/help/{helpId}/manual/remove",
  HELP_SAVE_MANUAL: "/help/manual/save",
  TASK_ASSIGN: "/tasks/task-assign",
  TASK_ACHIEVEMENT: "/task-contents/task-achievement",
  TASK_ACHIEVEMENT_DOWNLOAD: "/task-contents/task-achievement/download",
  TASK_DELIVERY_ACHIEVEMENT: "/task/task-delivery-achievement",
  TASK_DELIVERY_ACHIEVEMENT_DOWNLOAD: "/task/task-delivery-achievement/download",
  TASK_ANALYSIS: "/task/task-analysis",
  CHANGE_TASK_FIXED_DEADLINE: "/task/change-fixed-deadline",
  TEAM_ACHIEVEMENT: "/team/team-achievement",
  TEAM_ACHIEVEMENT_DOWNLOAD: "/team/team-achievement/download",
  CONTENT_ACHIEVEMENT: "/task-contents/task-content-achievement",
  CONTENT_ACHIEVEMENT_DOWNLOAD: "/task-contents/task-content-achievement/download",
  TEAM_CONTENT_ACHIEVEMENT: "/team/team-content-achievement",
  TEAM_CONTENT_ACHIEVEMENT_DOWNLOAD: "/team/team-content-achievement/download",
  TASK_COPY_HISTORY_SEARCH: "/task-copy-history/search",
  LIST_STORES: "/task-store/stores",
  TASK_STORE_STATISTIC_DOWNLOAD: "/task-store/statistic/download",
  TASK_STORE_CONFIG: "/task-store-config/{storeId}/{isStore}",
  TASK_STORE_CONFIG_ADD_AND_EDIT: "/task-store-config/add-and-edit",
  TASK_STORE_CONFIG_DELETE: "/task-store-config/delete/{storeId}/{isStore}",
  CRAWL_PLAN_SALE: "/crawl-plan-sale",
  CRAWL_PLAN_SALE_DOWNLOAD: "/crawl-plan-sale/download",
  GET_CRAWL_PLAN_SALE: "/crawl-plan-sale/current",
  CRAWL_COUPON: "/crawl-coupon",
  GET_CRAWL_COUPON: "/crawl-coupon/current",
  CRAWL_COUPON_DOWNLOAD: "/crawl-coupon/download",
  CRAWL_SALE_STATISTIC: "/crawl-sale-statistic",
  GET_CRAWL_SALE_STATISTIC: "/crawl-sale-statistic/current",
  CREATE_REQUEST_TIME: "/request-time/create",
  DELETE_REQUEST_TIME: "/request-time/delete/{requestTimeId}",
  LIST_REQUEST_TIME: "/request-time",
  LIST_TOTAL_TIME_WORK: "/total-time-work",
  CRAWL_PRINCE_ACHIEVEMENT: "/crawl-prince-achievement",
  GET_CRAWL_PRINCE_ACHIEVEMENT: "/crawl-prince-achievement/current",
  HELP_AGGREGATE_HOTEL: "/aggregate",
  HELP_DOWNLOAD_REPORT: "/aggregate/downloadReport",
  HELP_GET_FILE_REPORT: "/aggregate/getReport",
  HELP_AGGREGATE_RANKING_HOTEL: "/aggregate/ranking",
  HELP_DOWNLOAD_RANKING_REPORT: "/aggregate/ranking/downloadReport",
  HELP_GET_RANKING_FILE_REPORT: "/aggregate/ranking/getReport",
  SAVE_DATA_AUTO_CRAWL_COUPON: "/auto-crawl-coupon/save-data",
  AUTO_CRAWL_COUPON: "/auto-crawl-coupon/get-data",
  ADD_SEARCH_CONDITION: "/tasks/save-search",
  GET_SEARCH_CONDITION: "/tasks/get-search",
  GET_TEMPLATE: "/template/{categoryId}",
  GET_TEMPLATE_BY_SUB_CATEGORY: "/template/sub-category/{subCategoryId}",
  AUTO_COPY_TASK_SEARCH: "/auto-copy-task/search",
  CREATE_AUTO_COPY_TASK: "/auto-copy-task/create",
  DETAIL_AUTO_COPY_TASK: "/auto-copy-task/get-cronjob/{autoCopyTaskId}",
  UPDATE_AUTO_COPY_TASK: "/auto-copy-task/update/{autoCopyTaskId}",
  ROOM_SEARCH: "/room-ota",
  GET_LIST_API_PLAN: "/api-plan-ota/search",
  GET_LIST_API_CATEGORY: "/api-image-category/get-all",
  API_IMAGE: "/api-image-ota",
  API_IMAGE_SEARCH: "/api-image-ota/search",
  API_IMAGE_DELETE: "/api-image-ota/delete/{apiImageOtaId}",
  CREATE_API_IMAGE_CATEGORY: "/api-image-category/create",
  API_IMAGE_CATEGORY_ID: "/api-image-category/{imageCategoryId}",
  API_IMAGE_CATEGORY_SEARCH: "/api-image-category",
  LIST_ALL_API_OTAS: "/api-otas/get-all",
  GET_API_PLAN_DETAIL: "/api-plan-ota/{planId}/get",
  DELETE_API_PLAN_OTA: "/api-plan-ota/{apiPlanOtaId}/api-plan/{apiPlanId}/delete",
  ADD_API_PLAN: "/api-plan/create",
  UPDATE_API_PLAN: "/api-plan/{apiPlanId}/update",
  SELECT_API_ROOMS: "/select-api-rooms",
  API_IMAGE_BY_STORE_AND_OTA: "/api-image-ota-by-store-and-ota",
  CREATE_ROOM: "/rooms/create",
  ROOM_DETAIL_UPDATE: "/rooms/{apiRoomId}",
  DELETE_API_ROOM_OTA: "/room-ota/{apiRoomOtaId}/room/{apiRoomId}/delete",
  SYNC_ROOM_OTA: "/room-ota/sync/{apiRoomOtaId}",
  GET_API_OPTION_VALUES: "/api-option-values",
  API_IMAGE_STORE_AND_OTA: "/api-image-ota-store-and-ota",
  SYNC_PLAN_OTA: "/api-plan-ota/sync/{apiPlanOtaId}",
  CRAWL_ROOM_SEARCH: "/crawl-room/search",
  CRAWL_ROOM: "/crawl-room",
  CRAWL_ROOM_ALL: "/crawl-room-all",
  CRAWL_ROOM_DETAILS: "/crawl-room-details",
  CRAWL_ROOM_DOWNLOAD_EXCEL: "/crawl-room/download-excel/{apiCrawlRoomDataId}/{typeDownload}",
  CRAWL_ROOM_IMPORT_EXCEL: "/crawl-room/import-excel/{apiCrawlRoomDataId}",
  DELETE_API_CATEGORY: "/api-image-category/delete/{apiCategoryId}",
  CRAWL_PLAN_SEARCH: "/crawl-plan/search",
  CRAWL_PLAN: "/crawl-plan",
  CRAWL_PLAN_DOWNLOAD_EXCEL: "/crawl-plan/download-excel/{apiCrawlPlanDataId}/{typeDownload}",
  CRAWL_PLAN_IMPORT_EXCEL: "/crawl-plan/import-excel/{apiCrawlPlanDataId}",
  CRAWL_DATA_CANCEL_POLICY: "/crawl-cancel-policy",
  GET_CANCEL_POLICY: "/get-cancel-policy",
  GET_SYNC_PLAN_STATUS: "/api-plan-ota/sync/status",
  UPDATE_ROOM_OTA: "/room-ota/update/{apiRoomOtaId}",
  UPDATE_PLAN_OTA: "/plan-ota/update/{apiPlanOtaId}",
  CRAWL_ALL_PLAN: "/crawl-all-plan",
  UPDATE_MULTIPLE_PLAN_OTA: "/plan-ota/update-multiple",
  OPEN_SALE_ROOM: "/room/open-sale",
  STOP_SELLING_ROOM: "/room/stop-selling",
  OPEN_SALE_PLAN: "/plan-ota/open-sale",
  STOP_SELLING_PLAN: "/plan-ota/stop-selling",
  TASK_EXTRA_ATTACH_UPLOAD: "/tasks/extra-attach/upload",
  REQUEST_CODE_AUTHENTICATION: "/request-authentication",
  UPDATE_CODE_AUTHENTICATION: "/update-authentication",
  GET_INFO_AUTHENTICATION_CURRENT: "/request-authentication/current",
  CRAWL_VISITOR_TO_HENNA_HOTEL: "/crawl-visitor-henna-hotel",
  AUTO_CRAWL: "/auto-crawl/get-data",
  SAVE_DATA_AUTO_CRAWL: "/auto-crawl/save-data",
  TASK_ATTACH_FILE_COMMENT: "/tasks/attach/upload-file-comment",
  GET_TASK_ATTACH_FILE_COMMENT: "/tasks/attach/get-file-comments/{taskId}",
  DELETE_TASK_ATTACH_FILE_COMMENT: "/tasks/attach/delete/{taskAttachId}",
  GET_CRAWL_VISITOR_TO_HENNA_HOTEL: "/crawl-visitor-henna-hotel/current",
  CRAWL_ADDITIONAL_REQUEST: "/crawl-additional-request",
  GET_CRAWL_ADDITIONAL_REQUEST: "/crawl-additional-request/current",
  CREATE_WORK_SHEET: "/tasks/create-work-sheet/{taskId}",
  GET_OPTION: "/get-option",
  CRAWL_OPTION: "/crawl-option",
  TEAM_SEARCH: "/team/search",
  CREATE_TEAM: "/team/create",
  DETAIL_TEAM: "/team/detail/{teamId}",
  UPDATE_TEAM: "/team/update/{teamId}",
  DELETE_TEAM: "/team/delete/{teamId}",
  GET_PARENT_TEAMS: "/team/parent-teams",
  GET_TEAM_OPTIONS: "/team/options",
  GET_PERSON_IN_CHARGE: "/person_in_charge",
  CRAWL_DATA_COLLECTION: "/crawl-data-collection",
  GET_CRAWL_DATA_COLLECTION: "/crawl-data-collection/current",
  PLAN_LINK_PRICES: "/api-plan-ota-link-price",
  DELETE_STORE_BY_ID: "/store/delete/{storeId}",
  CRAWL_RANKING_AND_SCORE: "/crawl-ranking-score",
  GET_CRAWL_RANKING_AND_SCORE: "/crawl-ranking-score/current",
  IMPORT_INFORMATION_STORES: "/crawl-ranking-score/import-information-store",
  DOWNLOAD_INFORMATION_STORES: "/crawl-ranking-score/download-information-store",
  DELETE_INFORMATION_STORES: "/crawl-ranking-score/delete-information-store",
  GET_INFORMATION_STORES: "/crawl-ranking-score/get-information-store",
  GET_ACCOUNT_BY_STORE: "/store/{storeId}/accounts",
  DOWNLOAD_TOTAL_TIME_WORK: "/total-time-work/download",
};

export const ENDPOINTS_WITHOUT_AUTHEN = [
  API_ENDPOINTS.LOGIN,
  API_ENDPOINTS.RESET_PASS,
  API_ENDPOINTS.ACCOUNT_PASSWORD,
  API_ENDPOINTS.CONFIRM_EMAIL,
];
