import React, { useState, useEffect, memo, useCallback } from "react";
import { useIntl } from "react-intl";
import { Col, Row, Button, Checkbox, Radio, Modal, Empty } from "antd";
import { Form, Input, Select, DatePicker } from "formik-antd";
import { LayoutContentWrapper } from "./PlanOta.styles";
import PropTypes from "prop-types";
import {
    fieldKeyJalanApiPlanOption,
    searchSelect,
    LIMITED,
    ONLINE_CARD_PAYMENT_ONLY,
    POST_THE_INN_LOG_SPECIFY_CONTENT,
    POSTING_PERIOD_SETTING_NOT_SET,
    INDIVIDUAL_CANCELLATION_POLICY_FOR_PLAN,
    WITH,
    CANCEL_POLICY_SET_UP,
    POSTING_PERIOD_SETTING_SET_UP,
    DIFFERENT_TIME_DEADLINE,
    CANCEL_POLICY_NOT_SET,
    ERROR_STATUS,
    LIST_STATUS_SYNC,
    LIST_CODE_STATUS_SHOW_MESSAGE,
    LIST_CODE_STATUS_COMMON_MESSAGE,
    LIST_STATUS_SALE_PROCESSING,
    LIST_OPEN_SALE_STATUS
} from "@iso/constants/api.constant";
import {
    colFull,
    colFullApi,
    colInputLeftApi,
    colLeft,
    colLeftInputWithLabelApi,
    customRadioGroupFullRow,
    itemCheckBoxApi,
    itemLeftLabel,
    itemLeftLabelFullApi
} from "@iso/assets/styles/form.style";
import { colRight } from "@iso/containers/TaskLog";
import { ValidateField } from "@iso/validates/ValidateField";
import { isEmpty } from "lodash";
import RoomJalanExtendFields from "@iso/containers/ApiPlanAddAndEdit/Components/Jalan/RoomExtendFields";
import { ReloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import apiPlanAction from "@iso/redux/apiPlan/actions";
import UploadFileImageOta from "@iso/containers/ApiPlanAddAndEdit/extend-fields/UploadFileImageOta";
import moment from "moment";
import {DATE_FORMAT} from "@iso/constants/common.constant";

const Jalan = ({
    tabKey,
    setFieldValue,
    setFieldTouched,
    apiPlanId,
    values,
    otaId,
    storeId,
    apiOptionValue,
    isCloneData,
    dataPlanOta,
    setEventKodomoSubmit,
    activeTabKey
}) => {
    const { messages } = useIntl();
    const { Option } = Select;
    const dispatch = useDispatch();

    const {
        ApiPlan: { loadingSyncStatus, syncPlanStatus }
    } = useSelector((state) => state);

    const [optionValues, setOptionValues] = useState([])
    const [apiPlanOtaId, setApiPlanOtaId] = useState(null);

    const [packageValue, setPackageValue] = useState([]);
    const [postingPeriodSettingValue, setPostingPeriodSettingValue] = useState(null);
    const [ngRemovalValue, setNgRemovalValue] = useState([]);
    const [ngRemovalDetailValue, setNgRemovalDetailValue] = useState([]);
    const [breakfastGenreFeatureValue, setBreakfastGenreFeatureValue] = useState([]);
    const [dinnerGenreFeatureValue, setDinnerGenreFeatureValue] = useState([]);
    const [dinnerMainIngredientFeatureValue, setDinnerMainIngredientFeatureValue] = useState([]);
    const [otherFeatureValue, setOtherFeatureValue] = useState([]);
    const [acceptanceConditionTypeFeatureValue, setAcceptanceConditionTypeFeatureValue] = useState([]);
    const [reservationCancellationDeadlineValue, setReservationCancellationDeadlineValue] = useState(null);
    const [minimumConsecutiveNightTypeValue, setMinimumConsecutiveNightTypeValue] = useState(null);
    const [maximumConsecutiveNightTypeValue, setMaximumConsecutiveNightTypeValue] = useState(null);
    const [mealOptionBreakfastValue, setMealOptionBreakfastValue] = useState(null);
    const [mealOptionBreakfastTypeValue, setMealOptionBreakfastTypeValue] = useState([]);
    const [mealOptionDinnerValue, setMealOptionDinnerValue] = useState(null);
    const [mealOptionDinnerTypeValue, setMealOptionDinnerTypeValue] = useState([]);
    const [questionForCustomerValue, setQuestionForCustomerValue] = useState(null);
    const [askCustomerValue, setAskCustomerValue] = useState([]);
    const [globalCustomerServiceValue, setGlobalCustomerServiceValue] = useState([]);
    const [accommodationRequestingValue, setAccommodationRequestingValue] = useState([]);
    const [listingOrderValue, setListingOrderValue] = useState([]);
    const [automaticPostingAccommodationLogValue, setAutomaticPostingAccommodationLogValue] = useState(null);
    const [paymentMethodValue, setPaymentMethodValue] = useState(null);
    const [cancelPolicyTypeValue, setCancelPolicyTypeValue] = useState(null);
    const [jalanPackApplyValue, setJalanPackApplyValue] = useState([]);
    const [settingCancelPolicyValue, setSettingCancelPolicyValue] = useState({
        cancelPolicy1: null,
        cancelPolicy2: null,
        cancelPolicy3: null,
        cancelPolicy4: null,
        cancelPolicy5: null,
        cancelPolicy6: null,
        cancelPolicy7: null
    });
    const [cancelPolicyContent, setCancelPolicyContent] = useState([]);
    const [cancelPolicySetUpOption, setCancelPolicySetUpOption] = useState(false);

    const [isDisableButtonSync, setIsDisableButtonSync] = useState(false);
    const [isSyncDataToSite, setIsSyncDataToSite] = useState(false);
    const [isUpdateDataFromSite, setIsUpdateDataFromSite] = useState(false);
    const [syncErrorContent, setSyncErrorContent] = useState([]);
    const [showLog, setShowLog] = useState(false);

    useEffect(() => {
        if (!isEmpty(apiOptionValue)) {
            setOptionValues(apiOptionValue)
        }
    }, [apiOptionValue]);

    useEffect(() => {
        if (dataPlanOta &&
            dataPlanOta.otaFieldContent !== null && dataPlanOta.otaFieldContent !== undefined &&
            dataPlanOta.otaFieldContent[tabKey] !== null && dataPlanOta.otaFieldContent[tabKey] !== undefined &&
            Object.keys(dataPlanOta.otaFieldContent[tabKey]).length > 0
        ) {
            const jalanDataOta = dataPlanOta?.otaFieldContent[tabKey];
            setPackageValue(jalanDataOta?.package)
            setPostingPeriodSettingValue(jalanDataOta?.postingPeriodSetting)
            setNgRemovalValue(jalanDataOta?.ngRemoval)
            setNgRemovalDetailValue(jalanDataOta?.ngRemovalDetail)
            setBreakfastGenreFeatureValue(jalanDataOta?.breakfastGenreFeature)
            setDinnerGenreFeatureValue(jalanDataOta?.dinnerGenreFeature)
            setDinnerMainIngredientFeatureValue(jalanDataOta?.dinnerMainIngredientFeature)
            setOtherFeatureValue(jalanDataOta?.otherFeature)
            setAcceptanceConditionTypeFeatureValue(jalanDataOta?.acceptanceConditionTypeFeature)
            setReservationCancellationDeadlineValue(jalanDataOta?.reservationCancellationDeadline)
            setMinimumConsecutiveNightTypeValue(jalanDataOta?.minimumConsecutiveNightType)
            setMaximumConsecutiveNightTypeValue(jalanDataOta?.maximumConsecutiveNightType)
            setMealOptionBreakfastValue(jalanDataOta?.mealOptionBreakfast)
            setMealOptionBreakfastTypeValue(jalanDataOta?.mealOptionBreakfastType)
            setMealOptionDinnerValue(jalanDataOta?.mealOptionDinner)
            setMealOptionDinnerTypeValue(jalanDataOta?.mealOptionDinnerType)
            setQuestionForCustomerValue(jalanDataOta?.questionForCustomer)
            setAskCustomerValue(jalanDataOta?.askCustomer)
            setGlobalCustomerServiceValue(jalanDataOta?.globalCustomerService)
            setAccommodationRequestingValue(jalanDataOta?.accommodationRequesting)
            setListingOrderValue(jalanDataOta?.listingOrder)
            setAutomaticPostingAccommodationLogValue(jalanDataOta?.automaticPostingAccommodationLog)
            setPaymentMethodValue(jalanDataOta?.paymentMethod)
            setCancelPolicyTypeValue(jalanDataOta?.cancelPolicyType)
            setJalanPackApplyValue(jalanDataOta?.jalanPackApply)
            setSettingCancelPolicyValue({
                cancelPolicy1: jalanDataOta?.settingCancelPolicy1,
                cancelPolicy2: jalanDataOta?.settingCancelPolicy2,
                cancelPolicy3: jalanDataOta?.settingCancelPolicy3,
                cancelPolicy4: jalanDataOta?.settingCancelPolicy4,
                cancelPolicy5: jalanDataOta?.settingCancelPolicy5,
                cancelPolicy6: jalanDataOta?.settingCancelPolicy6,
                cancelPolicy7: jalanDataOta?.settingCancelPolicy7
            })
        }
    }, [dataPlanOta]);

    useEffect(() => {
        if (!values.otaFieldContent[tabKey] || Object.keys(values.otaFieldContent[tabKey]).length === 0) {
            jalanInitialValue.otaId = otaId;
            values.otaFieldContent[tabKey] = jalanInitialValue;
            if(!isEmpty(values.otaFieldContent[tabKey].package) && !apiPlanId) {
                setPackageValue(values.otaFieldContent[tabKey].package);
            }
        } else {
            const jalanDataOta = values.otaFieldContent[tabKey];
            const parentFieldKey = `otaFieldContent.${tabKey}`;

            setApiPlanOtaId(jalanDataOta.apiPlanOtaId)

            if (jalanDataOta.postingPeriodSetting === POSTING_PERIOD_SETTING_NOT_SET) {
                setFieldValue(`${parentFieldKey}.postingPeriodFrom`, null);
                setFieldValue(`${parentFieldKey}.postingPeriodTo`, null);
            }

            if (isEmpty(jalanDataOta.ngRemoval)) {
                setFieldValue(`${parentFieldKey}.jalJalanPack`, null);
                setFieldValue(`${parentFieldKey}.anaJalanPack`, null);
            }

            if (isEmpty(jalanDataOta.ngRemovalDetail)) {
                setFieldValue(`${parentFieldKey}.jalJalanPackDetail`, null);
                setFieldValue(`${parentFieldKey}.anaJalanPackDetail`, null);
            }

            if (jalanDataOta.minimumConsecutiveNightType !== LIMITED) {
                setFieldValue(`${parentFieldKey}.minimumConsecutiveNight`, null);
            }

            if (jalanDataOta.maximumConsecutiveNightType !== LIMITED) {
                setFieldValue(`${parentFieldKey}.maximumConsecutiveNight`, null);
            }

            if (jalanDataOta.automaticPostingAccommodationLog !== POST_THE_INN_LOG_SPECIFY_CONTENT) {
                setFieldValue(`${parentFieldKey}.articleTitle`, null);
                setFieldValue(`${parentFieldKey}.articleDetail`, null);
            }
        }
    }, [values])

    const handleChangeRadio = (e, field = null, key = null) => {
        const nameField = e.target.name;
        const valueField = e.target.value;
        const parentFieldKey = `otaFieldContent.${tabKey}`;
        setFieldValue(nameField, valueField);

        if ((field == fieldKeyJalanApiPlanOption.postingPeriodSetting)
            && valueField === POSTING_PERIOD_SETTING_SET_UP
        ) {
            setFieldValue(`${parentFieldKey}.automaticPostingAccommodationLog`, null);
            setFieldValue(`${parentFieldKey}.articleTitle`, null);
            setFieldValue(`${parentFieldKey}.articleDetail`, null);
            setAutomaticPostingAccommodationLogValue(null)
        }

        if ((field == fieldKeyJalanApiPlanOption.reservationCancellationDeadline)
            && valueField !== DIFFERENT_TIME_DEADLINE
        ) {
            setFieldValue(`${parentFieldKey}.reservationCancellationDeadlineDay`, null);
            setFieldValue(`${parentFieldKey}.reservationCancellationDeadlineHour`, null);
            setFieldValue(`${parentFieldKey}.reservationCancellationDeadlineMinute`, null);
        }

        if ((field == fieldKeyJalanApiPlanOption.mealOptionBreakfast)
            && valueField !== WITH
        ) {
            setFieldValue(`${parentFieldKey}.mealOptionBreakfastType`, []);
            setMealOptionBreakfastTypeValue([]);
        }

        if ((field == fieldKeyJalanApiPlanOption.mealOptionDinner)
            && valueField !== WITH
        ) {
            setFieldValue(`${parentFieldKey}.mealOptionDinnerType`, []);
            setMealOptionDinnerTypeValue([]);
        }
        
        if ((field == fieldKeyJalanApiPlanOption.paymentMethod)
            && (valueField != ONLINE_CARD_PAYMENT_ONLY)
        ) {
            const dataValues = { ...values.otaFieldContent[tabKey] };
            dataValues[field] = valueField;

            const cancelPolicyDataDefault = {
                cancelPolicyType: null,
                jalanPackApply: [],
                settingCancelPolicy1: null,
                cancelPolicyFee1: null,
                settingCancelPolicy2: null,
                cancelPolicyFee2: null,
                settingCancelPolicy3: null,
                cancelPolicyFee3: null,
                dayOfStayFrom3: null,
                dayOfStayTo3: null,
                settingCancelPolicy4: null,
                cancelPolicyFee4: null,
                dayOfStayFrom4: null,
                dayOfStayTo4: null,
                settingCancelPolicy5: null,
                cancelPolicyFee5: null,
                dayOfStayFrom5: null,
                dayOfStayTo5: null,
                settingCancelPolicy6: null,
                cancelPolicyFee6: null,
                dayOfStayFrom6: null,
                dayOfStayTo6: null,
                settingCancelPolicy7: null,
                cancelPolicyFee7: null,
                dayOfStayFrom7: null,
                dayOfStayTo7: null,
            }
            setJalanPackApplyValue([]);
            setCancelPolicyTypeValue(null);
            setSettingCancelPolicyValue({
                cancelPolicy1: null,
                cancelPolicy2: null,
                cancelPolicy3: null,
                cancelPolicy4: null,
                cancelPolicy5: null,
                cancelPolicy6: null,
                cancelPolicy7: null
            })
            const newDataOtaPlan = { ...dataValues, ...cancelPolicyDataDefault }
            setFieldValue(parentFieldKey, newDataOtaPlan);
        }

        if ((field == fieldKeyJalanApiPlanOption.cancelPolicyType)
            && (valueField === INDIVIDUAL_CANCELLATION_POLICY_FOR_PLAN)
        ) {
            const dataValues = { ...values.otaFieldContent[tabKey] };
            dataValues[field] = valueField;

            const cancelPolicyDataDefault = {
                jalanPackApply: [],
                settingCancelPolicy1: CANCEL_POLICY_SET_UP,
                cancelPolicyFee1: 100,
                settingCancelPolicy2: CANCEL_POLICY_SET_UP,
                cancelPolicyFee2: 80,
                settingCancelPolicy3: CANCEL_POLICY_SET_UP,
                cancelPolicyFee3: 50,
                dayOfStayFrom3: 1,
                dayOfStayTo3: 1,
                settingCancelPolicy4: CANCEL_POLICY_SET_UP,
                cancelPolicyFee4: 30,
                dayOfStayFrom4: 2,
                dayOfStayTo4: 2,
                settingCancelPolicy5: CANCEL_POLICY_NOT_SET,
                cancelPolicyFee5: null,
                dayOfStayFrom5: null,
                dayOfStayTo5: 3,
                settingCancelPolicy6: CANCEL_POLICY_NOT_SET,
                cancelPolicyFee6: null,
                dayOfStayFrom6: null,
                dayOfStayTo6: null,
                settingCancelPolicy7: CANCEL_POLICY_NOT_SET,
                cancelPolicyFee7: null,
                dayOfStayFrom7: null,
                dayOfStayTo7: null,
            }
            setJalanPackApplyValue([]);
            setSettingCancelPolicyValue({
                cancelPolicy1: CANCEL_POLICY_SET_UP,
                cancelPolicy2: CANCEL_POLICY_SET_UP,
                cancelPolicy3: CANCEL_POLICY_SET_UP,
                cancelPolicy4: CANCEL_POLICY_SET_UP,
                cancelPolicy5: CANCEL_POLICY_NOT_SET,
                cancelPolicy6: CANCEL_POLICY_NOT_SET,
                cancelPolicy7: CANCEL_POLICY_NOT_SET
            })
            const newDataOtaPlan = { ...dataValues, ...cancelPolicyDataDefault }
            setFieldValue(parentFieldKey, newDataOtaPlan);
        }
    }

    const handleChangeCheckbox = (nameField, valueField, field = null) => {
        setFieldValue(nameField, valueField)

        const parentFieldKey = `otaFieldContent.${tabKey}`;
        if ((field == 'askCustomer')
            && isEmpty(valueField)
        ) {
            setFieldValue(`${parentFieldKey}.globalCustomerService`, []);
            setGlobalCustomerServiceValue([])
        }
    }

    const handleInputBlur = (event, field = null, key = null) => {
        const nameField = event.target.name;
        const valueField = event.target.value;
        const parentFieldKey = `otaFieldContent.${tabKey}`;
        const trimmedValue = trimSpaces(valueField);

        setFieldValue(nameField, null)
        if (trimmedValue !== "" || trimmedValue == undefined) {
            setFieldValue(nameField, trimmedValue)
        }

        if (field == fieldKeyJalanApiPlanOption.questionForCustomer) {
            setQuestionForCustomerValue(trimmedValue)
            if (trimmedValue == "" || trimmedValue == undefined) {
                setQuestionForCustomerValue(null)
                setFieldValue(`${parentFieldKey}.askCustomer`, [])
                setFieldValue(`${parentFieldKey}.globalCustomerService`, [])
                setAskCustomerValue([])
                setGlobalCustomerServiceValue([])
            }
        }

        if (field == fieldKeyJalanApiPlanOption.dayOfStayFrom) {
            if ((valueField == "") || (valueField == null)) {
                setFieldValue(`${parentFieldKey}.${fieldKeyJalanApiPlanOption.dayOfStayTo + (key + 1)}`, 1)
            } else {
                setFieldValue(`${parentFieldKey}.${fieldKeyJalanApiPlanOption.dayOfStayTo + (key + 1)}`, (parseInt(valueField) + 1))
            }

        }
    };

    const trimSpaces = (inputString) => {
        if (typeof inputString !== 'string') {
            return '';
        }
        let elements = inputString.split(',');
        let trimmedElements = elements.map(element => element.trim());
        return trimmedElements.join(',');
    }

    const onChangeDate = (name, value) => {
        if (value) {
            setFieldValue(name, moment(value).format(DATE_FORMAT))
            setFieldTouched(name, false);
        } else {
            setFieldValue(name, '')
        }
    }

    useEffect(() => {
        const values = Object.values(settingCancelPolicyValue);
        const countOfOnes = values.filter(value => value === CANCEL_POLICY_SET_UP).length;

        const newCancelPolicySetUpOption = (countOfOnes > 4);
        setCancelPolicySetUpOption(newCancelPolicySetUpOption);

    }, [settingCancelPolicyValue])

    useEffect(() => {
        const policyContent = [];
        for (let i = 1; i <= 7; i++) {
            policyContent.push(
                <Row justify="start" className="mb-25px">
                    <Col {...colFullApi}>
                        <Form.Item
                            name={`otaFieldContent.${tabKey}.cancelPolicyType`}
                            label={
                                <div className="d-grid">
                                    <label>{messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyType"] + i}</label>
                                    {i === 1 && (
                                        <span>{messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyNoShow"]}</span>
                                    )}

                                    {i === 2 && (
                                        <span>{messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyOnTheDay"]}</span>
                                    )}

                                </div>
                            }
                            {...itemLeftLabelFullApi}
                        >
                            <Row justify="start" className="mb-10px">
                                <Col {...colFullApi}>
                                    <Row>
                                        <Col {...colLeftInputWithLabelApi}>
                                            <Form.Item
                                                name={`otaFieldContent.${tabKey}.maxNight`}
                                                {...itemCheckBoxApi}
                                            >
                                                <Radio.Group
                                                    className="mb-10px"
                                                    name={`otaFieldContent.${tabKey}.settingCancelPolicy` + i}
                                                    onChange={(e) => {
                                                        handleChangeRadio(e, fieldKeyJalanApiPlanOption.settingCancelPolicy, i)
                                                        setSettingCancelPolicyValue(prevState => ({
                                                            ...prevState,
                                                            [`cancelPolicy${i}`]: e.target.value
                                                        }));
                                                    }}
                                                    value={settingCancelPolicyValue[`cancelPolicy${i}`]}
                                                >
                                                    <Row>
                                                        {optionValues.map((option, index) => {
                                                            if (option.fieldKey === `settingCancelPolicy${i}`) {
                                                                return (
                                                                    <Row key={index}>
                                                                        <Radio value={option.value} disabled={cancelPolicySetUpOption && (option.value == CANCEL_POLICY_SET_UP)}>
                                                                            <span className="mx-10px">
                                                                                {option.value}
                                                                            </span>
                                                                        </Radio>
                                                                    </Row>
                                                                )
                                                            }
                                                        })}
                                                    </Row>
                                                </Radio.Group>

                                                {(i !== 1) && (i !== 2) && (
                                                    <Row justify="start" className="mb-10px">
                                                        <Col lg={24}>
                                                            <Form.Item
                                                                name={`otaFieldContent.${tabKey}.cancelPolicyAccommodation` + i}
                                                                label={messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyAccommodation"]}
                                                                labelCol={{ lg: 3, xs: 24 }}
                                                                wrapperCol={{ lg: 21, xs: 24 }}
                                                            >
                                                                <Row justify="start">
                                                                    <Col>
                                                                        <Form.Item
                                                                            className="row-reverse"
                                                                            name={`otaFieldContent.${tabKey}.dayOfStayFrom` + i}
                                                                            validate={(value) => ValidateField(
                                                                                value,
                                                                                messages["page.apiPlanAddAndEdit.Jalan.dayOfStayFrom"],
                                                                                {
                                                                                    integer: messages["form.error.number.integer"]
                                                                                },
                                                                                {
                                                                                    integer: true,
                                                                                }
                                                                            )}
                                                                        >
                                                                            <div className="d-flex align-items-center">
                                                                                <Input
                                                                                    name={`otaFieldContent.${tabKey}.dayOfStayFrom` + i}
                                                                                    style={{ maxWidth: "70px" }}
                                                                                    disabled={(settingCancelPolicyValue[`cancelPolicy${i}`] == CANCEL_POLICY_NOT_SET)}
                                                                                    onBlur={(e) => handleInputBlur(e, fieldKeyJalanApiPlanOption.dayOfStayFrom, i)}
                                                                                />
                                                                                <span style={{ marginLeft: "5px", whiteSpace: 'nowrap' }} className="item-center">
                                                                                    {messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyAccommodationTextFrom"]}
                                                                                </span>
                                                                                <span style={{ marginRight: "1rem" }} className="item-center">～</span>
                                                                            </div>
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col>
                                                                        <Form.Item
                                                                            className="row-reverse pointer-none"
                                                                            name={`otaFieldContent.${tabKey}.dayOfStayTo` + i}
                                                                            validate={(value) => ValidateField(
                                                                                value,
                                                                                messages["page.apiPlanAddAndEdit.Jalan.dayOfStayTo"],
                                                                                {
                                                                                    integer: messages["form.error.number.integer"]
                                                                                },
                                                                                {
                                                                                    integer: true,
                                                                                }
                                                                            )}
                                                                        >
                                                                            <Input
                                                                                name={`otaFieldContent.${tabKey}.dayOfStayTo` + i}
                                                                                style={{ maxWidth: "70px" }}
                                                                                disabled={(settingCancelPolicyValue[`cancelPolicy${i}`] == CANCEL_POLICY_NOT_SET)}
                                                                            />

                                                                            <span style={{ marginLeft: "5px", whiteSpace: 'nowrap' }} className="item-center">
                                                                                {messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyAccommodationTextTo"]}
                                                                            </span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                )}

                                                <Row justify="start">
                                                    <Col lg={9}>
                                                        <Form.Item
                                                            name={`otaFieldContent.${tabKey}.cancelPolicyFee` + i}
                                                            label={messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyHotelFee"]}
                                                            labelCol={{ lg: 13, xs: 24 }}
                                                            wrapperCol={{ lg: 11, xs: 24 }}
                                                            validate={(value) => ValidateField(
                                                                value,
                                                                messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyFee"],
                                                                {
                                                                    integer: messages["form.error.number.integer"]
                                                                },
                                                                {
                                                                    integer: true,
                                                                }
                                                            )}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <Input
                                                                    name={`otaFieldContent.${tabKey}.cancelPolicyFee` + i}
                                                                    disabled={(settingCancelPolicyValue[`cancelPolicy${i}`] == CANCEL_POLICY_NOT_SET)}
                                                                />
                                                                <span className="text-inline ml-1">{messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyHotelFeeUnit"]}</span>
                                                            </div>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
            );
        }

        setCancelPolicyContent(policyContent)
    }, [optionValues, settingCancelPolicyValue, cancelPolicySetUpOption]);

    useEffect(() => {
        refreshSyncPlan();
    }, [dispatch])

    useEffect(() => {
        if (syncPlanStatus !== null && syncPlanStatus !== undefined && !isCloneData && apiPlanOtaId) {
            for (let i = 0; i < syncPlanStatus.length; i++) {
                if (syncPlanStatus[i].otaId === tabKey) {
                    if (LIST_CODE_STATUS_SHOW_MESSAGE.includes(syncPlanStatus[i].codeStatus)) {
                        let syncErrorContentData = syncPlanStatus[i].syncErrorContent ?? '';
                        syncErrorContentData = syncPlanStatus[i]?.codeStatus === ERROR_STATUS.LOGIN_ERROR ? messages['page.apiPlanAddAndEdit.loginFail'] : syncErrorContentData;
                        if (syncErrorContentData !== '' && syncErrorContentData !== null && syncErrorContentData !== undefined) {
                            let syncErrorContentArray = syncErrorContentData.trim().split("\n").map(item => item.trim());
                            setSyncErrorContent(syncErrorContentArray);
                        } else {
                            setSyncErrorContent([])
                        }
                    } else if (LIST_CODE_STATUS_COMMON_MESSAGE.includes(syncPlanStatus[i].codeStatus)) {
                        setSyncErrorContent([messages['page.apiPlanAddAndEdit.somethingWrong']])
                    } else {
                        setSyncErrorContent([])
                    }
                    setFieldValue(`otaFieldContent.${tabKey}.syncStatus`, syncPlanStatus[i].syncStatus);
                    setFieldValue(`otaFieldContent.${tabKey}.updateStatus`, syncPlanStatus[i].updateStatus);
                    setFieldValue(`otaFieldContent.${tabKey}.openSaleStatus`, syncPlanStatus[i].openSaleStatus);
                }
            }
        }
    }, [syncPlanStatus])

    const syncDataToSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsSyncDataToSite(true)
            return dispatch(apiPlanAction.syncDataPlanToSite({apiPlanOtaId, resolve, reject}));
        }).then(() => {
            Modal.success({
                title: messages["page.apiPlanAddAndEdit.modal.addSuccessTitle"],
                content: messages[`page.apiPlanAddAndEdit.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.apiPlanAddAndEdit.inProgressSyncError`]
                : messages[`page.apiPlanAddAndEdit.updateError`]
            Modal.error({
                title: messages["page.apiPlanAddAndEdit.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsSyncDataToSite(false)
        })
    });

    const updateDataFromSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsUpdateDataFromSite(true)
            return dispatch(apiPlanAction.updateDataFromSite({ apiPlanOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.apiPlan.modal.updateSuccessTitle"],
                content: messages[`page.apiPlan.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.apiPlan.modal.inProgressSyncError`]
                : messages[`page.apiPlan.modal.updateError`]
            Modal.error({
                title: messages["page.apiPlan.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsUpdateDataFromSite(false)
        })
    });

    const refreshSyncPlan = () => {
        if (apiPlanId === null || apiPlanId === undefined) {
            return;
        }
        dispatch(apiPlanAction.getSyncPlanStatus({apiPlanId}))
    }

    return (
        <LayoutContentWrapper>
            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Row>
                        <Col {...colLeft}>
                            <Form.Item
                                label={messages["page.apiPlanAddAndEdit.Rakuten.sitePlanId"]}
                                name={`otaFieldContent.${tabKey}.sitePlanId`}
                                {...itemLeftLabel}
                            >
                                <Input
                                    name={`otaFieldContent.${tabKey}.sitePlanId`}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colRight} style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                                type="primary"
                                className="mr-10 mb-0 button"
                                block
                                hidden={apiPlanOtaId === null || apiPlanOtaId === undefined}
                                loading={isSyncDataToSite}
                                disabled={isDisableButtonSync}
                                onClick={syncDataToSite}
                            >
                                {messages["page.apiPlanAddAndEdit.buttonSync"]}
                            </Button>

                            <Button
                                type="primary"
                                className="mr-10 button"
                                block
                                hidden={apiPlanOtaId === null || apiPlanOtaId === undefined}
                                loading={isUpdateDataFromSite}
                                onClick={updateDataFromSite}
                            >
                                {messages["page.apiPlan.crawlUpdate"]}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={12}>
                    <Form.Item
                        label={messages["page.apiPlanAddAndEdit.Jalan.syncStatus"]}
                        name={`otaFieldContent.${tabKey}.syncStatus`}
                        labelCol={{ lg: 8, xs: 24 }}
                        wrapperCol={{ lg: 12, xs: 24 }}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.syncStatus`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} className={'position-error-message'}>
                    <Row>
                        <Col xs={24} lg={2}>
                            <Button
                                type="primary"
                                className="mr-2"
                                loading={loadingSyncStatus}
                                icon={<ReloadOutlined />}
                                onClick={() => refreshSyncPlan()}
                            >
                            </Button>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.syncErrorContent`}
                            >
                                <Input
                                    className="styleInputLog"
                                    onClick={() => setShowLog(true)}
                                    name={`otaFieldContent.${tabKey}.syncErrorContent`}
                                    value={
                                        syncErrorContent !== null &&
                                            syncErrorContent !== undefined &&
                                            syncErrorContent.length > 0 ? syncErrorContent[0] : ''
                                    }
                                    readOnly
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.apiPlan.ota.updateStatus"]}
                        name={`otaFieldContent.${tabKey}.updateStatus`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.updateStatus`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.apiPlanAddAndEdit.openSale"]}
                        name={`otaFieldContent.${tabKey}.openSaleStatus`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.openSaleStatus`}
                            disabled={true}
                        >
                            {LIST_STATUS_SALE_PROCESSING.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.crawlPlan.openSaleType"]}
                        name={`otaFieldContent[${tabKey}]['openSaleType']`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent[${tabKey}]['openSaleType']`}
                            disabled={true}
                        >
                            {LIST_OPEN_SALE_STATUS.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 12, xs: 24 }}
                        label={messages["page.apiPlanAddAndEdit.Jalan.package"]}
                        name={`otaFieldContent.${tabKey}.package`}
                    >
                        <Checkbox.Group
                            onChange={(value) => {
                                handleChangeCheckbox(`otaFieldContent.${tabKey}.package`, value)
                                setPackageValue(value)
                            }}
                            value={packageValue}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyJalanApiPlanOption.package) {
                                    return (
                                        <Checkbox
                                            key={index}
                                            value={option.value}
                                            className="mr-1"
                                            disabled={option.value == 'じゃらん' || option.value == 'JCS'}
                                        >
                                            {option.value}
                                        </Checkbox>
                                    )
                                }
                            })}
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col  {...colFull}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.registrationPeriod`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.registrationPeriod"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...customRadioGroupFullRow}
                    >
                        <Row justify="start">
                            <Col lg={3} xs={24}>
                                <Form.Item
                                    className="row-reverse"
                                    name={`otaFieldContent.${tabKey}.registrationPeriodFrom`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.registrationPeriod"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <DatePicker
                                        format={DATE_FORMAT}
                                        name={`otaFieldContent.${tabKey}.registrationPeriodFrom`}
                                        onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.registrationPeriodFrom`, date)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col lg={1} xs={24} className="content-middle max-h-34px">
                                <div>～</div>
                            </Col>

                            <Col lg={3} xs={24}>
                                <Form.Item
                                    className="row-reverse"
                                    name={`otaFieldContent.${tabKey}.registrationPeriodTo`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.registrationPeriod"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <DatePicker
                                        format={DATE_FORMAT}
                                        name={`otaFieldContent.${tabKey}.registrationPeriodTo`}
                                        onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.registrationPeriodTo`, date)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFull}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.postingPeriodSettings`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.postingPeriod"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        {...customRadioGroupFullRow}
                        className="custom-label"
                    >
                        <Row justify="start">
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.postingPeriodSetting`}
                                validate={(value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.Jalan.postingPeriod"],
                                    {
                                        required: messages["form.error.mixed.required"]
                                    },
                                    {
                                        required: true,
                                    }
                                )}
                            >
                                <Radio.Group
                                    name={`otaFieldContent.${tabKey}.postingPeriodSetting`}
                                    style={{ width: '100%' }}
                                    className="mb-10px"
                                    onChange={(e) => {
                                        handleChangeRadio(e, fieldKeyJalanApiPlanOption.postingPeriodSetting)
                                        setPostingPeriodSettingValue(e.target.value)
                                    }}
                                    value={postingPeriodSettingValue}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.postingPeriodSetting) {
                                            return (
                                                <Row justify="start" className="mb-10px" key={index}>
                                                    <Radio value={option.value}>
                                                        <span className="mx-10px">
                                                            {option.value}
                                                        </span>
                                                    </Radio>
                                                </Row>
                                            )
                                        }
                                    })}
                                </Radio.Group>
                            </Form.Item>
                        </Row>

                        {(postingPeriodSettingValue === POSTING_PERIOD_SETTING_SET_UP) && (
                            <Row justify="start">
                                <Col lg={3} xs={24}>
                                    <Form.Item
                                        className="row-reverse"
                                        name={`otaFieldContent.${tabKey}.postingPeriodFrom`}
                                        validate={(value) => ValidateField(
                                            value,
                                            messages["page.apiPlanAddAndEdit.Jalan.postingPeriod"],
                                            {
                                                required: messages["form.error.mixed.required"],

                                            },
                                            {
                                                required: postingPeriodSettingValue === POSTING_PERIOD_SETTING_SET_UP,
                                            }
                                        )}
                                    >
                                        <DatePicker
                                            format={DATE_FORMAT}
                                            name={`otaFieldContent.${tabKey}.postingPeriodFrom`}
                                            disabled={postingPeriodSettingValue === POSTING_PERIOD_SETTING_NOT_SET}
                                            onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.postingPeriodFrom`, date)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={1} xs={24} className="content-middle max-h-34px">
                                    <div>～</div>
                                </Col>

                                <Col lg={3} xs={24}>
                                    <Form.Item
                                        className="row-reverse"
                                        name={`otaFieldContent.${tabKey}.postingPeriodTo`}
                                        validate={(value) => ValidateField(
                                            value,
                                            messages["page.apiPlanAddAndEdit.Jalan.postingPeriod"],
                                            {
                                                required: messages["form.error.mixed.required"],
                                            },
                                            {
                                                required: postingPeriodSettingValue === POSTING_PERIOD_SETTING_SET_UP,
                                            }
                                        )}
                                    >
                                        <DatePicker
                                            format={DATE_FORMAT}
                                            name={`otaFieldContent.${tabKey}.postingPeriodTo`}
                                            disabled={postingPeriodSettingValue === POSTING_PERIOD_SETTING_NOT_SET}
                                            onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.postingPeriodTo`, date)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                        <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.postingPeriod.note"]}</span>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.planNames`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.planName"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-15px">
                            <Col lg={12}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.planName`}
                                    className="row-reverse mr-1 gap-10px"
                                    label={
                                        <span className="text-note-row">
                                            {messages["page.apiPlanAddAndEdit.Jalan.textNoteInput"]}
                                        </span>
                                    }
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.planName"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Input
                                        name={`otaFieldContent.${tabKey}.planName`}
                                        maxLength={50}
                                        onBlur={handleInputBlur}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify="start" className="mb-25px">
                            <Col {...colLeftInputWithLabelApi}>
                                <Checkbox.Group
                                    onChange={
                                        (value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.ngRemoval`, value)
                                            setNgRemovalValue(value)
                                        }
                                    }
                                    value={ngRemovalValue}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.ngRemoval) {
                                            return (
                                                <Checkbox value={option.value}>{option.value}</Checkbox>
                                            )
                                        }
                                    })}
                                </Checkbox.Group>
                            </Col>
                        </Row>

                        {!isEmpty(ngRemovalValue) && (
                            <>
                                <Row justify="start" className="mb-25px">
                                    <Col lg={15}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.jalJalanPack`}
                                            label={messages["page.apiPlanAddAndEdit.Jalan.jalJalanPack"]}
                                            labelCol={{ lg: 5, xs: 24 }}
                                            wrapperCol={{ lg: 19, xs: 24 }}
                                        >
                                            <div className="d-flex align-items-center" style={{ marginLeft: "10px" }}>
                                                <Input
                                                    name={`otaFieldContent.${tabKey}.jalJalanPack`}
                                                    maxLength={50}
                                                    onBlur={handleInputBlur}
                                                />
                                                <span className="text-note-row text-inline ml-1">
                                                    {messages["page.apiPlanAddAndEdit.Jalan.textNoteInput"]}
                                                </span>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify="start" className="mb-25px">
                                    <Col lg={15}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.anaJalanPack`}
                                            label={messages["page.apiPlanAddAndEdit.Jalan.anaJalanPack"]}
                                            labelCol={{ lg: 5, xs: 24 }}
                                            wrapperCol={{ lg: 19, xs: 24 }}
                                        >
                                            <div className="d-flex align-items-center" style={{ marginLeft: "10px" }}>
                                                <Input
                                                    name={`otaFieldContent.${tabKey}.anaJalanPack`}
                                                    maxLength={50}
                                                    onBlur={handleInputBlur}
                                                />
                                                <span className="text-note-row text-inline ml-1">{messages["page.apiPlanAddAndEdit.Jalan.textNoteInput"]}</span>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}

                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.planDetails`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.planDetail"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-25px">
                            <Col lg={15}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.planDetail`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.planDetail"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Input.TextArea
                                        rows="4"
                                        name={`otaFieldContent.${tabKey}.planDetail`}
                                        setFieldValue={setFieldValue}
                                        maxLength={1000}
                                        onBlur={handleInputBlur}
                                    />
                                </Form.Item>
                                <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.textNoteArea"]}</span>
                            </Col>
                        </Row>

                        <Row justify="start" className="mb-25px">
                            <Col {...colLeftInputWithLabelApi}>
                                <Checkbox.Group
                                    onChange={
                                        (value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.ngRemovalDetail`, value)
                                            setNgRemovalDetailValue(value)
                                        }
                                    }
                                    value={ngRemovalDetailValue}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.ngRemovalDetail) {
                                            return (
                                                <Checkbox value={option.value}>{option.value}</Checkbox>
                                            )
                                        }
                                    })}
                                </Checkbox.Group>
                            </Col>
                        </Row>

                        {!isEmpty(ngRemovalDetailValue) && (
                            <>
                                <Row justify="start" className="mb-25px">
                                    <Col lg={16}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.jalJalanPackDetail`}
                                            label={messages["page.apiPlanAddAndEdit.Jalan.jalJalanPackDetail"]}
                                            labelCol={{ lg: 5, xs: 24 }}
                                            wrapperCol={{ lg: 19, xs: 24 }}
                                        >
                                            <Input.TextArea
                                                rows="4"
                                                name={`otaFieldContent.${tabKey}.jalJalanPackDetail`}
                                                maxLength={1000}
                                                onBlur={handleInputBlur}
                                            />
                                            <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.textNoteArea"]}</span>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify="start" className="mb-25px">
                                    <Col lg={16}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.anaJalanPackDetail`}
                                            label={messages["page.apiPlanAddAndEdit.Jalan.anaJalanPackDetail"]}
                                            labelCol={{ lg: 5, xs: 24 }}
                                            wrapperCol={{ lg: 19, xs: 24 }}
                                        >
                                            <Input.TextArea
                                                rows="4"
                                                name={`otaFieldContent.${tabKey}.anaJalanPackDetail`}
                                                maxLength={1000}
                                                onBlur={handleInputBlur}
                                            />
                                            <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.textNoteArea"]}</span>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}

                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name="none"
                        label={
                            <div className="d-grid">
                                <label>{messages["page.apiPlanAddAndEdit.Jalan.forGlobalCustomerAttractionService"]}</label>
                                <label>{messages["page.apiPlanAddAndEdit.Jalan.additionalInformation"]}</label>
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <span>{messages["page.apiPlanAddAndEdit.Jalan.planFeature"]}</span>
                        </Row>
                        <div className="ml-1">
                            <Row>
                                <span>{messages["page.apiPlanAddAndEdit.Jalan.breakfast"]}</span>
                            </Row>
                            <Row justify="start" className="mb-10px ml-1">
                                <Col {...colLeftInputWithLabelApi}>{messages["page.apiPlanAddAndEdit.Jalan.genre"]}</Col>
                                <Col lg={22} className="ml-1">
                                    <Form.Item
                                        name={`otaFieldContent.${tabKey}.breakfastGenreFeature`}
                                        wrapperCol={{ lg: 24, xs: 24 }}
                                    >
                                        <Checkbox.Group
                                            name={`otaFieldContent.${tabKey}.breakfastGenreFeature`}
                                            onChange={(value) => {
                                                handleChangeCheckbox(`otaFieldContent.${tabKey}.breakfastGenreFeature`, value)
                                                setBreakfastGenreFeatureValue(value)
                                            }}
                                            value={breakfastGenreFeatureValue}
                                        >
                                            <Row>
                                                {optionValues.map((option, index) => {
                                                    if (option.fieldKey === fieldKeyJalanApiPlanOption.breakfastGenreFeature) {
                                                        return (
                                                            <Checkbox
                                                                key={index}
                                                                value={option.value}
                                                                className="mr-1"
                                                            >
                                                                {option.value}
                                                            </Checkbox>
                                                        )
                                                    }
                                                })}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <span>{messages["page.apiPlanAddAndEdit.Jalan.dinner"]}</span>
                            </Row>
                            <Row justify="start" className="mb-10px ml-1">
                                <Col {...colLeftInputWithLabelApi}>{messages["page.apiPlanAddAndEdit.Jalan.genre"]}</Col>
                                <Col lg={22} className="ml-1 mb-10px">
                                    <Form.Item
                                        name={`otaFieldContent.${tabKey}.dinnerGenreFeature`}
                                        wrapperCol={{ lg: 24, xs: 24 }}
                                    >
                                        <Checkbox.Group
                                            name={`otaFieldContent.${tabKey}.dinnerGenreFeature`}
                                            onChange={(value) => {
                                                handleChangeCheckbox(`otaFieldContent.${tabKey}.dinnerGenreFeature`, value)
                                                setDinnerGenreFeatureValue(value)
                                            }}
                                            value={dinnerGenreFeatureValue}
                                        >
                                            <Row>
                                                {optionValues.map((option, index) => {
                                                    if (option.fieldKey === fieldKeyJalanApiPlanOption.dinnerGenreFeature) {
                                                        return (
                                                            <Checkbox
                                                                key={index}
                                                                value={option.value}
                                                                className="mr-1"
                                                            >
                                                                {option.value}
                                                            </Checkbox>
                                                        )
                                                    }
                                                })}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>

                                <Col {...colLeftInputWithLabelApi}>{messages["page.apiPlanAddAndEdit.Jalan.mainIngredients"]}</Col>
                                <Col lg={22} className="ml-1">
                                    <Form.Item
                                        name={`otaFieldContent.${tabKey}.dinnerMainIngredientFeature`}
                                        {...itemCheckBoxApi}
                                    >
                                        <Checkbox.Group
                                            name={`otaFieldContent.${tabKey}.dinnerMainIngredientFeature`}
                                            onChange={(value) => {
                                                handleChangeCheckbox(`otaFieldContent.${tabKey}.dinnerMainIngredientFeature`, value)
                                                setDinnerMainIngredientFeatureValue(value)
                                            }}
                                            value={dinnerMainIngredientFeatureValue}
                                        >
                                            <Row>
                                                {optionValues.map((option, index) => {
                                                    if (option.fieldKey === fieldKeyJalanApiPlanOption.dinnerMainIngredientFeature) {
                                                        return (
                                                            <Checkbox
                                                                key={index}
                                                                value={option.value}
                                                                className="mr-1"
                                                            >
                                                                {option.value}
                                                            </Checkbox>
                                                        )
                                                    }
                                                })}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Row className="ml-1">
                            <span>{messages["page.apiPlanAddAndEdit.Jalan.otherFeature"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10px ml-3">
                            <Col lg={22}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.otherFeature`}
                                    {...itemCheckBoxApi}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.otherFeature`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.otherFeature`, value)
                                            setOtherFeatureValue(value)
                                        }}
                                        value={otherFeatureValue}
                                    >
                                        <Row>
                                            {optionValues.map((option, index) => {
                                                if (option.fieldKey === fieldKeyJalanApiPlanOption.otherFeature) {
                                                    return (
                                                        <Checkbox
                                                            key={index}
                                                            value={option.value}
                                                            className="mr-1"
                                                        >
                                                            {option.value}
                                                        </Checkbox>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <span>{messages["page.apiPlanAddAndEdit.Jalan.freeWord"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10px ml-3">
                            <Col {...colInputLeftApi}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.freeWordPlanFeature`}
                                >
                                    <Input
                                        name={`otaFieldContent.${tabKey}.freeWordPlanFeature`}
                                        maxLength={50}
                                        onBlur={handleInputBlur}
                                    />
                                </Form.Item>
                                <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.50WordAttention"]}</span>
                            </Col>
                        </Row>
                        <Row>
                            <span>{messages["page.apiPlanAddAndEdit.Jalan.acceptanceCondition"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10px ml-3">
                            <Col lg={22}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.acceptanceConditionTypeFeature`}
                                    {...itemCheckBoxApi}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.acceptanceConditionTypeFeature`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.acceptanceConditionTypeFeature`, value)
                                            setAcceptanceConditionTypeFeatureValue(value)
                                        }}
                                        value={acceptanceConditionTypeFeatureValue}
                                    >
                                        <Row>
                                            {optionValues.map((option, index) => {
                                                if (option.fieldKey === fieldKeyJalanApiPlanOption.acceptanceConditionTypeFeature) {
                                                    return (
                                                        <Checkbox
                                                            key={index}
                                                            value={option.value}
                                                            className="mr-1"
                                                        >
                                                            {option.value}
                                                        </Checkbox>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <span>{messages["page.apiPlanAddAndEdit.Jalan.freeWord"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10px ml-3">
                            <Col {...colInputLeftApi}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.freeWordAcceptanceConditionFeature`}
                                    {...itemCheckBoxApi}
                                >
                                    <Input
                                        name={`otaFieldContent.${tabKey}.freeWordAcceptanceConditionFeature`}
                                        maxLength={50}
                                        onBlur={handleInputBlur}
                                    />
                                </Form.Item>
                                <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.50WordAttention"]}</span>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.reservationAcceptance`}
                        label={
                            <div>
                                <div className="custom-required">
                                    {messages["page.apiPlanAddAndEdit.Jalan.reservationAcceptance"]}
                                </div>
                                <div>{messages["page.apiPlanAddAndEdit.Jalan.reservationDeadline"]}</div>
                                <div className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <span class="mr-1">{messages["page.apiPlanAddAndEdit.Jalan.accommodationDay"]}</span>
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.reservationAcceptanceDay`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.sameReservationReception"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.reservationAcceptanceDay`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.dayOptions) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.reservationAcceptanceHour`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.sameReservationReception"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.reservationAcceptanceHour`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.hourOptions) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>

                                <span className="mb-8px min-width-30px">
                                    {messages["page.apiPlanAddAndEdit.Jalan.hour"]}
                                </span>
                            </div>

                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.reservationAcceptanceMinute`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.sameReservationReception"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.reservationAcceptanceMinute`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.minuteOptions) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>

                                <span className="mb-8px min-width-30px">
                                    {messages["page.apiPlanAddAndEdit.Jalan.minute"]}
                                </span>
                            </div>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.reservationCancellationDeadline`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.reservationCancellationDeadline"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.apiPlanAddAndEdit.Jalan.reservationCancellationDeadline"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true,
                            }
                        )}
                    >
                        <Radio.Group
                            name={`otaFieldContent.${tabKey}.reservationCancellationDeadline`}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                handleChangeRadio(e, fieldKeyJalanApiPlanOption.reservationCancellationDeadline)
                                setReservationCancellationDeadlineValue(e.target.value)
                            }}
                            value={reservationCancellationDeadlineValue}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyJalanApiPlanOption.reservationCancellationDeadline) {
                                    return (
                                        <Row className="mb-10px" key={index}>
                                            <Radio value={option.value}>
                                                <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                    {option.value}
                                                </span>
                                            </Radio>
                                        </Row>
                                    )
                                }
                            })}

                            {(reservationCancellationDeadlineValue === DIFFERENT_TIME_DEADLINE) && (
                                <Col className="d-flex flex-wrap">
                                    <div className="d-flex align-item-baseline">
                                        <span class="mr-1">{messages["page.apiPlanAddAndEdit.Jalan.accommodationDay"]}</span>
                                        <Form.Item
                                            wrapperCol={{ lg: 22, xs: 22 }}
                                            name={`otaFieldContent.${tabKey}.reservationCancellationDeadlineDay`}
                                            validate={(value) => ValidateField(
                                                value,
                                                messages["page.apiPlanAddAndEdit.Jalan.reservationCancellationDeadline"],
                                                {
                                                    required: messages["form.error.mixed.required"]
                                                },
                                                {
                                                    required: (reservationCancellationDeadlineValue === DIFFERENT_TIME_DEADLINE),
                                                }
                                            )}
                                        >
                                            <Select
                                                name={`otaFieldContent.${tabKey}.reservationCancellationDeadlineDay`}
                                                listHeight={320}
                                                style={styleInputCommon}
                                                allowClear
                                                showSearch
                                                {...searchSelect}
                                            >
                                                {optionValues.map((option, index) => {
                                                    if (option.fieldKey === fieldKeyJalanApiPlanOption.dayOptions) {
                                                        return (
                                                            <Option key={index} value={option.value}>
                                                                {option.value}
                                                            </Option>
                                                        )
                                                    }
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="d-flex align-item-baseline">
                                        <Form.Item
                                            wrapperCol={{ lg: 22, xs: 22 }}
                                            name={`otaFieldContent.${tabKey}.reservationCancellationDeadlineHour`}
                                            validate={(value) => ValidateField(
                                                value,
                                                messages["page.apiPlanAddAndEdit.Jalan.reservationCancellationDeadline"],
                                                {
                                                    required: messages["form.error.mixed.required"]
                                                },
                                                {
                                                    required: (reservationCancellationDeadlineValue === DIFFERENT_TIME_DEADLINE),
                                                }
                                            )}
                                        >
                                            <Select
                                                name={`otaFieldContent.${tabKey}.reservationCancellationDeadlineHour`}
                                                listHeight={320}
                                                style={styleInputCommon}
                                                allowClear
                                                showSearch
                                                {...searchSelect}
                                            >
                                                {optionValues.map((option, index) => {
                                                    if (option.fieldKey === fieldKeyJalanApiPlanOption.hourOptions) {
                                                        return (
                                                            <Option key={index} value={option.value}>
                                                                {option.value}
                                                            </Option>
                                                        )
                                                    }
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <span className="mb-8px min-width-30px">
                                            {messages["page.apiPlanAddAndEdit.Jalan.hour"]}
                                        </span>
                                    </div>
                                    <div className="d-flex align-item-baseline">
                                        <Form.Item
                                            wrapperCol={{ lg: 22, xs: 22 }}
                                            name={`otaFieldContent.${tabKey}.reservationCancellationDeadlineMinute`}
                                            validate={(value) => ValidateField(
                                                value,
                                                messages["page.apiPlanAddAndEdit.Jalan.reservationCancellationDeadline"],
                                                {
                                                    required: messages["form.error.mixed.required"]
                                                },
                                                {
                                                    required: (reservationCancellationDeadlineValue === DIFFERENT_TIME_DEADLINE),
                                                }
                                            )}
                                        >
                                            <Select
                                                name={`otaFieldContent.${tabKey}.reservationCancellationDeadlineMinute`}
                                                listHeight={320}
                                                style={styleInputCommon}
                                                allowClear
                                                showSearch
                                                {...searchSelect}
                                            >
                                                {optionValues.map((option, index) => {
                                                    if (option.fieldKey === fieldKeyJalanApiPlanOption.minuteOptions) {
                                                        return (
                                                            <Option key={index} value={option.value}>
                                                                {option.value}
                                                            </Option>
                                                        )
                                                    }
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <span className="mb-8px min-width-30px">
                                            {messages["page.apiPlanAddAndEdit.Jalan.minute"]}
                                        </span>
                                    </div>
                                </Col>
                            )}
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.checkInStartTime`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.checkInStartTime"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.checkInHour`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.checkInStartTime"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.checkInHour`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.hourOptions) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span
                                    className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.Jalan.hour"]}</span>
                            </div>
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.checkInMinute`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.checkInStartTime"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.checkInMinute`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.minuteOptions) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span
                                    className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.Jalan.minute"]}</span>
                            </div>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.checkInEndTime`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.checkInEndTime"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.lastCheckInHour`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.checkInEndTime"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.lastCheckInHour`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.hourOptions) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span
                                    className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.Jalan.hour"]}</span>
                            </div>
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.lastCheckInMinute`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.checkInEndTime"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.lastCheckInMinute`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.minuteOptions) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span
                                    className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.Jalan.minute"]}</span>
                            </div>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.checkOutTime`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.checkOutTime"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.checkOutHour`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.checkOutTime"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.checkOutHour`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.hourOptions) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span
                                    className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.Jalan.hour"]}</span>
                            </div>
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.checkOutMinute`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.Jalan.checkOutTime"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.checkOutMinute`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        allowClear
                                        showSearch
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiPlanOption.minuteOptions) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                                <span
                                    className="mb-8px min-width-30px">{messages["page.apiPlanAddAndEdit.Jalan.minute"]}</span>
                            </div>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.minimumNumberOfConsecutiveNight`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.minimumNumberOfConsecutiveNights"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-10px">
                            <Col {...colFullApi}>
                                <Row>
                                    <Col {...colLeftInputWithLabelApi}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.minimumConsecutiveNightType`}
                                            {...itemCheckBoxApi}
                                            validate={(value) => ValidateField(
                                                value,
                                                messages["page.apiPlanAddAndEdit.Jalan.minimumNumberOfConsecutiveNights"],
                                                {
                                                    required: messages["form.error.mixed.required"]
                                                },
                                                {
                                                    required: true,
                                                }
                                            )}
                                        >
                                            <Radio.Group
                                                name={`otaFieldContent.${tabKey}.minimumConsecutiveNightType`}
                                                onChange={(e) => {
                                                    handleChangeRadio(e)
                                                    setMinimumConsecutiveNightTypeValue(e.target.value)
                                                }}
                                                value={minimumConsecutiveNightTypeValue}
                                            >
                                                <Row>
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.minimumConsecutiveNightType) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                        {option.value}
                                                                    </span>
                                                                </Radio>
                                                            )
                                                        }
                                                    })}

                                                    {minimumConsecutiveNightTypeValue === LIMITED && (
                                                        <Form.Item
                                                            name={`otaFieldContent.${tabKey}.minimumConsecutiveNight`}
                                                            wrapperCol={{ lg: 16, xs: 24 }}
                                                            className="custom-input"
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <span className="text-inline mr-1">
                                                                    {messages["page.apiPlanAddAndEdit.Jalan.minimumConsecutiveNight.minimum"]}
                                                                </span>
                                                                <Input
                                                                    name={`otaFieldContent.${tabKey}.minimumConsecutiveNight`}
                                                                    maxLength={1}
                                                                    onBlur={handleInputBlur}
                                                                />
                                                                <span className="text-inline ml-1">
                                                                    {messages["page.apiPlanAddAndEdit.Jalan.minimumConsecutiveNight.nights"]}
                                                                </span>
                                                            </div>
                                                        </Form.Item>
                                                    )}

                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.maximumNumberOfConsecutiveNight`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.maximumNumberOfConsecutiveNights"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-10px">
                            <Col {...colFullApi}>
                                <Row>
                                    <Col {...colLeftInputWithLabelApi}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.maximumConsecutiveNightType`}
                                            {...itemCheckBoxApi}
                                            validate={(value) => ValidateField(
                                                value,
                                                messages["page.apiPlanAddAndEdit.Jalan.maximumNumberOfConsecutiveNights"],
                                                {
                                                    required: messages["form.error.mixed.required"]
                                                },
                                                {
                                                    required: true,
                                                }
                                            )}
                                        >
                                            <Radio.Group
                                                name={`otaFieldContent.${tabKey}.maximumConsecutiveNightType`}
                                                onChange={(e) => {
                                                    handleChangeRadio(e)
                                                    setMaximumConsecutiveNightTypeValue(e.target.value)
                                                }}
                                                value={maximumConsecutiveNightTypeValue}
                                            >
                                                <Row>
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.maximumConsecutiveNightType) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                        {option.value}
                                                                    </span>
                                                                </Radio>
                                                            )
                                                        }
                                                    })}

                                                    {maximumConsecutiveNightTypeValue === LIMITED && (
                                                        <Form.Item
                                                            name={`otaFieldContent.${tabKey}.maximumConsecutiveNight`}
                                                            wrapperCol={{ lg: 16, xs: 24 }}
                                                            className="custom-input"
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <span className="text-inline mr-1">
                                                                    {messages["page.apiPlanAddAndEdit.Jalan.maximumConsecutiveNight.maximum"]}
                                                                </span>
                                                                <Input
                                                                    name={`otaFieldContent.${tabKey}.maximumConsecutiveNight`}
                                                                    maxLength={1}
                                                                    onBlur={handleInputBlur}
                                                                />
                                                                <span className="text-inline ml-1">
                                                                    {messages["page.apiPlanAddAndEdit.Jalan.maximumConsecutiveNight.nights"]}
                                                                </span>
                                                            </div>
                                                        </Form.Item>
                                                    )}

                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.mealSetting`}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.apiPlanAddAndEdit.Jalan.mealSetting"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.required"]}</span>
                                </div>
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <span>{messages["page.apiPlanAddAndEdit.Jalan.breakfast"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10px">
                            <Col {...colFullApi}>
                                <Row>
                                    <Col {...colLeftInputWithLabelApi}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.mealOptionBreakfast`}
                                            {...itemCheckBoxApi}
                                            validate={(value) => ValidateField(
                                                value,
                                                messages["page.apiPlanAddAndEdit.Jalan.mealSetting"],
                                                {
                                                    required: messages["form.error.mixed.required"]
                                                },
                                                {
                                                    required: true,
                                                }
                                            )}
                                        >
                                            <Radio.Group
                                                name={`otaFieldContent.${tabKey}.mealOptionBreakfast`}
                                                onChange={(e) => {
                                                    handleChangeRadio(e, fieldKeyJalanApiPlanOption.mealOptionBreakfast)
                                                    setMealOptionBreakfastValue(e.target.value)
                                                }}
                                                value={mealOptionBreakfastValue}
                                            >
                                                <Row>
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.mealOptionBreakfast) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    <span className="mx-10px">
                                                                        {option.value}
                                                                    </span>
                                                                </Radio>
                                                            )
                                                        }
                                                    })}

                                                    {mealOptionBreakfastValue === WITH && (
                                                        <Checkbox.Group
                                                            onChange={(value) => {
                                                                handleChangeCheckbox(`otaFieldContent.${tabKey}.mealOptionBreakfastType`, value)
                                                                setMealOptionBreakfastTypeValue(value)
                                                            }}
                                                            value={mealOptionBreakfastTypeValue}
                                                        >
                                                            {optionValues.map((option, index) => {
                                                                if (option.fieldKey === fieldKeyJalanApiPlanOption.mealOptionBreakfastType) {
                                                                    return (
                                                                        <Checkbox
                                                                            key={index}
                                                                            value={option.value}
                                                                            className="mr-1"
                                                                        >
                                                                            {option.value}
                                                                        </Checkbox>
                                                                    )
                                                                }
                                                            })}
                                                        </Checkbox.Group>
                                                    )}

                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <span>{messages["page.apiPlanAddAndEdit.Jalan.dinner"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10px">
                            <Col {...colFullApi}>
                                <Row>
                                    <Col {...colLeftInputWithLabelApi}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.mealOptionDinner`}
                                            {...itemCheckBoxApi}
                                            validate={(value) => ValidateField(
                                                value,
                                                messages["page.apiPlanAddAndEdit.Jalan.mealSetting"],
                                                {
                                                    required: messages["form.error.mixed.required"]
                                                },
                                                {
                                                    required: true,
                                                }
                                            )}
                                        >
                                            <Radio.Group
                                                name={`otaFieldContent.${tabKey}.mealOptionDinner`}
                                                onChange={(e) => {
                                                    handleChangeRadio(e, fieldKeyJalanApiPlanOption.mealOptionDinner)
                                                    setMealOptionDinnerValue(e.target.value)
                                                }}
                                                value={mealOptionDinnerValue}
                                            >
                                                <Row>
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.mealOptionDinner) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    <span className="mx-10px">
                                                                        {option.value}
                                                                    </span>
                                                                </Radio>
                                                            )
                                                        }
                                                    })}

                                                    {mealOptionDinnerValue === WITH && (
                                                        <Checkbox.Group
                                                            onChange={(value) => {
                                                                handleChangeCheckbox(`otaFieldContent.${tabKey}.mealOptionDinnerType`, value)
                                                                setMealOptionDinnerTypeValue(value)
                                                            }}
                                                            value={mealOptionDinnerTypeValue}
                                                        >
                                                            {optionValues.map((option, index) => {
                                                                if (option.fieldKey === fieldKeyJalanApiPlanOption.mealOptionDinnerType) {
                                                                    return (
                                                                        <Checkbox
                                                                            key={index}
                                                                            value={option.value}
                                                                            className="mr-1"
                                                                        >
                                                                            {option.value}
                                                                        </Checkbox>
                                                                    )
                                                                }
                                                            })}
                                                        </Checkbox.Group>
                                                    )}

                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.questionForCustomer`}
                        label={messages["page.apiPlanAddAndEdit.Jalan.questionForReservationHolder"]}
                        {...itemLeftLabelFullApi}
                    >
                        <Row className="mb-10px">
                            <Col lg={12}>
                                <Input.TextArea
                                    rows="4"
                                    name={`otaFieldContent.${tabKey}.questionForCustomer`}
                                    onBlur={(e) => handleInputBlur(e, fieldKeyJalanApiPlanOption.questionForCustomer)}
                                    maxLength={200}
                                />
                                <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.200WordAttention"]}</span>
                            </Col>
                        </Row>

                        <Row className="mb-10px">
                            <Checkbox.Group
                                onChange={(value) => {
                                    handleChangeCheckbox(`otaFieldContent.${tabKey}.askCustomer`, value, fieldKeyJalanApiPlanOption.askCustomer)
                                    setAskCustomerValue(value)
                                }}
                                value={askCustomerValue}
                                disabled={questionForCustomerValue == null}
                            >
                                {optionValues.map((option, index) => {
                                    if (option.fieldKey === fieldKeyJalanApiPlanOption.askCustomer) {
                                        return (
                                            <Checkbox
                                                key={index}
                                                value={option.value}
                                                className="mr-1"
                                            >
                                                {option.value}
                                            </Checkbox>
                                        )
                                    }
                                })}
                            </Checkbox.Group>
                        </Row>

                        {!isEmpty(askCustomerValue) && (
                            <Row justify="start" className="ml-1">
                                <Checkbox.Group
                                    onChange={(value) => {
                                        handleChangeCheckbox(`otaFieldContent.${tabKey}.globalCustomerService`, value)
                                        setGlobalCustomerServiceValue(value)
                                    }}
                                    value={globalCustomerServiceValue}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.globalCustomerService) {
                                            return (
                                                <Checkbox
                                                    key={index}
                                                    value={option.value}
                                                    className="mr-1"
                                                >
                                                    {option.value}
                                                </Checkbox>
                                            )
                                        }
                                    })}
                                </Checkbox.Group>
                            </Row>
                        )}

                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.accommodationRequesting`}
                        label={messages["page.apiPlanAddAndEdit.Jalan.requestsForAccommodation"]}
                        {...itemLeftLabelFullApi}
                    >
                        <Checkbox.Group
                            onChange={(value) => {
                                handleChangeCheckbox(`otaFieldContent.${tabKey}.accommodationRequesting`, value)
                                setAccommodationRequestingValue(value)
                            }}
                            value={accommodationRequestingValue}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyJalanApiPlanOption.accommodationRequesting) {
                                    return (
                                        <Checkbox
                                            key={index}
                                            value={option.value}
                                            className="mr-1"
                                        >
                                            {option.value}
                                        </Checkbox>
                                    )
                                }
                            })}
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.listingOrder`}
                        label={messages["page.apiPlanAddAndEdit.Jalan.listingOrder"]}
                        {...itemLeftLabelFullApi}
                    >
                        <Checkbox.Group
                            onChange={(value) => {
                                handleChangeCheckbox(`otaFieldContent.${tabKey}.listingOrder`, value)
                                setListingOrderValue(value)
                            }}
                            value={listingOrderValue}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyJalanApiPlanOption.listingOrder) {
                                    return (
                                        <Checkbox
                                            key={index}
                                            value={option.value}
                                            className="mr-1"
                                        >
                                            {option.value}
                                        </Checkbox>
                                    )
                                }
                            })}
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.automaticPostingAccommodationLog`}
                        label={messages["page.apiPlanAddAndEdit.Jalan.automaticPostingAccommodationLog"]}
                        className="required"
                        {...itemLeftLabelFullApi}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.apiPlanAddAndEdit.Jalan.automaticPostingAccommodationLog"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: (postingPeriodSettingValue === POSTING_PERIOD_SETTING_NOT_SET) && !apiPlanId,
                            }
                        )}
                    >
                        <Radio.Group
                            name={`otaFieldContent.${tabKey}.automaticPostingAccommodationLog`}
                            onChange={(e) => {
                                handleChangeRadio(e)
                                setAutomaticPostingAccommodationLogValue(e.target.value)
                            }}
                            value={automaticPostingAccommodationLogValue}
                            disabled={(postingPeriodSettingValue === POSTING_PERIOD_SETTING_SET_UP) || (postingPeriodSettingValue == null)}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyJalanApiPlanOption.automaticPostingAccommodationLog) {
                                    return (
                                        <Row key={index}>
                                            <Radio value={option.value}>
                                                <span className="mx-10px">
                                                    {option.value}
                                                </span>
                                            </Radio>
                                        </Row>
                                    )
                                }
                            })}
                        </Radio.Group>

                        {automaticPostingAccommodationLogValue === POST_THE_INN_LOG_SPECIFY_CONTENT && (
                            <>
                                <Row justify="start" className="mb-10px">
                                    <Col lg={15}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.articleTitle`}
                                            label={messages["page.apiPlanAddAndEdit.Jalan.articleTitle"]}
                                            labelCol={{ lg: 4, xs: 24 }}
                                            wrapperCol={{ lg: 20, xs: 24 }}
                                            className="no-required"
                                        >
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    name={`otaFieldContent.${tabKey}.articleTitle`}
                                                    maxLength={25}
                                                    onBlur={handleInputBlur}
                                                />
                                                <span className="text-note-row text-inline ml-1">{messages["page.apiPlanAddAndEdit.Jalan.25WordAttention"]}</span>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify="start">
                                    <Col lg={15}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.articleDetail`}
                                            label={messages["page.apiPlanAddAndEdit.Jalan.articleDetail"]}
                                            labelCol={{ lg: 4, xs: 24 }}
                                            wrapperCol={{ lg: 20, xs: 24 }}
                                            className="no-required"
                                        >
                                            <Input.TextArea
                                                rows="4"
                                                name={`otaFieldContent.${tabKey}.articleDetail`}
                                                maxLength={1000}
                                                onBlur={handleInputBlur}
                                            />
                                            <span className="text-note-row">{messages["page.apiPlanAddAndEdit.Jalan.textNoteArea"]}</span>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.paymentMethod`}
                        label={messages["page.apiPlanAddAndEdit.Jalan.paymentMethod"]}
                        className="required"
                        {...itemLeftLabelFullApi}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.apiPlanAddAndEdit.Jalan.paymentMethod"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true,
                            }
                        )}
                    >
                        <Radio.Group
                            name={`otaFieldContent.${tabKey}.paymentMethod`}
                            onChange={(e) => {
                                handleChangeRadio(e, fieldKeyJalanApiPlanOption.paymentMethod)
                                setPaymentMethodValue(e.target.value)
                            }}
                            value={paymentMethodValue}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyJalanApiPlanOption.paymentMethod) {
                                    return (
                                        <Row key={index}>
                                            <Radio value={option.value}>
                                                <span className="mx-10px">
                                                    {option.value}
                                                </span>
                                            </Radio>
                                        </Row>
                                    )
                                }
                            })}
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            {paymentMethodValue === ONLINE_CARD_PAYMENT_ONLY && (
                <>
                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.cancelPolicyType`}
                                label={messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyType"]}
                                className="required"
                                {...itemLeftLabelFullApi}
                                validate={(value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.Jalan.cancelPolicyType"],
                                    {
                                        required: messages["form.error.mixed.required"]
                                    },
                                    {
                                        required: true,
                                    }
                                )}
                            >
                                <Radio.Group
                                    name={`otaFieldContent.${tabKey}.cancelPolicyType`}
                                    onChange={(e) => {
                                        handleChangeRadio(e, fieldKeyJalanApiPlanOption.cancelPolicyType)
                                        setCancelPolicyTypeValue(e.target.value)
                                    }}
                                    value={cancelPolicyTypeValue}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyJalanApiPlanOption.cancelPolicyType) {
                                            return (
                                                <Row key={index}>
                                                    <Radio value={option.value}>
                                                        <span className="mx-10px">
                                                            {option.value}
                                                        </span>
                                                    </Radio>
                                                </Row>
                                            )
                                        }
                                    })}

                                    {cancelPolicyTypeValue === INDIVIDUAL_CANCELLATION_POLICY_FOR_PLAN && (
                                        <Row>
                                            <Checkbox.Group
                                                onChange={(value) => {
                                                    handleChangeCheckbox(`otaFieldContent.${tabKey}.jalanPackApply`, value)
                                                    setJalanPackApplyValue(value)
                                                }}
                                                value={jalanPackApplyValue}
                                            >
                                                {optionValues.map((option, index) => {
                                                    if (option.fieldKey === fieldKeyJalanApiPlanOption.jalanPackApply) {
                                                        return (
                                                            <Checkbox
                                                                key={index}
                                                                value={option.value}
                                                                className="mr-1"
                                                            >
                                                                {option.value}
                                                            </Checkbox>
                                                        )
                                                    }
                                                })}
                                            </Checkbox.Group>
                                        </Row>
                                    )}

                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    {cancelPolicyTypeValue === INDIVIDUAL_CANCELLATION_POLICY_FOR_PLAN && (
                        cancelPolicyContent
                    )}
                </>
            )}

            <Row justify="start" className="mb-25px">
                <RoomJalanExtendFields
                    dataOtas={values}
                    setFieldValue={setFieldValue}
                    otaId={otaId}
                    storeId={storeId}
                    tabKey={tabKey}
                    apiPlanOtaId={apiPlanOtaId}
                    setEventKodomoSubmit={setEventKodomoSubmit}
                    isCloneData={isCloneData}
                    activeTabKey={activeTabKey}
                    optionValues={optionValues}
                />
            </Row>

            <Row justify="start" className="mb-25px">
              <UploadFileImageOta
                  values={values}
                  setFieldValue={setFieldValue}
                  otaId={otaId}
                  storeId={storeId}
                  tabKey={tabKey}
                  isCloneData={isCloneData}
              ></UploadFileImageOta>
          </Row>

            <Modal
              title={messages["page.apiPlanAddAndEdit.titleLog"]}
              style={{ top: 20 }}
              width={700}
              visible={showLog}
              footer={null}
              onCancel={() => setShowLog(false)}
          >
              <Row style={{maxHeight: '80vh', overflowY: 'auto', display: 'block'}}>
                  {syncErrorContent != null && syncErrorContent.length > 0 && syncErrorContent.map((x, index) => (
                      <Row style={{marginTop: '10px' }} key={index}>
                          <p key={index}>{x}</p>
                      </Row>
                  ))}
              </Row>
              {syncErrorContent == null || syncErrorContent.length <= 0 && (
                  <Row  style={{ padding: '10px', justifyContent: 'center' }}>
                      <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                              <span>{messages["antTable.emptyData"]}</span>
                          }
                      >
                      </Empty>
                  </Row>
              )}
          </Modal>
        </LayoutContentWrapper>
    );
};

Jalan.propTypes = {
    setFieldValue: PropTypes.any,
};

const jalanInitialValue = {
    package: ["じゃらん", "JCS"],
    registrationPeriodFrom: null,
    registrationPeriodTo: null,
    postingPeriodSetting: null,
    postingPeriodFrom: null,
    postingPeriodTo: null,
    planName: null,
    ngRemoval: [],
    jalJalanPack: null,
    anaJalanPack: null,
    planDetail: null,
    ngRemovalDetail: [],
    jalJalanPackDetail: null,
    anaJalanPackDetail: null,
    breakfastGenreFeature: [],
    dinnerGenreFeature: [],
    dinnerMainIngredientFeature: [],
    otherFeature: [],
    freeWordPlanFeature: null,
    acceptanceConditionTypeFeature: [],
    freeWordAcceptanceConditionFeature: null,
    reservationAcceptanceDay: null,
    reservationAcceptanceHour: null,
    reservationAcceptanceMinute: null,
    reservationCancellationDeadline: null,
    reservationCancellationDeadlineDay: null,
    reservationCancellationDeadlineHour: null,
    reservationCancellationDeadlineMinute: null,
    checkInHour: null,
    checkInMinute: null,
    lastCheckInHour: null,
    lastCheckInMinute: null,
    checkOutHour: null,
    checkOutMinute: null,
    minimumConsecutiveNightType: null,
    minimumConsecutiveNight: null,
    maximumConsecutiveNightType: null,
    maximumConsecutiveNight: null,
    mealOptionBreakfast: null,
    mealOptionBreakfastType: [],
    mealOptionDinner: null,
    mealOptionDinnerType: [],
    questionForCustomer: null,
    askCustomer: [],
    globalCustomerService: [],
    accommodationRequesting: [],
    listingOrder: [],
    automaticPostingAccommodationLog: null,
    articleTitle: null,
    articleDetail: null,
    paymentMethod: null,
    cancelPolicyType: null,
    jalanPackApply: [],
    settingCancelPolicy1: null,
    cancelPolicyFee1: null,
    settingCancelPolicy2: null,
    cancelPolicyFee2: null,
    settingCancelPolicy3: null,
    cancelPolicyFee3: null,
    dayOfStayFrom3: null,
    dayOfStayTo3: null,
    settingCancelPolicy4: null,
    cancelPolicyFee4: null,
    dayOfStayFrom4: null,
    dayOfStayTo4: null,
    settingCancelPolicy5: null,
    cancelPolicyFee5: null,
    dayOfStayFrom5: null,
    dayOfStayTo5: null,
    settingCancelPolicy6: null,
    cancelPolicyFee6: null,
    dayOfStayFrom6: null,
    dayOfStayTo6: null,
    settingCancelPolicy7: null,
    cancelPolicyFee7: null,
    dayOfStayFrom7: null,
    dayOfStayTo7: null,
}

const styleInputCommon = {
    minWidth: 168,
    marginRight: 15
}

export default memo(Jalan);
