import React, {memo, useState, useEffect, useCallback} from "react";
import {Col, Row, Button, Radio, Checkbox, Modal, DatePicker, Tooltip, Empty} from "antd";
import { Select, Form, Input } from "formik-antd";
import { useIntl } from "react-intl";
import { TabStyles } from "./TabStyle";
import { isEmpty } from "lodash";
import UploadFile from "../components/UploadFile";
import {
    colFull,
    colSingleUnit,
    customCheckboxGroupFullRow,
    customRadioGroupFullRow,
    inputColLeft,
    inputColRight
} from "@iso/assets/styles/form.style";
import roomAction from "@iso/redux/apiRoom/actions";
import {useDispatch} from "react-redux";
import {
    ERROR_STATUS,
    LIST_STATUS_SYNC,
    typeStatusCrawlOption,
    TYPE_ROOM_FORM,
    TYPE_ROOM_CRAWL,
    LIST_OPEN_SALE_STATUS, LIST_STATUS_SALE_PROCESSING, fieldKeyPublicFlagOption, searchSelect
} from "@iso/constants/api.constant";
import MoneyRegister from "../components/MoneyRegister/MoneyRegister";
import { ValidateField } from "@iso/validates/ValidateField";
import {ReloadOutlined} from "@ant-design/icons";
import { MODE_SCREEN } from "@iso/constants/common.constant";
import {colRight} from "@iso/containers/TaskLog";

const RakutenTab = ({
    values,
    dataOta,
    setFieldValue,
    tabKey,
    apiRoomId,
    imageByStoreAndOta,
    apiOptionValues,
    dataRoom,
    mode,
    setFieldTouched,
    setValues
}) => {
    const { messages } = useIntl();
    const dispatch = useDispatch();

    const [activeSize, setActiveSize] = useState(null)
    const [conditionValue, setConditionValue] = useState(null)
    const [activeRoomType, setActiveRoomType] = useState(true)
    const [roomEquimentValue, setRoomEquimentValue] = useState([])
    const [filterConditionsValue, setFilterConditionsValue] = useState([])
    const [optionValues, setOptionValues] = useState([])
    const [borderConditionSelectRequired, setBorderConditionSelectRequired] = useState(false)
    const [borderAcreageRequired, setBorderAcreageRequired] = useState(false)
    const [isSyncDataToSite, setIsSyncDataToSite] = useState(false)
    const [isUpdateDataFromSite, setIsUpdateDataFromSite] = useState(false)
    const [apiRoomOtaId, setApiRoomOtaId] = useState(null)
    const [roomEquipmentOptions, setRoomEquipmentOptions] = useState([])
    const [syncErrorContentFirst, setSyncErrorContentFirst] = useState(null)
    const [syncErrorContent, setSyncErrorContent] = useState([])
    const [showLog, setShowLog] = useState(false);
    const [loadingRoom, setLoadingRoom] = useState(false)
    const [disableSyncData, setDisableSyncData] = useState(true)
    const equipmentBus = messages["page.room.bus"];
    const equipmentOnlyShower = messages["page.room.onlyShower"];
    const [roomPublicFlagValue, setRoomPublicFlagValue] = useState([])

    const [peopleFromHistory, setPeopleFromHistory] = useState(null);
    const [peopleToHistory, setPeopleToHistory] = useState(null);

    useEffect(() => {
        if (apiRoomId 
            && !isEmpty(dataRoom.apiRoomOtas[tabKey])
            && (dataRoom.apiRoomOtas[tabKey].typeRoom == TYPE_ROOM_FORM
                || (dataRoom.apiRoomOtas[tabKey].typeRoom == TYPE_ROOM_CRAWL
                    && dataRoom.apiRoomOtas[tabKey].updateStatus == typeStatusCrawlOption.crawlSuccess
                ))
        ) {
            setDisableSyncData(false)
        } else {
            setDisableSyncData(true)
        }
    }, [dataRoom])
    useEffect(() => {
        setFieldValue(`apiRoomOtas[${tabKey}]['otaId']`, dataOta.otaId)
        if (apiRoomId && !isEmpty(values) && !isEmpty(values.apiRoomOtas[tabKey]) && !isEmpty(values.apiRoomOtas[tabKey].fieldContents)) {
            setFieldValue(`apiRoomOtas[${tabKey}]['imageStorage']`, dataRoom.apiRoomOtas[tabKey]?.imageStorage)
            setFieldValue(`apiRoomOtas[${tabKey}]['imageUrl']`, dataRoom.apiRoomOtas[tabKey]?.imageUrl)
            setPeopleFromHistory(dataRoom.apiRoomOtas[tabKey].fieldContents.totalPeopleFrom)
            setPeopleToHistory(dataRoom.apiRoomOtas[tabKey].fieldContents.totalPeopleTo)
            setActiveSize(values.apiRoomOtas[tabKey].fieldContents.acreageType)
            setConditionValue(values.apiRoomOtas[tabKey].fieldContents.roomConditions)
            const roomEquiment = values.apiRoomOtas[tabKey]?.fieldContents?.roomEquiment;
            setRoomEquimentValue(Array.isArray(roomEquiment) ? roomEquiment : []);
            setFilterConditionsValue(values.apiRoomOtas[tabKey].fieldContents.filterConditions)
            setRoomPublicFlagValue(values.apiRoomOtas[tabKey].fieldContents.publicFlag)
            setApiRoomOtaId(values.apiRoomOtas[tabKey]?.apiRoomOtaId)
            const syncErrorFirst = getSyncError(values);
            setSyncErrorContentFirst(syncErrorFirst[0])
            const filteredArray = syncErrorFirst.filter(item => item.trim() !== '');
            setSyncErrorContent(filteredArray)
            if (values.apiRoomOtas[tabKey].fieldContents.roomConditions == messages["page.room.ota.roomType.western"]) {
                setActiveRoomType(false)
            }

            if (mode == MODE_SCREEN.COPY) {
                setFieldValue(`apiRoomOtas[${tabKey}]['syncStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['updateStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['syncErrorContent']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['openSaleStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}][fieldContents]['openSaleOtaStatus']`, null);
                setSyncErrorContentFirst(null);
            }
        } else {
            if (!apiRoomId || Array.isArray(values.otaId) && values.otaId.includes(tabKey)) {
                setFieldValue(`apiRoomOtas[${tabKey}]['siteRoomId']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}].fieldContents`, rakutenInitialValue)
                setFieldValue(`apiRoomOtas[${tabKey}]['imageStorage']`, [])
                setFieldValue(`apiRoomOtas[${tabKey}]['imageUrl']`, [])
                setFieldValue(`apiRoomOtas[${tabKey}].syncStatus`, 0)
                setFieldValue(`apiRoomOtas[${tabKey}][fieldContents]['openSaleOtaStatus']`, null);
                setFieldTouched(`apiRoomOtas[${tabKey}].siteRoomId`, false);
                setFieldTouched(`apiRoomOtas[${tabKey}].fieldContents`, false);
                onChangePublicFlag([fieldKeyPublicFlagOption.accommodationOnly, fieldKeyPublicFlagOption.package], `apiRoomOtas[${tabKey}]['fieldContents']['publicFlag']`)
            }
        }
        if (!isEmpty(apiOptionValues)) {
            apiOptionValues.map((apiOptionValue) => {
                if (apiOptionValue.otaId == dataOta.otaId) {
                    let roomEquipment = findValueByKey('room_equiment', apiOptionValue.options)
                    roomEquipment = roomEquipment.map((equipment) => {
                        if (equipment.value === equipmentBus && roomEquimentValue.includes(equipmentOnlyShower)) {
                            return {...equipment, disabled: true}
                        } else if (equipment.value === equipmentOnlyShower && roomEquimentValue.includes(equipmentBus)) {
                            return {...equipment, disabled: true}
                        } else {
                            return {...equipment, disabled: false}
                        }
                    })
                    setRoomEquipmentOptions(roomEquipment)
                    setOptionValues(apiOptionValue.options)
                }
            })
        }
    }, [apiOptionValues, dataOta, mode]);

    const getSyncError = (values) => {
        const syncErrorContent = values.apiRoomOtas[tabKey]?.syncErrorContent || '';
        const syncErrorArray = syncErrorContent.split("\n").map(item => item.trim());
        return syncErrorArray || [];
    }
    const findValueByKey = (key, options) => options.filter((value) => value.fieldKey === key);
    const onChangeConditions = (value, name) => {
        setFieldValue(name, value.target.value)
        setConditionValue(value.target.value)
        setBorderConditionSelectRequired(true)

        if (value.target.value == messages["page.room.ota.roomType.western"]) {
            setActiveRoomType(false)
        } else {
            setActiveRoomType(true)
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['roomConditionType1']`, '')
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['roomConditionType2']`, '')
        }
    };

    const onChangeSize = (value, name) => {
        setFieldValue(name, value.target.value)
        setActiveSize(value.target.value)
        setBorderAcreageRequired(true)
    };

    const onChangeEquipmentConditions = (values, name) => {
        setFieldValue(name, values)
        setRoomEquimentValue(values)
    };

    const onChangePublicFlag = (values, name) => {
        setFieldValue(name, values)
        setRoomPublicFlagValue(values)
    };

    const onChangeFilterCondition = (values, name) => {
        setFieldValue(name, values)
        setFilterConditionsValue(values)
    };

    const onChangePaymentMethod = (values, name) => {
        setFieldValue(name, values)
    };

    const onChangeEquipment = useCallback((e) => {
        let equipmentChecked = e.target.value
        let roomEquipment = roomEquipmentOptions;
        if (equipmentChecked === equipmentBus) {
            roomEquipment = roomEquipmentOptions.map((equipment) => {
                return {
                    ...equipment,
                    disabled: equipment.value === equipmentOnlyShower && e.target.checked === true
                }
            })
        }
        else if (equipmentChecked === equipmentOnlyShower) {
            roomEquipment = roomEquipmentOptions.map((equipment) => {
                return {
                    ...equipment,
                    disabled: equipment.value === equipmentBus && e.target.checked === true
                }
            })
        }
        setRoomEquipmentOptions(roomEquipment)
    }, [roomEquipmentOptions]);

    const handleReloadRoom = () => {
        if (apiRoomId) {
            new Promise((resolve, reject) => {
                setLoadingRoom(true)
                dispatch(roomAction.reloadRoom({ apiRoomId, resolve, reject }))
            }).finally(() => {
                setLoadingRoom(false)
            })
         }
    }
    
    const syncDataToSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsSyncDataToSite(true)
             return dispatch(roomAction.syncDataToSite({ apiRoomOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.room.modal.updateSuccessTitle"],
                content: messages[`page.room.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.room.modal.inProgressSyncError`]
                : messages[`page.room.modal.updateError`]
            Modal.error({
                title: messages["page.room.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsSyncDataToSite(false)
        })
    });

    const updateDataFromSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsUpdateDataFromSite(true)
            return dispatch(roomAction.updateDataFromSite({ apiRoomOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.room.modal.updateSuccessTitle"],
                content: messages[`page.room.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.room.modal.inProgressSyncError`]
                : messages[`page.room.modal.updateError`]
            Modal.error({
                title: messages["page.room.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsUpdateDataFromSite(false)
        })
    });

    const handleInputBlur  = (event) => {
        const trimmedValue = trimSpaces(event.target.value);
        setFieldValue(event.target.name, trimmedValue)
    };

    const trimSpaces = (inputString) => {
        if (typeof inputString !== 'string') {
          return '';
        }
        let elements = inputString.split(',');
        let trimmedElements = elements.map(element => element.trim());
        return trimmedElements.join(',');
      }

    return (
        <TabStyles>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div className="pb-25px">
                                <div className="custom-required">{messages["page.room.site.id"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.room.siteRoomIdNoteRequired"]}</span>
                                </div>
                                <div>
                                    <span className="text-note-row">{messages["page.room.siteRoomIdNoteMax"]}</span>
                                </div>
                            </div>
                            
                        }
                        name={`apiRoomOtas[${tabKey}]['siteRoomId']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.site.id"],
                            {
                                required: messages["form.error.mixed.required"],
                                valueInvalid: messages["page.room.valueInvalid"]

                            },
                            {
                                required : true,
                                space : true,
                                symbols : true
                            }
                        )}
                        className="custom-label"
                    >
                        <Input
                            disabled={(mode == MODE_SCREEN.COPY) ? false : apiRoomOtaId}
                            name={`apiRoomOtas[${tabKey}]['siteRoomId']`}
                            maxLength={10}
                        />
                    </Form.Item>
                </Col>
                <Col {...colRight} style={{display: 'flex' , justifyContent: 'end'}}>
                    <Button
                        type="primary"
                        className="mr-10 button"
                        block
                        hidden={apiRoomOtaId === null || apiRoomOtaId === undefined || mode == MODE_SCREEN.COPY}
                        loading={isSyncDataToSite}
                        onClick={syncDataToSite}
                        disabled={disableSyncData}
                    >
                        {messages["page.room.sync"]}
                    </Button>
                    <Button
                        type="primary"
                        className="mr-10 button"
                        block
                        hidden={apiRoomOtaId === null || apiRoomOtaId === undefined || mode == MODE_SCREEN.COPY}
                        loading={isUpdateDataFromSite}
                        onClick={updateDataFromSite}
                    >
                        {messages["page.room.crawlUpdate"]}
                    </Button>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColRight}
                        label={messages["page.room.ota.syncStatus"]}
                        name={`apiRoomOtas[${tabKey}]['syncStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['syncStatus']`}
                            disabled={true}
                            {...searchSelect}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} className={'position-error-message'}>
                    <Row>
                        <Col xs={24} lg={2}>
                            <Button
                                type="primary"
                                className="mr-2"
                                loading={loadingRoom}
                                icon={<ReloadOutlined />}
                                disabled={mode == MODE_SCREEN.COPY}
                                onClick={() => handleReloadRoom()}>
                            </Button>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['syncErrorContent']`}
                            >
                                <Input
                                    className="styleInputLog"
                                    onClick={() => setShowLog(true)}
                                    value={syncErrorContentFirst}
                                    name={`apiRoomOtas[${tabKey}]['syncErrorContent']`}
                                    disabled={mode == MODE_SCREEN.COPY}
                                    readOnly
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColRight}
                        label={messages["page.room.ota.updateStatus"]}
                        name={`apiRoomOtas[${tabKey}]['updateStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['updateStatus']`}
                            disabled={true}
                            {...searchSelect}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColRight}
                        label={messages["page.room.ota.openSaleStatus"]}
                        name={`apiRoomOtas[${tabKey}]['openSaleStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['openSaleStatus']`}
                            disabled={true}
                            {...searchSelect}
                        >
                            {LIST_STATUS_SALE_PROCESSING.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColRight}
                        label={messages["page.room.ota.openSaleOtaStatus"]}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['openSaleOtaStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['openSaleOtaStatus']`}
                            disabled={true}
                            {...searchSelect}
                        >
                            {LIST_OPEN_SALE_STATUS.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col  {...colFull}>
                    <Form.Item
                        label={messages["page.room.ota.publicFlag"]}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['publicFlag']`}
                        {...customCheckboxGroupFullRow}
                    >
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['publicFlag']`}
                            onChange={(values) => onChangePublicFlag(values, `apiRoomOtas[${tabKey}]['fieldContents']['publicFlag']`)}
                            value={roomPublicFlagValue}
                        >
                            <Row>
                                {optionValues.map((filterCondition, index) => {
                                    if (filterCondition.fieldKey == "public_flag") {
                                        return (
                                            <Col span={7} key={index} className="mb-5">
                                                <Checkbox value={filterCondition.value}>
                                                    {filterCondition.value}
                                                </Checkbox>
                                            </Col>
                                        );
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.room.ota.roomType.title"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.room.roomTypeNote"]}</span>
                                </div>
                            </div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['name']`}
                        className="custom-label"
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ota.roomType.title"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Input
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['name']`}
                            maxLength={30}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div>
                                <div>{messages["page.room.ota.roomType.title"]}</div>
                                <div>{messages["page.room.ota.package"]}</div>
                            </div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['packageName']`}
                        className="custom-label"
                    >
                        <Input
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['packageName']`}
                            maxLength={30}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <span className="text-note-row">{messages["page.room.roomTypePackageNote"]}</span>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.room.ota.amountPeople"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.room.amountPeopleNote"]}</span>
                                </div>
                            </div>
                        }
                        name="amount_people"
                        className="custom-label"
                    >
                        <Row gutter={[10, 10]}>
                            <Col xs={24} xl={12}>
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['totalPeopleFrom']`}
                                    labelCol={{ lg: 24 }}
                                    wrapperCol={{ lg: 24 }}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.ota.amountPeople"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                            integer: messages["form.error.number.integer"],
                                            valueInvalid: messages["page.room.valueInvalid"]

                                        },
                                        {
                                            required : true,
                                            integer : true,
                                            rangeValid: true,
                                            customRulePeople: true
                                        },
                                        value,
                                        values.apiRoomOtas[tabKey].fieldContents.totalPeopleTo,
                                        {
                                            "people" : 'from',
                                            "valueHistory" : peopleFromHistory
                                        }
                                    )}
                                >
                                    <Input
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['totalPeopleFrom']`}
                                        maxLength={2}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} xl={12}>
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['totalPeopleTo']`}
                                    labelCol={{ lg: 24 }}
                                    wrapperCol={{ lg: 24 }}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.ota.amountPeople"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                            integer: messages["form.error.number.integer"],
                                            valueInvalid: messages["page.room.valueInvalid"]

                                        },
                                        {
                                            required : true,
                                            integer : true,
                                            rangeValid: true,
                                            customRulePeople: true
                                        },
                                        values.apiRoomOtas[tabKey].fieldContents.totalPeopleFrom,
                                        value,
                                        {
                                            "people" : 'to',
                                            "valueHistory" : peopleToHistory
                                        }
                                    )}
                                >
                                    <Input
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['totalPeopleTo']`}
                                        maxLength={2}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div>
                                <div className="custom-required">{messages["page.room.ota.memo"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.room.memoNote"]}</span>
                                </div>
                            </div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['memo']`}
                        className="custom-label"
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ota.memo"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Input
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['memo']`}
                            maxLength={80}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div>
                                <div>{messages["page.room.ota.memo"]}</div>
                                <div>{messages["page.room.ota.package"]}</div>
                            </div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['packageMemo']`}
                        className="custom-label"
                    >
                        <Input
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['packageMemo']`}
                            maxLength={80}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <span className="text-note-row">{messages["page.room.memoNote"]}</span>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colFull}>
                    <Form.Item
                        label={
                            <div>
                                <div className="custom-required">{messages["page.room.acreage"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.room.acreageTypeNote"]}</span>
                                </div>
                            </div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['acreageType']`}
                        {...customRadioGroupFullRow}
                        className={borderAcreageRequired ? "custom-label" : "custom-label border-required-none"}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.acreage"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Radio.Group
                            onChange={(values) => onChangeSize(values, `apiRoomOtas[${tabKey}]['fieldContents']['acreageType']`)}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['acreageType']`}
                            value={activeSize}
                        >
                            <Row justify="start">
                                {optionValues.map((option, index) => {
                                    if (option.fieldKey == "acreage_type") {
                                        return (
                                            <Col style={{ display: "flex", marginRight: "2rem" }} key={index}>
                                                <Radio value={option.value} className={"item-center"} />
                                                <Form.Item
                                                    name={activeSize == option.value ? `apiRoomOtas[${tabKey}]['fieldContents']['acreage']` : 'other'}
                                                    validate={(activeSize == option.value) ? 
                                                        (value) => ValidateField(
                                                            value,
                                                            messages["page.room.acreage"],
                                                            {
                                                                required: messages["form.error.mixed.required"],
                                                                number: messages["form.error.number.true"],
                                                                valueInvalid: messages["page.room.valueInvalid"]

                                                            },
                                                            {
                                                                required : true,
                                                                number : true,
                                                                space: true
                                                            }
                                                        )
                                                    : false}
                                                >
                                                    <Input
                                                        name={activeSize == option.value ? `apiRoomOtas[${tabKey}]['fieldContents']['acreage']` : 'other'}
                                                        disabled={(activeSize == option.value ? false : true)}
                                                        maxLength={8}
                                                    />
                                                </Form.Item>
                                                <span style={{ marginLeft: "5px", whiteSpace: 'nowrap' }} className={"item-center"}>
                                                    {option.value}
                                                </span>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colFull}>
                    <Form.Item
                        label={
                            <div>
                                <div className="custom-required">{messages["page.room.ota.conditions"]}</div>
                                <div className="text-note-row">{messages["page.room.conditionNote"]}</div>
                            </div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['roomConditions']`}
                        {...customRadioGroupFullRow}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ota.conditions"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                        className={borderConditionSelectRequired ? "custom-label" : "custom-label border-required-none"}
                    >
                        <Radio.Group
                            onChange={(values) => onChangeConditions(values, `apiRoomOtas[${tabKey}]['fieldContents']['roomConditions']`)}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['roomConditions']`}
                            value={conditionValue}
                            style={{ width: '100%' }}
                        >
                            {
                                optionValues.map((conditions, index) => {
                                    if (conditions.fieldKey == "room_conditions" && conditions.apiOptionValueId == 10003) {
                                        return (
                                            <Row justify="start" className="mb-10" key={index}>
                                                <Col className="mr-10" style={{ display: "flex" }}>
                                                    <Radio value={conditions.value} className={"item-center"}>
                                                        <span style={{ marginLeft: 10, marginRight: 10 }}>{messages["page.room.ota.roomType.western"]}</span>
                                                    </Radio>
                                                    <Form.Item
                                                        name={!activeRoomType ? `apiRoomOtas[${tabKey}]['fieldContents']['roomConditionType1']` : 'other1'}
                                                        style={{ width: '100%' }}
                                                        validate={!activeRoomType ?
                                                            (value) => ValidateField(
                                                                value,
                                                                messages["page.room.ota.roomType.western"],
                                                                {
                                                                    required: messages["form.error.mixed.required"]
                                                                },
                                                                {
                                                                    required: true
                                                                }
                                                            )
                                                        : false}
                                                    >
                                                        <Select
                                                            className="select_room_type_option"
                                                            name={!activeRoomType ? `apiRoomOtas[${tabKey}]['fieldContents']['roomConditionType1']` : 'other1'}
                                                            disabled={activeRoomType}
                                                            style={{ minWidth: '200px' }}
                                                            {...searchSelect}
                                                        >
                                                            {optionValues.map((option, index) => {
                                                                if (option.fieldKey == "room_condition_type1") {
                                                                    return (
                                                                        <Select.Option
                                                                            key={index}
                                                                            value={option.value}
                                                                            title={option.value}
                                                                        >
                                                                            {option.value}
                                                                        </Select.Option>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col style={{ display: "flex" }}>
                                                    <Form.Item
                                                        name={!activeRoomType ? `apiRoomOtas[${tabKey}]['fieldContents']['roomConditionType2']` : 'other2'}
                                                        style={{ width: '100%' }}
                                                        label={messages["page.room.conditions"]}
                                                        className="label-room-condition-2"
                                                        validate={!activeRoomType ?
                                                            (value) => ValidateField(
                                                                value,
                                                                messages["page.room.conditions"],
                                                                {
                                                                    required: messages["form.error.mixed.required"]
                                                                },
                                                                {
                                                                    required: true
                                                                }
                                                            )
                                                        : false}
                                                    >
                                                        <Select
                                                            className="select_room_type_option"
                                                            style={{ minWidth: '200px' }}
                                                            name={!activeRoomType ? `apiRoomOtas[${tabKey}]['fieldContents']['roomConditionType2']` : 'other2'}
                                                            disabled={activeRoomType}
                                                            {...searchSelect}
                                                        >
                                                            {optionValues.map((option, index) => {
                                                                if (option.fieldKey == "room_condition_type2") {
                                                                    return (
                                                                        <Select.Option
                                                                            key={index}
                                                                            value={option.value}
                                                                            title={option.value}
                                                                        >
                                                                            {option.value}
                                                                        </Select.Option>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )
                                    }

                                    if (conditions.fieldKey == "room_conditions" && conditions.apiOptionValueId != 10003) {
                                        return (
                                            <Row justify="start" className="mb-10" key={index}>
                                                <Radio value={conditions.value}>
                                                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                        {conditions.value}
                                                    </span>
                                                </Radio>
                                            </Row>
                                        )
                                    }
                                })
                            }
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colFull}>
                    <Form.Item
                        label={messages["page.room.ota.equipmentConditions"]}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['roomEquiment']`}
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 12, xs: 24 }}
                    >
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['roomEquiment']`}
                            onChange={(values) => onChangeEquipmentConditions(values, `apiRoomOtas[${tabKey}]['fieldContents']['roomEquiment']`)}
                            value={roomEquimentValue}
                        >
                            <Row>
                                {roomEquipmentOptions.map((equiment, index) => {
                                    return (
                                        <Col span={8} key={index}>
                                            <Checkbox
                                                value={equiment.value}
                                                disabled={equiment.disabled}
                                                onChange={onChangeEquipment}
                                            >
                                                {equiment.value}
                                            </Checkbox>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colFull}>
                    <Form.Item
                        label={messages["page.room.ota.filter"]}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['filterConditions']`}
                        {...customCheckboxGroupFullRow}
                    >
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['filterConditions']`}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas[${tabKey}]['fieldContents']['filterConditions']`)}
                            value={filterConditionsValue}
                        >
                            <Row>
                                {optionValues.map((filterCondition, index) => {
                                    if (filterCondition.fieldKey == "filter_conditions") {
                                    return (
                                        <Col span={6} key={index} className="mb-5">
                                            <Checkbox value={filterCondition.value}>
                                                {filterCondition.value}
                                            </Checkbox>
                                        </Col>
                                    );
                                }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25">
                <Col  {...colSingleUnit}>
                    <Form.Item
                        label={messages["page.room.ota.paymentMethod"]}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['paymentMethods']`}
                        className="required"
                        {...inputColLeft}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ota.paymentMethod"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Select
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['paymentMethods']`}
                            onChange={(values) => onChangePaymentMethod(values, `apiRoomOtas[${tabKey}]['fieldContents']['paymentMethods']`)}
                            {...searchSelect}
                        >
                            {optionValues.map((payment, index) => {
                                if (payment.fieldKey == "payment_methods") {
                                    return (
                                        <Select.Option
                                            key={index}
                                            value={payment.value}
                                        >
                                            {payment.value}
                                        </Select.Option>
                                    );
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <UploadFile
                values={values}
                dataOta={dataOta}
                setFieldValue={setFieldValue}
                tabKey={tabKey}
                apiRoomId={apiRoomId}
                imageByStoreAndOta={imageByStoreAndOta}
                dataRoom={dataRoom}
                mode={mode}
            />

            <MoneyRegister
                values={values}
                dataOta={dataOta}
                setFieldValue={setFieldValue}
                tabKey={tabKey}
                apiRoomId={apiRoomId}
                imageByStoreAndOta={imageByStoreAndOta}
                optionValues={optionValues}
                dataRoom={dataRoom}
                mode={mode}
            />

            <Modal
                title={messages["page.crawlRoom.titleLog"]}
                style={{ top: 20 }}
                width={700}
                visible={showLog}
                footer={null}
                onCancel={() => setShowLog(false)}
            >
                <Row style={{maxHeight: '80vh', overflowY: 'auto', display: 'block'}}>
                    {syncErrorContent != null && syncErrorContent.length > 0 && syncErrorContent.map((x, index) => (
                        <Row style={{marginTop: '10px' }}>
                            <p key={index}>{x}</p>
                        </Row>
                    ))}
                </Row>
                {syncErrorContent == null || syncErrorContent === '' || syncErrorContent.length <= 0 && (
                    <Row  style={{ padding: '10px', justifyContent: 'center' }}>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>{messages["antTable.emptyData"]}</span>
                            }
                        >
                        </Empty>
                    </Row>
                )}
            </Modal>
        </TabStyles>
    )
}

const rakutenInitialValue = {
    publicFlag: null,
    name: null,
    packageName: null,
    totalPeopleFrom: null,
    totalPeopleTo: null,
    memo: null,
    packageMemo: null,
    acreageType: null,
    acreage: null,
    roomConditions: null,
    roomConditionType1: null,
    roomConditionType2: null,
    roomEquiment: [],
    filterConditions: [],
    paymentMethods: null,
    priceTypeFormat: null,
    priceDayFormat: null,
    settingPeriodFromYear: null,
    settingPeriodFromMonth: null,
    settingPeriodToYear: null,
    settingPeriodToMonth: null,
    openSaleOtaStatus: null,
    moneyRegister: []
}

export default memo(RakutenTab);
