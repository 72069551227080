import React, {useEffect, useRef, useState} from "react";
import {Tooltip} from "antd";
import PropTypes from "prop-types";

const TooltipCustom = ({value}) => {
    const ref = useRef(null);
    const [isActiveTooltip, setIsActiveTooltip] = useState(false);

    useEffect(() => {
        const element = ref.current;
        if (element) {
            setIsActiveTooltip(element.scrollWidth > element.clientWidth)
        }
    }, [value]);

    return (
        <Tooltip
            title={isActiveTooltip ? value : ''}
            placement={"right"}
        >
            <div ref={ref} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                {value}
            </div>
        </Tooltip>
    )
}

TooltipCustom.propTypes = {
    value: PropTypes.any
};

export default TooltipCustom;