import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { useIntl } from "react-intl";
import { InputNumber } from "formik-antd";
import { isEmpty } from "lodash";


const InputPoint = ({ name, maxValueCondition, maxLengthCondition, isHoliday = null, option = null}) => {
    const { messages } = useIntl();

    return (
        <Form.Item
            name={name}
            label={messages["page.room.moneyRegister.percent"]}
            style={{ display: "flex", flexDirection : "row-reverse", width: "80px" }}
            rules={[
                {
                    validator(_, value) {
                        if (value === null || value === undefined || value === "") {
                            return Promise.reject(
                                messages["form.error.mixed.required"].replace(
                                    "${path}",
                                    messages["page.room.moneyRegister.setting"]
                                )
                            );
                        }

                        const regex = /^\d+$/;
                        if (value != "" && value != null && !regex.test(value)) {
                            return Promise.reject(
                                messages["form.error.number.integer"].replace(
                                    "${path}",
                                    messages["page.room.moneyRegister.setting"]
                                )
                            );
                        }

                        return Promise.resolve();
                    },
                },
            ]}
        >
            <InputNumber
                name={name}
                min={0}
                max={maxValueCondition}
                maxLength={maxLengthCondition}
                defaultValue={1}
                className="w-50"
                disabled={(isHoliday != null && option == "holiday" && isEmpty(isHoliday))}
            />
        </Form.Item>
    );
};

export default InputPoint;
