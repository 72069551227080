const actions = {
  GET_CRAWL_DATA_COLLECTION_REQUEST: "GET_CRAWL_DATA_COLLECTION_REQUEST",
  GET_CRAWL_DATA_COLLECTION_SUCCESS: "GET_CRAWL_DATA_COLLECTION_SUCCESS",
  GET_CRAWL_DATA_COLLECTION_ERROR: "GET_CRAWL_DATA_COLLECTION_ERROR",

  CRAWL_DATA_COLLECTION_REQUEST: "CRAWL_DATA_COLLECTION_REQUEST",
  CRAWL_DATA_COLLECTION_SUCCESS: "CRAWL_DATA_COLLECTION_SUCCESS",
  CRAWL_DATA_COLLECTION_ERROR: "CRAWL_DATA_COLLECTION_ERROR",

  crawlDataCollection: (payload) => ({
    type: actions.CRAWL_DATA_COLLECTION_REQUEST,
    payload,
  }),

  getCrawlDataCollection: (payload) => ({
    type: actions.GET_CRAWL_DATA_COLLECTION_REQUEST,
    payload,
  }),
};
export default actions;
