import React, {memo, useState, useEffect, useCallback} from "react";
import {Col, Row, Button, Radio, Checkbox, Modal, Empty} from "antd";
import {Select, Form, Input, InputNumber, DatePicker} from "formik-antd";
import { useIntl } from "react-intl";
import { TabStyles } from "./TabStyle";
import {
    colFullApi,
    colInputLeftApi,
    colLeftApi, colSingleUnit, inputColRight,
    itemCheckBoxApi,
    itemLeftLabelApi,
    itemLeftLabelFullApi,
} from "@iso/assets/styles/form.style";
import {
    apiMaxlength,
    fieldKeyJalanApiRoomOption,
    searchSelect,
    daysOfWeek,
    TYPE_ROOM_FORM,
    TYPE_ROOM_CRAWL,
    typeStatusCrawlOption,
    ERROR_STATUS,
    LIST_STATUS_SYNC,
    LIST_STATUS_SALE_PROCESSING,
    LIST_OPEN_SALE_STATUS
} from "@iso/constants/api.constant";
import {DATE_FORMAT, MODE_SCREEN} from "@iso/constants/common.constant";
import {isEmpty} from "lodash";
import {ValidateField} from "@iso/validates/ValidateField";
import UploadFile from "@iso/components/RoomAddAndEditComponent/components/UploadFile";
import roomAction from "@iso/redux/apiRoom/actions";
import {useDispatch} from "react-redux";
import {ReloadOutlined} from "@ant-design/icons";
import {colRight} from "@iso/containers/TaskLog";
import moment from "moment/moment";

const JalanTab = ({
    values,
    dataOta,
    setFieldValue,
    tabKey,
    apiRoomId,
    imageByStoreAndOta,
    apiOptionValues,
    dataRoom,
    mode,
    setFieldTouched,
    setValues
}) => {
    const { messages } = useIntl();
    const dispatch = useDispatch();
    const [optionValues, setOptionValues] = useState([])
    const [specialRoomFeatureOffer, setSpecialRoomFeatureOffer] = useState([])
    const [acceptanceCondition, setAcceptanceCondition] = useState([])
    const [roomType, setRoomType] = useState([])
    const [roomFeature, setRoomFeature] = useState([])
    const [isSyncDataToSite, setIsSyncDataToSite] = useState(false)
    const [disableSyncData, setDisableSyncData] = useState(true)
    const [apiRoomOtaId, setApiRoomOtaId] = useState(null)
    const [loadingRoom, setLoadingRoom] = useState(false)
    const [syncErrorContentFirst, setSyncErrorContentFirst] = useState(null)
    const [showLog, setShowLog] = useState(false);
    const [syncErrorContent, setSyncErrorContent] = useState([])
    const [isUpdateDataFromSite, setIsUpdateDataFromSite] = useState(false)

    useEffect(() => {
        setFieldValue(`apiRoomOtas[${tabKey}]['otaId']`, dataOta.otaId)
        if (!isEmpty(apiOptionValues)) {
            apiOptionValues.map((apiOptionValue) => {
                if (apiOptionValue.otaId == dataOta.otaId) {
                    setOptionValues(apiOptionValue.options)
                }
            })
        }
    }, [apiOptionValues, dataOta]);

    useEffect(() => {
        if (apiRoomId
            && !isEmpty(dataRoom.apiRoomOtas[tabKey])
            && (dataRoom.apiRoomOtas[tabKey].typeRoom == TYPE_ROOM_FORM
                || (dataRoom.apiRoomOtas[tabKey].typeRoom == TYPE_ROOM_CRAWL
                    && dataRoom.apiRoomOtas[tabKey].updateStatus == typeStatusCrawlOption.crawlSuccess
                ))
        ) {
            setDisableSyncData(false)
        } else {
            setDisableSyncData(true)
        }
    }, [dataRoom])

    useEffect(() => {
        if (
            values.apiRoomOtas[tabKey] !== null &&
            values.apiRoomOtas[tabKey] !== undefined &&
            Object.keys(values.apiRoomOtas[tabKey]).length !== 0 &&
            !isEmpty(values.apiRoomOtas[tabKey].fieldContents)
        ) {
            const specialRoomFeatureOffer = values.apiRoomOtas[tabKey]?.fieldContents?.specialRoomFeatureOffer;
            const acceptanceCondition = values.apiRoomOtas[tabKey]?.fieldContents?.acceptanceCondition;
            setSpecialRoomFeatureOffer(Array.isArray(specialRoomFeatureOffer) ? specialRoomFeatureOffer : []);
            setAcceptanceCondition(Array.isArray(acceptanceCondition) ? acceptanceCondition : []);
            setRoomType(values.apiRoomOtas[tabKey]?.fieldContents?.roomType);
            setRoomFeature(values.apiRoomOtas[tabKey]?.fieldContents?.roomFeature);
            setApiRoomOtaId(values.apiRoomOtas[tabKey]?.apiRoomOtaId)
            const syncErrorFirst = getSyncError(values);
            setSyncErrorContentFirst(syncErrorFirst[0])
            const filteredArray = syncErrorFirst.filter(item => item.trim() !== '');
            setSyncErrorContent(filteredArray)
            if (mode == MODE_SCREEN.COPY) {
                setFieldValue(`apiRoomOtas[${tabKey}]['syncStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['updateStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['syncErrorContent']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['siteRoomId']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}]['openSaleStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}][fieldContents]['openSaleOtaStatus']`, null);
                setSyncErrorContentFirst(null);
            }
        } else {
            if (!apiRoomId || Array.isArray(values.otaId) && values.otaId.includes(tabKey)) {
                setFieldValue(`apiRoomOtas[${tabKey}]['siteRoomId']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}].fieldContents`, jalanInitialValue)
                setFieldValue(`apiRoomOtas[${tabKey}]['imageStorage']`, [])
                setFieldValue(`apiRoomOtas[${tabKey}]['imageUrl']`, [])
                setFieldValue(`apiRoomOtas[${tabKey}].syncStatus`, 0)
                setFieldTouched(`apiRoomOtas[${tabKey}].fieldContents`, false);
                setFieldValue(`apiRoomOtas[${tabKey}][fieldContents]['openSaleOtaStatus']`, null);
                setFieldTouched(`apiRoomOtas[${tabKey}].siteRoomId`, false);
                setFieldTouched(`apiRoomOtas[${tabKey}].fieldContents`, false);
            }
        }
    }, [values.apiRoomOtas[tabKey]]);

    const getSyncError = (values) => {
        const syncErrorContent = values.apiRoomOtas[tabKey]?.syncErrorContent || '';
        const syncErrorArray = syncErrorContent.split("\n").map(item => item.trim());
        return syncErrorArray || [];
    }

    const syncDataToSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsSyncDataToSite(true)
            return dispatch(roomAction.syncDataToSite({ apiRoomOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.room.modal.updateSuccessTitle"],
                content: messages[`page.room.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.room.modal.inProgressSyncError`]
                : messages[`page.room.modal.updateError`]
            Modal.error({
                title: messages["page.room.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsSyncDataToSite(false)
        })
    });

    const updateDataFromSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsUpdateDataFromSite(true)
            return dispatch(roomAction.updateDataFromSite({ apiRoomOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.room.modal.updateSuccessTitle"],
                content: messages[`page.room.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.room.modal.inProgressSyncError`]
                : messages[`page.room.modal.updateError`]
            Modal.error({
                title: messages["page.room.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsUpdateDataFromSite(false)
        })
    });

    const handleReloadRoom = () => {
        if (apiRoomId) {
            new Promise((resolve, reject) => {
                setLoadingRoom(true)
                dispatch(roomAction.reloadRoom({ apiRoomId, resolve, reject }))
            }).finally(() => {
                setLoadingRoom(false)
            })
        }
    }

    const onChangeValues = (values, name) => {
        setFieldValue(name, values)
    };

    const onChangeSelectValue = (values, name) => {
        if (values === undefined) {
            values = null;
        }

        setFieldValue(name, values)
    };

    const changeRadioButtonValue = (value, name) => {
        setFieldValue(name, value.target.value);
    };

    const onChangeDate = (name, value) => {
        if (value) {
            setFieldValue(name, moment(value).format(DATE_FORMAT))
            setFieldTouched(name, false);
        } else {
            setFieldValue(name, '')
        }
    }

    const validationRequired = (value, message) => {
        return (value === "" || value === null || value === undefined) ?
            messages["form.error.mixed.required"].replace("${path}", message) : null
    }

    return (
        <TabStyles>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={messages["page.room.site.id"]}
                        name={`apiRoomOtas[${tabKey}]['siteRoomId']`}
                    >
                        <Input
                            disabled={true}
                            name={`apiRoomOtas[${tabKey}]['siteRoomId']`}
                            maxLength={10}
                        />
                    </Form.Item>
                </Col>
                <Col {...colRight} style={{display: 'flex' , justifyContent: 'end'}}>
                    <Button
                        type="primary"
                        className="mr-10 button"
                        block
                        hidden={apiRoomOtaId === null || apiRoomOtaId === undefined || mode == MODE_SCREEN.COPY}
                        loading={isSyncDataToSite}
                        onClick={syncDataToSite}
                        disabled={disableSyncData}
                    >
                        {messages["page.room.sync"]}
                    </Button>
                    <Button
                        type="primary"
                        className="mr-10 button"
                        block
                        hidden={apiRoomOtaId === null || apiRoomOtaId === undefined || mode == MODE_SCREEN.COPY}
                        disabled={
                            (values?.apiRoomOtas[tabKey]?.siteRoomId === null ||
                            values?.apiRoomOtas[tabKey]?.siteRoomId === undefined)
                        }
                        loading={isUpdateDataFromSite}
                        onClick={updateDataFromSite}
                    >
                        {messages["page.room.crawlUpdate"]}
                    </Button>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={messages["page.room.ota.syncStatus"]}
                        name={`apiRoomOtas[${tabKey}]['syncStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['syncStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} className={'position-error-message'}>
                    <Row>
                        <Col xs={24} lg={2}>
                            <Button
                                type="primary"
                                className="mr-2"
                                loading={loadingRoom}
                                icon={<ReloadOutlined />}
                                onClick={() => handleReloadRoom()}>
                            </Button>
                        </Col>
                        <Col xs={24} lg={13}>
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['syncErrorContent']`}
                            >
                                <Input
                                    className="styleInputLog"
                                    onClick={() => setShowLog(true)}
                                    value={syncErrorContentFirst}
                                    name={`apiRoomOtas[${tabKey}]['syncErrorContent']`}
                                    readOnly
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={messages["page.room.ota.updateStatus"]}
                        name={`apiRoomOtas[${tabKey}]['updateStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['updateStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColRight}
                        label={messages["page.room.ota.openSaleStatus"]}
                        name={`apiRoomOtas[${tabKey}]['openSaleStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['openSaleStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SALE_PROCESSING.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColRight}
                        label={messages["page.room.ota.openSaleOtaStatus"]}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['openSaleOtaStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['openSaleOtaStatus']`}
                            disabled={true}
                        >
                            {LIST_OPEN_SALE_STATUS.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['name']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.jalan.roomName"],
                            {
                                required: messages["form.error.mixed.required"],
                                maxlengthFullWidth: messages["form.error.string.max"],
                            },
                            {
                                required : true,
                                maxlengthFullWidth : true,
                            },
                            {},
                            {},
                            {
                                "maxLength" : apiMaxlength.maxThirtyCharacter,
                            }
                        )}
                        label={
                            <div className="d-grid pb-15">
                                <label className="custom-required">{messages["page.room.jalan.roomName"]}</label>
                                <span className="text-note-row">{messages["page.room.jalan.required"]}</span>
                            </div>
                        }
                        labelCol={{ lg: 8, xs: 24  }}
                        wrapperCol={{ lg: 14, xs: 24 }}
                    >
                        <div className="d-flex">
                            <Input
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['name']`}
                            />
                            <div className="roomName-custom text-note-row">{messages["page.room.jalan.fullWidth"]}</div>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['description']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.jalan.roomName"],
                            {
                                maxlengthFullWidth: messages["form.error.string.max"],
                            },
                            {
                                maxlengthFullWidth : true,
                            },
                            {},
                            {},
                            {
                                "maxLength" : apiMaxlength.maxFourHundredCharacter,
                            }
                        )}
                        label={
                            <div className="d-grid pb-15">
                                <label>{messages["page.room.jalan.roomTypeDescription"]}</label>
                                <span className="text-note-row">{messages["page.room.jalan.descriptionNote"]}</span>
                            </div>
                        }
                        {...itemLeftLabelApi}
                    >
                        <Input.TextArea
                            rows="4"
                            name={`apiRoomOtas[${tabKey}]['fieldContents'][description]`}
                            maxLength={400}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['addInformationCustomer']`}
                        label={messages["page.room.jalan.addInformationCustomer"]}
                        {...itemLeftLabelFullApi}
                    >
                        <Row className="mb-10">
                            <span className="label-custom">{messages["page.room.jalan.roomFeature"]}</span>
                        </Row>
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.buildingLocation"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents'][buildingAndLocation]`}
                                >
                                    <Select
                                        name={`apiRoomOtas[${tabKey}]['fieldContents'][buildingAndLocation]`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        allowClear
                                        showSearch
                                        onChange={(value) => onChangeSelectValue( value, `apiRoomOtas[${tabKey}]['fieldContents'][buildingAndLocation]`)}
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiRoomOption.buildingAndLocation) {
                                                return (
                                                    <Select.Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Select.Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.floor"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents'][floor]`}
                                >
                                    <Select
                                        name={`apiRoomOtas[${tabKey}]['fieldContents'][floor]`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        allowClear
                                        showSearch
                                        onChange={(value) => onChangeSelectValue( value, `apiRoomOtas[${tabKey}]['fieldContents'][floor]`)}
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiRoomOption.floor) {
                                                return (
                                                    <Select.Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Select.Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.grade"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents'][grade]`}
                                >
                                    <Select
                                        name={`apiRoomOtas[${tabKey}]['fieldContents'][grade]`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        allowClear
                                        showSearch
                                        onChange={(value) => onChangeSelectValue( value, `apiRoomOtas[${tabKey}]['fieldContents'][grade]`)}
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiRoomOption.grade) {
                                                return (
                                                    <Select.Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Select.Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.overlook"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents'][view]`}
                                >
                                    <Select
                                        name={`apiRoomOtas[${tabKey}]['fieldContents'][view]`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        allowClear
                                        showSearch
                                        onChange={(value) => onChangeSelectValue( value, `apiRoomOtas[${tabKey}]['fieldContents'][view]`)}
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiRoomOption.view) {
                                                return (
                                                    <Select.Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Select.Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.roomAndFacilityDetail"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents'][roomAndFacilityDetail]`}
                                >
                                    <Select
                                        name={`apiRoomOtas[${tabKey}]['fieldContents'][roomAndFacilityDetail]`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        allowClear
                                        showSearch
                                        onChange={(value) => onChangeSelectValue( value, `apiRoomOtas[${tabKey}]['fieldContents'][roomAndFacilityDetail]`)}
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiRoomOption.roomAndFacilityDetail) {
                                                return (
                                                    <Select.Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Select.Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.tobacco"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents'][tobacco]`}
                                >
                                    <Select
                                        name={`apiRoomOtas[${tabKey}]['fieldContents'][tobacco]`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        allowClear
                                        showSearch
                                        onChange={(value) => onChangeSelectValue( value, `apiRoomOtas[${tabKey}]['fieldContents'][tobacco]`)}
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiRoomOption.tobacco) {
                                                return (
                                                    <Select.Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Select.Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.internet"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents'][internet]`}
                                >
                                    <Select
                                        name={`apiRoomOtas[${tabKey}]['fieldContents'][internet]`}
                                        listHeight={320}
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        allowClear
                                        showSearch
                                        onChange={(value) => onChangeSelectValue( value, `apiRoomOtas[${tabKey}]['fieldContents'][internet]`)}
                                        {...searchSelect}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyJalanApiRoomOption.internet) {
                                                return (
                                                    <Select.Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Select.Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.acreage"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents'][acreage]`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.acreage"],
                                        {
                                            number: messages["form.error.number.true"],
                                            valueInvalid: messages["page.room.valueInvalid"]
                                        },
                                        {
                                            number: true,
                                            space: true
                                        }
                                    )}
                                >
                                    <Input
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents'][acreage]`}
                                    />
                                    <span className="label-value-custom">{messages["page.room.jalan.unit"]}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="mb-10">
                            <span className="label-custom">{messages["page.room.jalan.bedding"]}</span>
                        </Row>
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.singleBed"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['singleBed']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.singleBed"],
                                        {
                                            number: messages["form.error.number.true"],
                                            valueInvalid: messages["page.room.valueInvalid"]
                                        },
                                        {
                                            number: true,
                                            space: true
                                        }
                                    )}
                                >
                                    <Input
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['singleBed']`}
                                    />
                                    <span  className="label-value-custom">{messages["page.room.jalan.tower"]}</span>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.SemiDoubleBed"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['semiDoubleBed']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.SemiDoubleBed"],
                                        {
                                            number: messages["form.error.number.true"],
                                            valueInvalid: messages["page.room.valueInvalid"]
                                        },
                                        {
                                            number: true,
                                            space: true
                                        }
                                    )}
                                >
                                    <Input
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['semiDoubleBed']`}
                                    />
                                    <span  className="label-value-custom">{messages["page.room.jalan.tower"]}</span>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.doubleBed"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['doubleBed']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.doubleBed"],
                                        {
                                            number: messages["form.error.number.true"],
                                            valueInvalid: messages["page.room.valueInvalid"]
                                        },
                                        {
                                            number: true,
                                            space: true
                                        }
                                    )}
                                >
                                    <Input
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['doubleBed']`}
                                    />
                                    <span  className="label-value-custom">{messages["page.room.jalan.tower"]}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.queenBed"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['queenBed']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.queenBed"],
                                        {
                                            number: messages["form.error.number.true"],
                                            valueInvalid: messages["page.room.valueInvalid"]
                                        },
                                        {
                                            number: true,
                                            space: true
                                        }
                                    )}
                                >
                                    <Input
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['queenBed']`}
                                    />
                                    <span  className="label-value-custom">{messages["page.room.jalan.tower"]}</span>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.kingBed"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['kingBed']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.kingBed"],
                                        {
                                            number: messages["form.error.number.true"],
                                            valueInvalid: messages["page.room.valueInvalid"]
                                        },
                                        {
                                            number: true,
                                            space: true
                                        }
                                    )}
                                >
                                    <Input
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['kingBed']`}
                                    />
                                    <span  className="label-value-custom">{messages["page.room.jalan.tower"]}</span>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.sofaBed"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['sofaBed']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.sofaBed"],
                                        {
                                            number: messages["form.error.number.true"],
                                            valueInvalid: messages["page.room.valueInvalid"]
                                        },
                                        {
                                            number: true,
                                            space: true
                                        }
                                    )}
                                >
                                    <Input
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['sofaBed']`}
                                    />
                                    <span  className="label-value-custom">{messages["page.room.jalan.tower"]}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={
                                        <div className="d-grid">
                                            <label className="label-grid-custom">{messages["page.room.jalan.bunkBed"]}</label>
                                            <span className="label-grid-custom">{messages["page.room.jalan.bunkBed.note"]}</span>
                                        </div>
                                    }
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['bunkBed']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.bunkBed"],
                                        {
                                            number: messages["form.error.number.true"],
                                            valueInvalid: messages["page.room.valueInvalid"]
                                        },
                                        {
                                            number: true,
                                            space: true
                                        }
                                    )}
                                >
                                    <Input
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['bunkBed']`}
                                    />
                                    <span  className="label-value-custom">{messages["page.room.jalan.tower"]}</span>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={
                                        <div className="d-grid">
                                            <label className="label-grid-custom">{messages["page.room.jalan.extraBed"]}</label>
                                            <span className="label-grid-custom">{messages["page.room.jalan.extraBed.note"]}</span>
                                        </div>
                                    }
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['extraBed']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.extraBed"],
                                        {
                                            number: messages["form.error.number.true"],
                                            valueInvalid: messages["page.room.valueInvalid"]
                                        },
                                        {
                                            number: true,
                                            space: true
                                        }
                                    )}
                                >
                                    <Input
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['extraBed']`}
                                    />
                                    <span  className="label-value-custom">{messages["page.room.jalan.tower"]}</span>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2 mb-25"
                                    label={<div className="label-value-custom">{messages["page.room.jalan.futon"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['futon']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.futon"],
                                        {
                                            number: messages["form.error.number.true"],
                                            valueInvalid: messages["page.room.valueInvalid"]
                                        },
                                        {
                                            number: true,
                                            space: true
                                        }
                                    )}
                                >
                                    <Input
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['futon']`}
                                    />
                                    <span className="label-value-custom">{messages["page.room.jalan.group"]}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="mb-10">
                            <span className="label-custom">{messages["page.room.jalan.freeWord"]}</span>
                            <span className="ml-1 text-note-row">{messages["page.room.jalan.instruct"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10">
                            <Col {...colInputLeftApi}>
                                <Form.Item
                                    name={`apiRoomOtas.${tabKey}.fieldContents.additionFreeWord`}
                                    {...itemCheckBoxApi}
                                >
                                    <Input name={`apiRoomOtas.${tabKey}.fieldContents.additionFreeWord`} maxLength={apiMaxlength.maxFiftyCharacter}/>
                                </Form.Item>
                                <span className="text-note-row">{messages["page.room.jalan.50WordAttention"]}</span>
                            </Col>
                            <Col xs={24} lg={3}>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    className="ml-1"
                                >
                                    {messages["page.room.jalan.completion"]}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-10">
                            <span className="label-custom">{messages["page.room.jalan.roomCharacteristics"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10">
                            <Col {...colFullApi}>
                                <Form.Item
                                    name={`apiRoomOtas.${tabKey}.fieldContents.specialRoomFeatureOffer`}
                                    {...itemCheckBoxApi}
                                >
                                    <Checkbox.Group
                                        name={`apiRoomOtas.${tabKey}.fieldContents.specialRoomFeatureOffer`}
                                        onChange={(values) => onChangeValues(values, `apiRoomOtas.${tabKey}.fieldContents.specialRoomFeatureOffer`)}
                                        value={specialRoomFeatureOffer}
                                        style={{ width: '100%' }}
                                    >
                                        <Row>
                                            {optionValues.map((option, key) => {
                                                if (option.fieldKey === fieldKeyJalanApiRoomOption.specialRoomFeatureOffer) {
                                                    return (
                                                        <Col xs={24} lg={6} key={key} className="mb-5">
                                                            <Checkbox
                                                                key={key}
                                                                value={option.value}
                                                            >
                                                                {option.value}
                                                            </Checkbox>
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="mb-10">
                            <span className="label-custom">{messages["page.room.jalan.freeWord"]}</span>
                            <span className="ml-1 text-note-row">{messages["page.room.jalan.instruct"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10">
                            <Col {...colInputLeftApi}>
                                <Form.Item
                                    name={`apiRoomOtas.${tabKey}.fieldContents.specialFeatureFreeWord`}
                                    {...itemCheckBoxApi}
                                >
                                    <Input name={`apiRoomOtas.${tabKey}.fieldContents.specialFeatureFreeWord`} maxLength={apiMaxlength.maxFiftyCharacter}/>
                                </Form.Item>
                                <span className="text-note-row">{messages["page.room.jalan.50WordAttention"]}</span>
                            </Col>
                            <Col xs={24} lg={3}>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    className="ml-1"
                                >
                                    {messages["page.room.jalan.completion"]}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-10">
                            <span className="label-custom">{messages["page.room.jalan.acceptanceConditions"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10">
                            <Col {...colFullApi}>
                                <Form.Item
                                    name={`apiRoomOtas.${tabKey}.fieldContents.acceptanceCondition`}
                                    {...itemCheckBoxApi}
                                >
                                    <Checkbox.Group
                                        name={`apiRoomOtas.${tabKey}.fieldContents.acceptanceCondition`}
                                        onChange={(values) => onChangeValues(values, `apiRoomOtas.${tabKey}.fieldContents.acceptanceCondition`)}
                                        value={acceptanceCondition}
                                        style={{ width: '100%' }}
                                    >
                                        <Row>
                                            {optionValues.map((option, key) => {
                                                if (option.fieldKey === fieldKeyJalanApiRoomOption.acceptanceCondition) {
                                                    return (
                                                        <Col span={6} key={key} className="mb-5">
                                                            <Checkbox
                                                                key={key}
                                                                value={option.value}
                                                            >
                                                                {option.value}
                                                            </Checkbox>
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="mb-10">
                            <span className="label-custom">{messages["page.room.jalan.freeWord"]}</span>
                            <span className="ml-1 text-note-row">{messages["page.room.jalan.instruct"]}</span>
                        </Row>
                        <Row justify="start">
                            <Col {...colInputLeftApi}>
                                <Form.Item
                                    name={`apiRoomOtas.${tabKey}.fieldContents.acceptanceConditionFreeWord`}
                                    {...itemCheckBoxApi}
                                >
                                    <Input name={`apiRoomOtas.${tabKey}.fieldContents.acceptanceConditionFreeWord`} maxLength={apiMaxlength.maxFiftyCharacter}/>
                                </Form.Item>
                                <span className="text-note-row">{messages["page.room.jalan.50WordAttention"]}</span>
                            </Col>
                            <Col xs={24} lg={3}>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    className="ml-1"
                                >
                                    {messages["page.room.jalan.completion"]}
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`apiRoomOtas.${tabKey}.roomCapacity`}
                        label={
                            <div className="d-grid pb-15">
                                <label className="custom-required">{messages["page.room.jalan.roomCapacity"]}</label>
                                <span className="text-note-row">{messages["page.room.jalan.required"]}</span>
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-10">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2"
                                    name={`apiRoomOtas.${tabKey}.fieldContents.minimumCapacity`}
                                    label={<div className="label-value-custom">{messages["page.room.jalan.minimum"]}</div>}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.minimum"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true
                                        }
                                    )}
                                >
                                    <InputNumber
                                        name={`apiRoomOtas.${tabKey}.fieldContents.minimumCapacity`}
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                    />
                                    <span className="label-value-custom">{messages["page.room.jalan.person"]}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="start">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelJalanRoom}
                                    className="mr-2"
                                    name={`apiRoomOtas.${tabKey}.fieldContents.maximumCapacity`}
                                    label={<div className="label-value-custom">{messages["page.room.jalan.maximum"]}</div>}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.jalan.maximum"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true
                                        }
                                    )}
                                >
                                    <InputNumber
                                        name={`apiRoomOtas.${tabKey}.fieldContents.maximumCapacity`}
                                        style={styleInputCommon}
                                        className="custom-max-width"
                                    />
                                    <span className="label-value-custom">{messages["page.room.jalan.person"]}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`apiRoomOtas.${tabKey}.fieldContents.roomType`}
                        label={
                            <div className="d-grid pb-15">
                                <label className="custom-required">{messages["page.room.jalan.roomType"]}</label>
                                <span className="text-note-row">{messages["page.room.jalan.required"]}</span>
                            </div>
                        }
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.jalan.roomType"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                        {...itemLeftLabelFullApi}
                    >
                        <Row className="mb-10">
                            <span className="label-custom">{messages["page.room.jalan.westernStyleRoom"]}</span>
                        </Row>
                        <Row justify="start" className="mb-10">
                            <Col {...colFullApi}>
                                <Radio.Group
                                    onChange={(values) => changeRadioButtonValue(values, `apiRoomOtas[${tabKey}]['fieldContents']['roomType']`)}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['roomType']`}
                                    value={roomType}
                                    style={{ width: '100%' }}
                                >
                                    <Row>
                                        {optionValues.map((option, key) => {
                                            if (option.fieldKey === fieldKeyJalanApiRoomOption.roomTypeWestern) {
                                                return (
                                                    <Radio
                                                        key={key}
                                                        value={option.value}
                                                        className="mr-1"
                                                    >
                                                        {option.value}
                                                    </Radio>
                                                )
                                            }
                                        })}
                                    </Row>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row className="mb-10">
                            <span className="label-custom">{messages["page.room.jalan.japaneseStyleRoom"]}</span>
                        </Row>
                        <Row justify="start">
                            <Col {...colFullApi}>
                                <Radio.Group
                                    onChange={(values) => changeRadioButtonValue(values, `apiRoomOtas[${tabKey}]['fieldContents']['roomType']`)}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['roomType']`}
                                    value={roomType}
                                    style={{width: '100%'}}
                                >
                                    <Row>
                                        {optionValues.map((option, key) => {
                                            if (option.fieldKey === fieldKeyJalanApiRoomOption.roomTypeJapanese) {
                                                return (
                                                    <Radio
                                                        key={key}
                                                        value={option.value}
                                                        className="mr-1"
                                                    >
                                                        {option.value}
                                                    </Radio>
                                                )
                                            }
                                        })}
                                    </Row>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`apiRoomOtas.${tabKey}.fieldContents.roomFeature`}
                        label={messages["page.room.jalan.roomFeatures"]}
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start">
                            <Col {...colFullApi}>
                                <Form.Item
                                    name={`apiRoomOtas.${tabKey}.fieldContents.roomFeature`}
                                    {...itemCheckBoxApi}
                                >
                                    <Checkbox.Group
                                        name={`apiRoomOtas.${tabKey}.fieldContents.roomFeature`}
                                        onChange={(values) => onChangeValues(values, `apiRoomOtas.${tabKey}.fieldContents.roomFeature`)}
                                        value={roomFeature}
                                        style={{ width: '100%' }}
                                    >
                                        <Row>
                                            {optionValues.map((option, key) => {
                                                if (option.fieldKey === fieldKeyJalanApiRoomOption.roomFeature) {
                                                    return (
                                                        <Col span={6} key={key} className="mb-5">
                                                            <Checkbox
                                                                key={key}
                                                                value={option.value}
                                                            >
                                                                {option.value}
                                                            </Checkbox>
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`apiRoomOtas.${tabKey}.fieldContents.settingBasicRoomProvided`}
                        label={
                            <div className="d-grid pb-15">
                                <label className="custom-required">{messages["page.room.jalan.settingBasicRoomProvided"]}</label>
                                <span className="text-note-row">{messages["page.room.jalan.required"]}</span>
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <span className="label-custom">{messages["page.room.jalan.setPeriod"]}</span>
                        </Row>
                        <Row className="mb-25">
                            <Form.Item
                                name={`apiRoomOtas.${tabKey}.fieldContents.settingPeriodFrom`}
                                validate={(value) => validationRequired(value, messages["page.room.jalan.settingBasicRoomProvided"])}
                            >
                                <DatePicker
                                    format={DATE_FORMAT}
                                    name={`apiRoomOtas.${tabKey}.fieldContents.settingPeriodFrom`}
                                    onChange={(date) => onChangeDate(`apiRoomOtas.${tabKey}.fieldContents.settingPeriodFrom`, date)}
                                />
                            </Form.Item>
                            <p className="m-2 d-flex mt-7">{messages["page.apiPlanAddAndEdit.Rakuten.specialChar"]}</p>
                            <Form.Item
                                name={`apiRoomOtas.${tabKey}.fieldContents.settingPeriodTo`}
                                validate={(value) => validationRequired(value, messages["page.room.jalan.settingBasicRoomProvided"])}
                            >
                                <DatePicker
                                    format={DATE_FORMAT}
                                    name={`apiRoomOtas.${tabKey}.fieldContents.settingPeriodTo`}
                                    onChange={(date) => onChangeDate(`apiRoomOtas.${tabKey}.fieldContents.settingPeriodTo`, date)}
                                />
                            </Form.Item>
                        </Row>
                        <Row gutter={8}>
                            {daysOfWeek.map((day, index) => (
                                <Col key={index}  xs={{span: 3}} md={{span: 3}} sm={{span: 3}} lg={{span: 2}} xl={{span: 1}} xxl={{span: 1}} className="day-col">
                                    <div className={`day-header day-${index}`}>
                                        {day.value}
                                    </div>
                                    <Form.Item
                                        name={`apiRoomOtas.${tabKey}.fieldContents.${day.key}`}
                                        validate={(value) => (values?.apiRoomOtas[tabKey]?.siteRoomId !== null &&
                                            values?.apiRoomOtas[tabKey]?.siteRoomId !== undefined)
                                            ? false
                                            : validationRequired(value, messages["page.room.jalan.settingBasicRoomProvided"])}
                                        className="day-form-item"
                                    >
                                        <Input
                                            disabled={
                                                (values?.apiRoomOtas[tabKey]?.siteRoomId !== null &&
                                                    values?.apiRoomOtas[tabKey]?.siteRoomId !== undefined)
                                            }
                                            className="day-input"
                                            name={`apiRoomOtas.${tabKey}.fieldContents.${day.key}`}
                                        />
                                    </Form.Item>
                                </Col>
                            ))}
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <UploadFile
                values={values}
                dataOta={dataOta}
                setFieldValue={setFieldValue}
                tabKey={tabKey}
                apiRoomId={apiRoomId}
                imageByStoreAndOta={imageByStoreAndOta}
                dataRoom={dataRoom}
                isHiddenUrl={true}
                titleCustom={messages["page.room.jalan.ota.image"]}
            />
            <Modal
                title={messages["page.crawlRoom.titleLog"]}
                style={{ top: 20 }}
                width={700}
                visible={showLog}
                footer={null}
                onCancel={() => setShowLog(false)}
            >
                <Row style={{maxHeight: '80vh', overflowY: 'auto', display: 'block'}}>
                    {syncErrorContent != null && syncErrorContent.length > 0 && syncErrorContent.map((x, index) => (
                        <Row style={{marginTop: '10px' }}>
                            <p key={index}>{x}</p>
                        </Row>
                    ))}
                </Row>
                {syncErrorContent == null || syncErrorContent === '' || syncErrorContent.length <= 0 && (
                    <Row  style={{ padding: '10px', justifyContent: 'center' }}>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>{messages["antTable.emptyData"]}</span>
                            }
                        >
                        </Empty>
                    </Row>
                )}
            </Modal>
        </TabStyles>
    )
}

const styleInputCommon = {
    minWidth: 168,
    marginRight: 15
}

const colInputWithLabelJalanRoom = {
    labelCol: { xs: 24, sm: 24, md: 24, lg: 10, xl: 9, },
    wrapperCol: { xs: 24, sm: 24, md: 24, lg: 14, xl: 15, },
}

const colInputApi = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 12, offset: 0 },
    xxl: { span: 8, offset: 0 },
}

const jalanInitialValue = {
    name: null,
    description: null,
    buildingAndLocation: null,
    floor: null,
    grade: null,
    view: null,
    roomAndFacilityDetail: null,
    tobacco: null,
    internet: null,
    acreage: null,
    singleBed: null,
    queenBed: null,
    bunkBed: null,
    semiDoubleBed: null,
    kingBed: null,
    extraBed: null,
    doubleBed: null,
    sofaBed: null,
    futon: null,
    additionFreeWord: null,
    specialRoomFeatureOffer: [],
    specialFeatureFreeWord: null,
    acceptanceCondition: [],
    acceptanceConditionFreeWord: null,
    minimumCapacity: null,
    maximumCapacity: null,
    roomType: null,
    roomFeature: null,
    settingPeriodFrom: null,
    settingPeriodTo: null,
    roomAvailableOnSunday: null,
    roomAvailableOnMonday: null,
    roomAvailableOnTuesday: null,
    roomAvailableOnWednesday: null,
    roomAvailableOnThursday: null,
    roomAvailableOnFriday: null,
    roomAvailableOnSaturday: null,
}

export default memo(JalanTab);
