import styled from "styled-components";

const LayoutContentWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 0px;
  }
  
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }

  .text-note-row {
    color: #f22929;
    font-size: 14px;
  }

  .space-btn {
    width: 100%;
  }

  .ant-table-tbody .ant-table-cell-with-append {
    > a {
      float: left;
    }
  }

  .ant-table-row-expand-icon-spaced {
    pointer-events: none;
  }

  .button {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 25px;
  }
  @media only screen and (min-width: 992px) {
    .button {
      width: 100px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .button {
      width: 100px;
    }
  }

  @media only screen and (min-width: 1600px) {
    .button {
      width: 120px;
    }
  }
  
  .mt-5px {
    margin-top: 5px;
  }

  .mt-15px {
    margin-top: 15px;
  }
  
  .mb-2 {
    margin-bottom: 2em;
  }

  .mb-5 {
    margin-bottom: 5em;
  }
  
  .mb-10px {
    margin-bottom: 10px;
  }

  .mx-10px {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .mb-8px {
    margin-bottom: 8px;
  }
  
  .mb-15px {
    margin-bottom: 15px;
  }

  .mb-25px {
    margin-bottom: 25px;
  }
  
  .mw-50 {
    min-width: 50px;
  }
  
  .mw-100 {
    min-width: 100px;
  }
  
  .mw-150 {
    min-width: 150px;
  }

  .mw-200 {
    min-width: 200px;
  }
  
  .min-width-60px {
    min-width: 60px;
  }
  
  .min-width-30px {
    min-width: 30px;
  }

  .gap-10px {
    gap: 10px;
  }

  .text-inline {
    white-space: nowrap;
  }
  
  .align-item-baseline {
    align-items: baseline;
  }
  
  .ant-form-item-label > label::after {
    content: ' ';
  }
  
  .row-reverse {
    flex-direction: row-reverse;
  }
  
  .ant-picker-separator {
      width: 2em
  }
  
  .rangeDatePicker .ant-picker-separator span {
      display: flex;
      align-items: center;
   }
    
    .rangeDatePicker .ant-picker-separator span:before {
      display: inline-block;
      content: "~";
      margin-top: 7px;
      width: 10px;
    }
    .rangeDatePicker .ant-picker-separator svg {display: none;}
    
  .totalResult {
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.85);
  }
  
  .btn-not-close {
    margin-top: 5px; 
    border: none;
    background-color: white;
    cursor: pointer;
    color: #40a9ff;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-offset: 2px;
  }
  
  .reverse-required label {
    flex-direction: row-reverse;
  }
  
  .reverse-required label::after {
    margin: 0px;
  }

  .btn-not-close:hover {
    color: #40a9ff;
  }
  .custom-collapse .ant-collapse-header {
    font-size: 16px;
    font-weight: bold;
  }
  
  .d-flex {
    display: flex;
  }

  .d-grid {
    display: grid;
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }

  .align-items-center {
    align-items: center;
  }

  .flex-column {
    flex-direction: column;
  }
  
  .rangeDatePicker .ant-picker-separator span {
      display: flex;
      align-items: center;
   }
    
    .rangeDatePicker .ant-picker-separator span:before {
      display: inline-block;
      content: "~";
      margin-top: 7px;
      width: 10px;
    }
    .rangeDatePicker .ant-picker-separator svg {display: none;}
    .mr-1 {
      margin-right: 1em;
    }
  
  .m-2 {
    margin-left: 2em;
    margin-right: 2em;
  }
  
  .ml-1 {
    margin-left: 1rem;
  }

  .ml-2 {
    margin-left: 2rem;
  }

  .ml-3 {
    margin-left: 3rem;
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .ml-1 {
    margin-left: 1rem;
  }
  .mr-1 {
    margin-right: 1rem;
  }
  
  .mb-0 {
    margin-bottom: 0px;
  }

  .pb-25px {
    padding-bottom: 25px;
  }

  .styleInputLog {
    background: #f5f5f5;
    cursor: pointer;
  }
  .position-error-message {
    right: 100px;
  }
  @media only screen and (max-width: 1550px) {
      .position-error-message {
        right: 0px;
      }
  }

  .ant-col .required .ant-form-item-label .ant-form-item-no-colon:after {
    color: #f55753;
    content: "*";
    font-family: arial;
    font-size: 20px;
    position: relative;
    z-index: 999;
  }
  
  .ant-col .ant-form-item-label .ant-form-item-no-colon .custom-required:after {
    color: #f55753;
    content: "*";
    font-family: arial;
    font-size: 20px;
    position: relative;
    z-index: 999;
  }

  .custom-label .ant-form-item-label label{
    padding-bottom: 35px;
  }

  .custom-input {
    position: relative;
    bottom: 5px;
  }

  .ant-form-item-has-error .ant-radio-inner {
    border-color: #ff4d4f !important;
  }

  .pointer-none {
    pointer-events: none;
  }

  .content-middle {
    text-align: center;
    align-content: center;
  }

  .max-h-34px {
    max-height: 34px;
  }

  .no-required .ant-form-item-no-colon:after {
    content: "" !important;
  }

  .kodomo-jalan .ant-form-item-label {
    text-align: left !important;
  }

  .kodomo-jalan-table {
    vertical-align: middle;
    text-align: center;
  }

  .kodomo-jalan-table, .kodomo-jalan-table th, .kodomo-jalan-table td {
    border: 1px solid #e1dede;
    border-collapse: collapse;
  }

  .kodomo-ikkyu-table {
    vertical-align: middle;
    text-align: center;
  }

  .kodomo-ikkyu-table, .kodomo-ikkyu-table th, .kodomo-ikkyu-table td {
    border: 1px solid #e1dede;
    border-collapse: collapse;
  }

  .kodomo-rurubu .ant-form-item-label {
    text-align: left !important;
  }

  .kodomo-rurubu-table {
    width: 100%;
    vertical-align: middle;
    text-align: center;
  }

  .kodomo-rurubu-table, .kodomo-rurubu-table th, .kodomo-rurubu-table td {
    border: 1px solid #e1dede;
    border-collapse: collapse;
  }

  .p-5px {
    padding: 5px;
  }

  .custom-height .ant-form-item-control .ant-form-item-control-input {
    height: 30px;
  }

  .kodomo-required table {
    border: 1px solid red;
  }

  .ant-form-item-no-colon {
    white-space: normal;
  }

  .heading-inline table thead tr th {
    white-space: nowrap;
  }

  .table-setting-person {
    th,tr,td{
      border: 1px solid #a4a2a2;
      text-align: center;
    }
  }

  .table-setting-person tbody th{
    background: #D0E0E3;
  }

  .table-setting-person thead th{
    padding: 5px 30px;
    background: #D0E0E3;
  }

  .table-setting-person tbody th, td{
    padding: 20px;
  }

  .heading {
    font-size: 20px;
  }
`;

export { LayoutContentWrapper };
