import actions from "@iso/redux/apiCrawlOption/actions";

const initialState = {
  listMeals: [],
  statusMeal: null,
  loadingDataMeal: true,
  loadingCrawlMeal: false,
  listFeatures: [],
  statusFeature: null,
  loadingDataFeature: true,
  loadingCrawlFeature: false,
  listOptionals: [],
  statusOptional: null,
  loadingDataOptional: true,
  loadingCrawlOptional: false,
  listPointSetting: [],
  statusPointSetting: null,
  loadingDataPointSetting: true,
  loadingCrawlPointSetting: false,
};

export default function ApiCrawlOptionReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_LIST_MEAL_REQUEST:
      return {
        ...state,
        listMeals: [],
        loadingDataMeal: true,
      };
    case actions.GET_LIST_MEAL_SUCCESS:
      return {
        ...state,
        listMeals: action.listMeals,
        statusMeal: action.statusMeal,
        loadingDataMeal: false,
      };
    case actions.GET_LIST_MEAL_ERROR:
      return {
        ...state,
        listMeals: [],
        loadingDataMeal: false,
        error: action.error,
      };
    case actions.GET_LIST_FEATURE_REQUEST:
      return {
        ...state,
        listFeatures: [],
        loadingDataFeature: true,
      };
    case actions.GET_LIST_FEATURE_SUCCESS:
      return {
        ...state,
        listFeatures: action.listFeatures,
        statusFeature: action.statusFeature,
        loadingDataFeature: false,
      };
    case actions.GET_LIST_FEATURE_ERROR:
      return {
        ...state,
        listFeatures: [],
        loadingDataFeature: false,
        error: action.error,
      };
    case actions.GET_LIST_OPTIONAL_REQUEST:
      return {
        ...state,
        listOptionals: [],
        loadingDataOptional: true,
      };
    case actions.GET_LIST_OPTIONAL_SUCCESS:
      return {
        ...state,
        listOptionals: action.listOptionals,
        statusOptional: action.statusOptional,
        loadingDataOptional: false,
      };
    case actions.GET_LIST_OPTIONAL_ERROR:
      return {
        ...state,
        listOptionals: [],
        loadingDataOptional: false,
        error: action.error,
      };
    case actions.CRAWL_DATA_MEAL_REQUEST:
      return {
        ...state,
        statusMeal: 3,
        loadingCrawlMeal: true
      };
    case actions.CRAWL_DATA_MEAL_SUCCESS:
      return {
        ...state,
        loadingCrawlMeal: false
      };
    case actions.CRAWL_DATA_MEAL_ERROR:
      return {
        ...state,
        loadingCrawlMeal: false
      };
    case actions.CRAWL_DATA_FEATURE_REQUEST:
      return {
        ...state,
        statusFeature: 3,
        loadingCrawlFeature: true
      };
    case actions.CRAWL_DATA_FEATURE_SUCCESS:
      return {
        ...state,
        loadingCrawlFeature: false
      };
    case actions.CRAWL_DATA_FEATURE_ERROR:
      return {
        ...state,
        loadingCrawlFeature: false
      };
    case actions.CRAWL_DATA_OPTIONAL_REQUEST:
      return {
        ...state,
        statusOptional: 3,
        loadingCrawlOptional: true
      };
    case actions.CRAWL_DATA_OPTIONAL_SUCCESS:
      return {
        ...state,
        loadingCrawlOptional: false
      };
    case actions.CRAWL_DATA_OPTIONAL_ERROR:
      return {
        ...state,
        loadingCrawlOptional: false
      };

    case actions.GET_LIST_POINT_SETTING_REQUEST:
      return {
        ...state,
        listPointSetting: [],
        loadingDataPointSetting: true,
      };
    case actions.GET_LIST_POINT_SETTING_SUCCESS:
      return {
        ...state,
        listPointSetting: action.listPointSetting,
        statusPointSetting: action.statusPointSetting,
        loadingDataPointSetting: false,
      };
    case actions.GET_LIST_POINT_SETTING_ERROR:
      return {
        ...state,
        listPointSetting: [],
        loadingDataPointSetting: false,
        error: action.error,
      };

    case actions.CRAWL_DATA_POINT_SETTING_REQUEST:
      return {
        ...state,
        statusPointSetting: 3,
        loadingCrawlPointSetting: true
      };
    case actions.CRAWL_DATA_POINT_SETTING_SUCCESS:
      return {
        ...state,
        loadingCrawlPointSetting: false
      };
    case actions.CRAWL_DATA_POINT_SETTING_ERROR:
      return {
        ...state,
        loadingCrawlPointSetting: false
      };
    default:
      return state;
  }
}
