import React, { useState, useEffect, memo, useCallback } from "react";
import { useIntl } from "react-intl";
import { Col, Row, Button, Checkbox, Radio, Modal, Empty, message, Divider, Descriptions } from "antd";
import { Form, Input, Select, DatePicker, InputNumber } from "formik-antd";
import { LayoutContentWrapper } from "./PlanOta.styles";
import PropTypes from "prop-types";
import moment from "moment";
import {
    BREAKFAST,
    DINNER,
    ERROR_STATUS,
    fieldKeyRurubuApiPlanOption,
    JAPANICAN,
    LIST_CODE_STATUS_COMMON_MESSAGE,
    LIST_CODE_STATUS_SHOW_MESSAGE,
    LIST_FIELD_KEY_CRAWL_OPTION_RURUBU,
    LIST_OPEN_SALE_STATUS,
    LIST_STATUS_CRAWL,
    LIST_STATUS_SALE_PROCESSING,
    LIST_STATUS_SYNC,
    PACKAGED_GOODS,
    PRICE_LINK_SETTING,
    RURUBU_TRAVEL,
    SELECT_LINK_PLAN,
    TYPE_ACTION_CRAWL_RURUBU,
} from "@iso/constants/api.constant";
import {
    colFullApi,
    colLeft,
    itemCheckBoxApi,
    itemLeftLabel,
    itemLeftLabelFullApi
} from "@iso/assets/styles/form.style";
import { colRight } from "@iso/containers/TaskLog";
import { useDispatch, useSelector } from "react-redux";
import { ValidateField } from "@iso/validates/ValidateField";
import { DATE_FORMAT, searchSelect } from "@iso/constants/common.constant";
import { isEmpty } from "lodash";
import { ReloadOutlined } from "@ant-design/icons";
import apiPlanAction from "@iso/redux/apiPlan/actions";
import apiCancelPolicyAction from "@iso/redux/apiCancelPolicy/actions";
import apiCrawlOptionAction from "@iso/redux/apiCrawlOption/actions";
import RoomRurubuExtendFields from "@iso/containers/ApiPlanAddAndEdit/Components/Rurubu/RoomRurubuExtendFields";
import TooltipCustom from "../common/TooltipCustom";

const Rurubu = ({
    tabKey,
    setFieldValue,
    setFieldTouched,
    apiPlanId,
    values,
    otaId,
    storeId,
    apiOptionValue,
    isCloneData,
    dataPlanOta,
    setEventKodomoSubmit,
    activeTabKey
}) => {
    const { messages } = useIntl();
    const { Option } = Select;
    const dispatch = useDispatch();

    const {
        ApiPlan: { loadingSyncStatus, syncPlanStatus, planLinkPrices },
        ApiCancelPolicy: { listCancelPolicy, statusCrawlCancelPolicy },
        ApiCrawlOption: {
            listPointSetting,
            statusPointSetting
        },
    } = useSelector((state) => state);

    const [optionValues, setOptionValues] = useState([])
    const [apiPlanOtaId, setApiPlanOtaId] = useState(null);
    const [syncErrorContent, setSyncErrorContent] = useState([]);
    const [showLog, setShowLog] = useState(false);
    const [isUpdateDataFromSite, setIsUpdateDataFromSite] = useState(false);
    const [isDisableButtonSync, setIsDisableButtonSync] = useState(false);
    const [isSyncDataToSite, setIsSyncDataToSite] = useState(false);

    const [inventoryTypeValue, setInventoryTypeValue] = useState(rurubuInitialValue.inventoryType);
    const [pricesFromValue, setPricesFromValue] = useState(rurubuInitialValue.pricesFrom);
    const [searchKeyValue, setSearchKeyValue] = useState(rurubuInitialValue.searchKey);
    const [extendSellDateForeverValue, setExtendSellDateForeverValue] = useState(rurubuInitialValue.extendSellDateForever);
    const [extendStayDateForeverValue, setExtendStayDateForeverValue] = useState(rurubuInitialValue.extendStayDateForever);
    const [dietaryRequirementsValue, setDietaryRequirementsValue] = useState(rurubuInitialValue.dietaryRequirements);
    const [paymentChannelValue, setPaymentChannelValue] = useState(rurubuInitialValue.paymentChannel);
    const [specialRequestListenValue, setSpecialRequestListenValue] = useState(rurubuInitialValue.specialRequestListen);
    const [acceptGenderSettingValue, setAcceptGenderSettingValue] = useState(rurubuInitialValue.acceptGenderSetting);
    const [mealBreakfastValue, setMealBreakfastValue] = useState(rurubuInitialValue.mealBreakfast);
    const [mealDinnerValue, setMealDinnerValue] = useState(rurubuInitialValue.mealDinner);
    const [benefitFoodDrinkValue, setBenefitFoodDrinkValue] = useState(rurubuInitialValue.benefitFoodDrink);
    const [servicesBenefitsValue, setServicesBenefitsValue] = useState(rurubuInitialValue.servicesBenefits);
    const [transportBenefitsValue, setTransportBenefitsValue] = useState(rurubuInitialValue.transportBenefits);
    const [activityBenefitsValue, setActivityBenefitsValue] = useState(rurubuInitialValue.activityBenefits);
    const [voucherBenefitsValue, setVoucherBenefitsValue] = useState(rurubuInitialValue.voucherBenefits);
    const [rateCategoryUsePriceIncreaseValue, setRateCategoryUsePriceIncreaseValue] = useState(rurubuInitialValue.rateCategoryUsePriceIncrease);
    const [isDisableEditMode, setIsDisableEditMode] = useState(false);
    const [minDaysInAdvanceValue, setMinDaysInAdvanceValue] = useState(rurubuInitialValue.minDaysInAdvance);

    const [listPlanLinkPrices, setListPlanLinkPrices] = useState([]);

    useEffect(() => {
        if (!isEmpty(apiOptionValue)) {
            setOptionValues(apiOptionValue)
        }
    }, [apiOptionValue]);

    useEffect(() => {
        if (dataPlanOta &&
            dataPlanOta.otaFieldContent !== null && dataPlanOta.otaFieldContent !== undefined &&
            dataPlanOta.otaFieldContent[tabKey] !== null && dataPlanOta.otaFieldContent[tabKey] !== undefined &&
            Object.keys(dataPlanOta.otaFieldContent[tabKey]).length > 0
        ) {
            const rurubuDataOta = dataPlanOta?.otaFieldContent[tabKey];
            setInventoryTypeValue(rurubuDataOta.inventoryType);
            setPricesFromValue(rurubuDataOta.pricesFrom);
            setSearchKeyValue(rurubuDataOta.searchKey);
            setExtendSellDateForeverValue(rurubuDataOta.extendSellDateForever);
            setExtendStayDateForeverValue(rurubuDataOta.extendStayDateForever);
            setDietaryRequirementsValue(rurubuDataOta.dietaryRequirements);
            setPaymentChannelValue(rurubuDataOta.paymentChannel);
            setSpecialRequestListenValue(rurubuDataOta.specialRequestListen);
            setAcceptGenderSettingValue(rurubuDataOta.acceptGenderSetting);
            setMealBreakfastValue(rurubuDataOta.mealBreakfast);
            setMealDinnerValue(rurubuDataOta.mealDinner);
            setBenefitFoodDrinkValue(rurubuDataOta.benefitFoodDrink);
            setServicesBenefitsValue(rurubuDataOta.servicesBenefits);
            setTransportBenefitsValue(rurubuDataOta.transportBenefits);
            setActivityBenefitsValue(rurubuDataOta.activityBenefits);
            setVoucherBenefitsValue(rurubuDataOta.voucherBenefits);
            setRateCategoryUsePriceIncreaseValue(rurubuDataOta.rateCategoryUsePriceIncrease);
            setMinDaysInAdvanceValue(rurubuDataOta.minDaysInAdvance);

            setIsDisableEditMode(false)
            if (rurubuDataOta.sitePlanId) {
                setIsDisableEditMode(true)
            }

            if (
                planLinkPrices
                && planLinkPrices != null
                && planLinkPrices != undefined
            ) {
                const dataLinkPrices = planLinkPrices.filter(plan => plan.sitePlanId !== rurubuDataOta.sitePlanId);
                setListPlanLinkPrices(dataLinkPrices);
            }

        } else {
            setListPlanLinkPrices(planLinkPrices);
        }
    }, [dataPlanOta, planLinkPrices]);

    useEffect(() => {
        if (!values.otaFieldContent[tabKey] || Object.keys(values.otaFieldContent[tabKey]).length === 0) {
            rurubuInitialValue.otaId = otaId;
            values.otaFieldContent[tabKey] = rurubuInitialValue;
            if (!apiPlanId) {
                setFieldValue(`otaFieldContent.${tabKey}`, rurubuInitialValue);
            }
        } else {
            const rurubuDataOta = values.otaFieldContent[tabKey];
            const parentFieldKey = `otaFieldContent.${tabKey}`;

            setApiPlanOtaId(rurubuDataOta.apiPlanOtaId)
        }
    }, [values])


    const handleChangeCheckbox = (nameField, valueField, field = null) => {
        setFieldValue(nameField, valueField)
    }

    const handleChangeRadio = (e, field = null, key = null) => {
        const nameField = e.target.name;
        const valueField = e.target.value;
        const parentFieldKey = `otaFieldContent.${tabKey}`;
        setFieldValue(nameField, valueField);

        if ((field == fieldKeyRurubuApiPlanOption.inventoryType)) {
            setFieldValue(`${parentFieldKey}.pointSettings`, null);
            setFieldValue(`${parentFieldKey}.paymentChannel`, null);
            if (valueField == JAPANICAN) {
                setFieldValue(`${parentFieldKey}.ratePlanDescription`, null);
                setFieldValue(`${parentFieldKey}.noticeInformation`, null);
                setFieldValue(`${parentFieldKey}.paymentInformation`, null);
                setFieldValue(`${parentFieldKey}.specialRequestListen`, null);
                setFieldValue(`${parentFieldKey}.questionToCustomer`, null);
            }

        }
    }

    const handleChangeSelect = (field, valueField) => {
        const parentFieldKey = `otaFieldContent.${tabKey}`;
        if ((field == 'pricesFrom')) {
            setFieldValue(`${parentFieldKey}.rateCategoryUsePriceIncrease`, null);
            setFieldValue(`${parentFieldKey}.usePriceValue`, null);
        }
    }

    const onChangeDate = (name, value) => {
        if (value) {
            setFieldValue(name, moment(value).format(DATE_FORMAT))
            setFieldTouched(name, false);
        } else {
            setFieldValue(name, '')
        }
    }

    const handleInputBlur = (event) => {
        const nameField = event.target.name;
        const valueField = event.target.value;
        const trimmedValue = trimSpaces(valueField);

        setFieldValue(nameField, null)
        if (trimmedValue !== "" || trimmedValue == undefined) {
            setFieldValue(nameField, trimmedValue)
        }
    };

    useEffect(() => {
        refreshSyncPlan();
    }, [dispatch])

    const trimSpaces = (inputString) => {
        if (typeof inputString !== 'string') {
            return '';
        }
        let elements = inputString.split(',');
        let trimmedElements = elements.map(element => element.trim());
        return trimmedElements.join(',');
    }

    useEffect(() => {
        if (syncPlanStatus !== null && syncPlanStatus !== undefined && !isCloneData && apiPlanOtaId) {
            for (let i = 0; i < syncPlanStatus.length; i++) {
                if (syncPlanStatus[i].otaId === tabKey) {
                    if (LIST_CODE_STATUS_SHOW_MESSAGE.includes(syncPlanStatus[i].codeStatus)) {
                        let syncErrorContentData = syncPlanStatus[i].syncErrorContent ?? '';
                        syncErrorContentData = syncPlanStatus[i]?.codeStatus === ERROR_STATUS.LOGIN_ERROR ? messages['page.apiPlanAddAndEdit.loginFail'] : syncErrorContentData;
                        if (syncErrorContentData !== '' && syncErrorContentData !== null && syncErrorContentData !== undefined) {
                            let syncErrorContentArray = syncErrorContentData.trim().split("\n").map(item => item.trim());
                            setSyncErrorContent(syncErrorContentArray);
                        } else {
                            setSyncErrorContent([])
                        }
                    } else if (LIST_CODE_STATUS_COMMON_MESSAGE.includes(syncPlanStatus[i].codeStatus)) {
                        setSyncErrorContent([messages['page.apiPlanAddAndEdit.somethingWrong']])
                    } else {
                        setSyncErrorContent([])
                    }
                    setFieldValue(`otaFieldContent.${tabKey}.syncStatus`, syncPlanStatus[i].syncStatus);
                    setFieldValue(`otaFieldContent.${tabKey}.updateStatus`, syncPlanStatus[i].updateStatus);
                    setFieldValue(`otaFieldContent.${tabKey}.openSaleStatus`, syncPlanStatus[i].openSaleStatus);
                }
            }
        }
    }, [syncPlanStatus])

    const refreshSyncPlan = () => {
        if (apiPlanId === null || apiPlanId === undefined) {
            return;
        }
        dispatch(apiPlanAction.getSyncPlanStatus({ apiPlanId }))
    }

    useEffect(() => {
        if (storeId !== null && storeId !== undefined && otaId !== null && otaId !== undefined) {
            dispatch(apiCancelPolicyAction.getListCancelPolicy({ storeId, otaId }));

            LIST_FIELD_KEY_CRAWL_OPTION_RURUBU.map((fieldKey) => {
                switch (fieldKey) {
                    case TYPE_ACTION_CRAWL_RURUBU.point_settings:
                        dispatch(apiCrawlOptionAction.getListPointSetting({ storeId, otaId, fieldKey }));
                        break;
                    default:
                        break;
                }

            })

            dispatch(apiPlanAction.getApiPlanLinkPrices({ storeId, otaId }));
        }
    }, [storeId, otaId])

    const crawlOption = (fieldKey) => {
        if (otaId === null || otaId === undefined) {
            Modal.error({
                title: messages["page.apiPlanAddAndEdit.modal.errorTitle"],
                content: messages["form.error.mixed.required"].replace(
                    "${path}",
                    messages["page.apiPlanAddAndEdit.otas"]
                ),
            });
            return;
        }
        if (storeId === null || storeId === undefined) {
            Modal.error({
                title: messages["page.apiPlanAddAndEdit.modal.errorTitle"],
                content: messages["form.error.mixed.required"].replace(
                    "${path}",
                    messages["page.apiPlanAddAndEdit.storeId"]
                ),
            });
            return;
        }

        let action;
        let successMessage;
        let failureMessage;
        switch (fieldKey) {
            case TYPE_ACTION_CRAWL_RURUBU.cancelPolicy:
                action = apiCancelPolicyAction.crawlDataCancelPolicy;
                successMessage = messages["page.apiPlanAddAndEdit.crawlCancelPolicy.success"];
                failureMessage = messages[`page.apiPlanAddAndEdit.crawlCancelPolicy.failed`];
                break;
            case TYPE_ACTION_CRAWL_RURUBU.point_settings:
                action = apiCrawlOptionAction.crawlDataPointSetting;
                successMessage = messages["page.apiPlanAddAndEdit.pointSetting.success"];
                failureMessage = messages[`page.apiPlanAddAndEdit.pointSetting.failed`];
                break;
            default:
                break;
        }

        new Promise((resolve, reject) => {
            return dispatch(action({ data: { storeId, otaId, fieldKey }, resolve, reject }))
        })
            .then(() => message.success(successMessage))
            .catch((e) => {
                const messageError = e.response?.status === ERROR_STATUS.IN_PROGRESS_SYNC
                    ? messages[`page.apiPlanAddAndEdit.inProgressSyncError`]
                    : failureMessage;
                message.error(messageError);
            });
    }

    const syncDataToSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsSyncDataToSite(true)
            return dispatch(apiPlanAction.syncDataPlanToSite({ apiPlanOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.apiPlanAddAndEdit.modal.addSuccessTitle"],
                content: messages[`page.apiPlanAddAndEdit.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.apiPlanAddAndEdit.inProgressSyncError`]
                : messages[`page.apiPlanAddAndEdit.updateError`]
            Modal.error({
                title: messages["page.apiPlanAddAndEdit.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsSyncDataToSite(false)
        })
    });

    const updateDataFromSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsUpdateDataFromSite(true)
            return dispatch(apiPlanAction.updateDataFromSite({ apiPlanOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.apiPlan.modal.updateSuccessTitle"],
                content: messages[`page.apiPlan.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.apiPlan.modal.inProgressSyncError`]
                : messages[`page.apiPlan.modal.updateError`]
            Modal.error({
                title: messages["page.apiPlan.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsUpdateDataFromSite(false)
        })
    });

    const refreshOption = (fieldKey) => {
        if (otaId === null || otaId === undefined) {
            return;
        }
        if (storeId === null || storeId === undefined) {
            return;
        }
        switch (fieldKey) {
            case TYPE_ACTION_CRAWL_RURUBU.cancelPolicy:
                dispatch(apiCancelPolicyAction.getListCancelPolicy({ storeId, otaId }))
                break;
            case TYPE_ACTION_CRAWL_RURUBU.point_settings:
                dispatch(apiCrawlOptionAction.getListPointSetting({ storeId, otaId, fieldKey }))
                break;
            case TYPE_ACTION_CRAWL_RURUBU.feature_settings:
                dispatch(apiCrawlOptionAction.getListPriceFrom({ storeId, otaId, fieldKey }))
                break;
            default:
                break;
        }
    }

    return (
        <LayoutContentWrapper>
            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Row>
                        <Col {...colLeft}>
                            <Form.Item
                                label={messages["page.apiPlanAddAndEdit.Rakuten.sitePlanId"]}
                                name={`otaFieldContent.${tabKey}.sitePlanId`}
                                {...itemLeftLabel}
                            >
                                <Input
                                    name={`otaFieldContent.${tabKey}.sitePlanId`}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colRight} style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                                type="primary"
                                className="mr-10 mb-0 button"
                                block
                                hidden={apiPlanOtaId === null || apiPlanOtaId === undefined}
                                loading={isSyncDataToSite}
                                disabled={isDisableButtonSync}
                                onClick={syncDataToSite}
                            >
                                {messages["page.apiPlanAddAndEdit.buttonSync"]}
                            </Button>

                            <Button
                                type="primary"
                                className="mr-10 button"
                                block
                                hidden={apiPlanOtaId === null || apiPlanOtaId === undefined}
                                loading={isUpdateDataFromSite}
                                onClick={updateDataFromSite}
                            >
                                {messages["page.apiPlan.crawlUpdate"]}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={12}>
                    <Form.Item
                        label={messages["page.apiPlanAddAndEdit.Jalan.syncStatus"]}
                        name={`otaFieldContent.${tabKey}.syncStatus`}
                        labelCol={{ lg: 8, xs: 24 }}
                        wrapperCol={{ lg: 12, xs: 24 }}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.syncStatus`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} className={'position-error-message'}>
                    <Row>
                        <Col xs={24} lg={2}>
                            <Button
                                type="primary"
                                className="mr-2"
                                loading={loadingSyncStatus}
                                icon={<ReloadOutlined />}
                                onClick={() => refreshSyncPlan()}
                            >
                            </Button>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.syncErrorContent`}
                            >
                                <Input
                                    className="styleInputLog"
                                    onClick={() => setShowLog(true)}
                                    name={`otaFieldContent.${tabKey}.syncErrorContent`}
                                    value={
                                        syncErrorContent !== null &&
                                            syncErrorContent !== undefined &&
                                            syncErrorContent.length > 0 ? syncErrorContent[0] : ''
                                    }
                                    readOnly
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.apiPlan.ota.updateStatus"]}
                        name={`otaFieldContent.${tabKey}.updateStatus`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.updateStatus`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.apiPlanAddAndEdit.openSale"]}
                        name={`otaFieldContent.${tabKey}.openSaleStatus`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.openSaleStatus`}
                            disabled={true}
                        >
                            {LIST_STATUS_SALE_PROCESSING.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.crawlPlan.openSaleType"]}
                        name={`otaFieldContent.${tabKey}.openSaleType`}
                    >
                        <Select
                            className="select"
                            name={`otaFieldContent.${tabKey}.openSaleType`}
                            disabled={true}
                        >
                            {LIST_OPEN_SALE_STATUS.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <h1 className="heading">{messages['page.apiPlanAddAndEdit.rurubu.heading.basicSettings']}</h1>
            <Divider className={"mt-5"}></Divider>

            <Row justify="start">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 20, xs: 24 }}
                        label={
                            <Descriptions title={messages['page.apiPlanAddAndEdit.rurubu.title.heading.inventoryType']} />
                        }
                        name={`otaFieldContent.${tabKey}.inventoryType`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.apiPlanAddAndEdit.rurubu.title.heading.inventoryType"],
                            {
                                required: messages["form.error.mixed.required"],
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Radio.Group
                            name={`otaFieldContent.${tabKey}.inventoryType`}
                            onChange={(e) => {
                                handleChangeRadio(e, fieldKeyRurubuApiPlanOption.inventoryType)
                                setInventoryTypeValue(e.target.value)
                            }}
                            value={inventoryTypeValue}
                            disabled={isDisableEditMode}
                        >
                            <Row>
                                {optionValues.map((option, index) => {
                                    if (option.fieldKey === fieldKeyRurubuApiPlanOption.inventoryType) {
                                        return (
                                            <Radio value={option.value} key={index}>
                                                <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                    {option.value}
                                                </span>
                                            </Radio>
                                        )
                                    }
                                })}
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Divider className={"mt-5"}></Divider>
            <Descriptions title={messages['page.apiPlanAddAndEdit.rurubu.title.heading.basicInformation']} />

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.rurubu.title.planName"]}
                            </div>
                        }
                        className="custom-label"
                        name={`otaFieldContent.${tabKey}.planName`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.apiPlanAddAndEdit.rurubu.title.planName"],
                            {
                                required: messages["form.error.mixed.required"],
                            },
                            {
                                required: true,
                            }
                        )}
                    >
                        <Input
                            name={`otaFieldContent.${tabKey}.planName`}
                            maxLength={50}
                            onBlur={(e) => handleInputBlur(e)}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {(inventoryTypeValue != JAPANICAN) && (
                <>
                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.ratePlanDescription`}
                                label={
                                    <div className="custom-required">
                                        {messages["page.apiPlanAddAndEdit.rurubu.title.ratePlanDescription"]}
                                    </div>
                                }
                                className="custom-label"
                                {...itemLeftLabelFullApi}
                                validate={(value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.rurubu.title.ratePlanDescription"],
                                    {
                                        required: messages["form.error.mixed.required"],
                                    },
                                    {
                                        required: true,
                                    }
                                )}
                            >
                                <Row className="mb-10px">
                                    <Col lg={12} xs={24}>
                                        <Input.TextArea
                                            rows="4"
                                            name={`otaFieldContent.${tabKey}.ratePlanDescription`}
                                            maxLength={2000}
                                            onBlur={(e) => handleInputBlur(e)}
                                        />
                                    </Col>
                                </Row>

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.noticeInformation`}
                                label={messages["page.apiPlanAddAndEdit.rurubu.title.noticeInformation"]}
                                {...itemLeftLabelFullApi}
                            >
                                <Row className="mb-10px">
                                    <Col lg={12} xs={24}>
                                        <Input.TextArea
                                            rows="4"
                                            name={`otaFieldContent.${tabKey}.noticeInformation`}
                                            maxLength={2000}
                                            onBlur={(e) => handleInputBlur(e)}
                                        />
                                    </Col>
                                </Row>

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.paymentInformation`}
                                label={messages["page.apiPlanAddAndEdit.rurubu.title.paymentInformation"]}
                                {...itemLeftLabelFullApi}
                            >
                                <Row className="mb-10px">
                                    <Col lg={12} xs={24}>
                                        <Input.TextArea
                                            rows="2"
                                            name={`otaFieldContent.${tabKey}.paymentInformation`}
                                            maxLength={2000}
                                            onBlur={(e) => handleInputBlur(e)}
                                        />
                                    </Col>
                                </Row>

                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.cancelPolicyGroup`}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.rurubu.title.cancelPolicy"]}
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <div className="d-flex">
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.cancelPolicy`}
                                validate={(inventoryTypeValue != PACKAGED_GOODS) ? (value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.rurubu.title.cancelPolicy"],
                                    {
                                        required: messages["form.error.mixed.required"],
                                    },
                                    {
                                        required: true,
                                    }
                                ) : null}
                            >
                                <Select
                                    name={`otaFieldContent.${tabKey}.cancelPolicy`}
                                    listHeight={320}
                                    style={styleSelectCommon}
                                    showSearch
                                    {...searchSelect}
                                    allowClear
                                    disabled={inventoryTypeValue == PACKAGED_GOODS}
                                >
                                    {listCancelPolicy.map((option, index) => {
                                        return (
                                            <Option key={index} value={option.value}>
                                                {option.value}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name={`otaFieldContent.${tabKey}.cancelPolicyStatus`}
                            >
                                <Select
                                    name={`otaFieldContent.${tabKey}.cancelPolicyStatus`}
                                    listHeight={320}
                                    style={styleSelectCommon}
                                    disabled={true}
                                    value={statusCrawlCancelPolicy}
                                >
                                    {LIST_STATUS_CRAWL.map((select, index) => {
                                        return (
                                            <Select.Option
                                                key={index}
                                                value={select.id}
                                            >
                                                {select.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Button type="primary"
                                style={{ width: "32px" }}
                                block
                                icon={<ReloadOutlined />}
                                onClick={() => refreshOption(TYPE_ACTION_CRAWL_RURUBU.cancelPolicy)}
                            ></Button>
                            <Button type="primary"
                                style={{ marginLeft: "10px", width: "120px" }}
                                block
                                onClick={() => crawlOption(TYPE_ACTION_CRAWL_RURUBU.cancelPolicy)}
                            >
                                {messages["page.apiPlanAddAndEdit.rurubu.button.update"]}
                            </Button>
                        </div>
                    </Form.Item>
                </Col>
            </Row>


            {(inventoryTypeValue == RURUBU_TRAVEL) && (
                <Row justify="start" className="mb-25px">
                    <Col {...colFullApi}>
                        <Form.Item
                            name={`otaFieldContent.${tabKey}.pointSettingGroup`}
                            label={messages["page.apiPlanAddAndEdit.rurubu.title.pointSettings"]}
                            {...itemLeftLabelFullApi}
                        >
                            <div className="d-flex">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.pointSettings`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.pointSettings`}
                                        listHeight={320}
                                        style={styleSelectCommon}
                                        showSearch
                                        {...searchSelect}
                                        allowClear
                                    >
                                        {listPointSetting.map((option, index) => {
                                            return (
                                                <Option key={index} value={option.value}>
                                                    {option.name}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.pointSettingsStatus`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.pointSettingsStatus`}
                                        listHeight={320}
                                        style={styleSelectCommon}
                                        disabled={true}
                                        value={statusPointSetting}
                                    >
                                        {LIST_STATUS_CRAWL.map((select, index) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={select.id}
                                                >
                                                    {select.name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>

                                <Button type="primary"
                                    style={{ width: "32px" }}
                                    block
                                    icon={<ReloadOutlined />}
                                    onClick={() => refreshOption(TYPE_ACTION_CRAWL_RURUBU.point_settings)}
                                ></Button>
                                <Button type="primary"
                                    style={{ marginLeft: "10px", width: "120px" }}
                                    block
                                    onClick={() => crawlOption(TYPE_ACTION_CRAWL_RURUBU.point_settings)}
                                >
                                    {messages["page.apiPlanAddAndEdit.rurubu.button.update"]}
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            )}

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={messages["page.apiPlanAddAndEdit.rurubu.title.priceLinkageSettings"]}
                        name={`otaFieldContent.${tabKey}.pricesFromGroup`}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.pricesFrom`}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.pricesFrom`}
                                        listHeight={320}
                                        showSearch
                                        {...searchSelect}
                                        allowClear
                                        onChange={(value) => {
                                            handleChangeSelect('pricesFrom', value)
                                            setPricesFromValue(value)
                                        }}
                                        value={pricesFromValue}
                                    >
                                        {listPlanLinkPrices.map((option, index) => {
                                            return (
                                                <Option key={index} value={option.sitePlanId}>
                                                    <TooltipCustom value={option.planName} />
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        {(pricesFromValue != "-1") && (
                            <>
                                <Row className="mt-5px">
                                    <Col xs={24} lg={{ span: 24 }}>
                                        <Form.Item
                                            name={`otaFieldContent.${tabKey}.rateCategoryUsePriceIncrease`}
                                        >
                                            <Radio.Group
                                                name={`otaFieldContent.${tabKey}.rateCategoryUsePriceIncrease`}
                                                onChange={(e) => {
                                                    handleChangeRadio(e, `rateCategoryUsePriceIncrease`)
                                                    setRateCategoryUsePriceIncreaseValue(e.target.value)
                                                }}
                                                value={rateCategoryUsePriceIncreaseValue}
                                            >
                                                <Row>
                                                    {optionValues.map((option, index) => {
                                                        if (option.fieldKey === fieldKeyRurubuApiPlanOption.rateCategoryUsePriceIncrease) {
                                                            return (
                                                                <Radio value={option.value} key={index}>
                                                                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                                        {option.value}
                                                                    </span>
                                                                </Radio>
                                                            )
                                                        }
                                                    })}
                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row className="mt-5px">
                                    <div className="d-flex">
                                        <Form.Item
                                            wrapperCol={{ lg: 22, xs: 22 }}
                                            name={`otaFieldContent.${tabKey}.usePriceValue`}
                                        >
                                            <Input
                                                name={`otaFieldContent.${tabKey}.usePriceValue`}
                                                style={styleSelectCommon}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            wrapperCol={{ lg: 22, xs: 22 }}
                                            name={`otaFieldContent.${tabKey}.chargeType`}
                                        >
                                            <Select
                                                name={`otaFieldContent.${tabKey}.chargeType`}
                                                style={styleSelectCommon}
                                                disabled={true}
                                            >
                                                {optionValues.map((option, index) => {
                                                    if (option.fieldKey === fieldKeyRurubuApiPlanOption.chargeType) {
                                                        return (
                                                            <Option key={index} value={option.value}>
                                                                {option.value}
                                                            </Option>
                                                        )
                                                    }
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Row>
                            </>
                        )}
                    </Form.Item>
                </Col>
            </Row>

            {(inventoryTypeValue == RURUBU_TRAVEL) && (
                <Row justify="start" className="mb-25px">
                    <Col {...colFullApi}>
                        <Form.Item
                            name={`otaFieldContent.${tabKey}.paymentChannel`}
                            label={messages["page.apiPlanAddAndEdit.rurubu.title.paymentChannel"]}
                            className="custom-label"
                            {...itemLeftLabelFullApi}
                        >
                            <Row>
                                <Col xs={24} lg={{ span: 24 }}>
                                    <Form.Item
                                        name={`otaFieldContent.${tabKey}.paymentChannel`}
                                    >
                                        <Checkbox.Group
                                            name={`otaFieldContent.${tabKey}.paymentChannel`}
                                            onChange={(value) => {
                                                handleChangeCheckbox(`otaFieldContent.${tabKey}.paymentChannel`, value)
                                                setPaymentChannelValue(value)
                                            }}
                                            value={paymentChannelValue}
                                        >
                                            {optionValues.map((option, index) => {
                                                if (option.fieldKey === fieldKeyRurubuApiPlanOption.paymentChannel) {
                                                    return (
                                                        <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                            {option.value}
                                                        </Checkbox>
                                                    )
                                                }
                                            })}
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
            )}

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.searchKey`}
                        label={
                            <div>{messages["page.apiPlanAddAndEdit.rurubu.title.searchKey"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Checkbox.Group
                            name={`otaFieldContent.${tabKey}.searchKey`}
                            onChange={(value) => {
                                handleChangeCheckbox(`otaFieldContent.${tabKey}.searchKey`, value)
                                setSearchKeyValue(value)
                            }}
                            value={searchKeyValue}
                        >
                            <Row>
                                {optionValues.map((option, index) => {
                                    if (option.fieldKey === fieldKeyRurubuApiPlanOption.searchKey) {
                                        return (
                                            <Col xs={24} lg={{ span: 8 }} key={index} className="mb-10px">
                                                <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>

            {(inventoryTypeValue != JAPANICAN) && (
                <>
                    <Divider className={"mt-5"}></Divider>

                    <Row justify="start" className="mb-25px">
                        <Col xs={24} lg={{ span: 24 }}>
                            <Form.Item
                                labelCol={{ lg: 4, xs: 24 }}
                                wrapperCol={{ lg: 6, xs: 24 }}
                                label={
                                    <Descriptions title={messages['page.apiPlanAddAndEdit.rurubu.title.heading.request']} />
                                }
                                name={`otaFieldContent.${tabKey}.specialRequestListen`}
                            >
                                <Radio.Group
                                    name={`otaFieldContent.${tabKey}.specialRequestListen`}
                                    onChange={(e) => {
                                        handleChangeRadio(e, `specialRequestListen`)
                                        setSpecialRequestListenValue(e.target.value)
                                    }}
                                    value={specialRequestListenValue}
                                >
                                    <Row>
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.specialRequestListen) {
                                                return (
                                                    <Radio value={option.value} key={index}>
                                                        <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                            {option.value}
                                                        </span>
                                                    </Radio>
                                                )
                                            }
                                        })}

                                        <span>{messages["page.apiPlanAddAndEdit.rurubu.searchKey.note"]}</span>
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="start" className="mb-25px">
                        <Col {...colFullApi}>
                            <Form.Item
                                name={`otaFieldContent.${tabKey}.questionToCustomer`}
                                label={' '}
                                {...itemLeftLabelFullApi}
                            >
                                <Row className="mb-10px">
                                    <Col lg={12}>
                                        <Input.TextArea
                                            rows="2"
                                            name={`otaFieldContent.${tabKey}.questionToCustomer`}
                                            maxLength={2000}
                                            onBlur={(e) => handleInputBlur(e)}
                                        />
                                    </Col>
                                </Row>

                                <span>{messages["page.apiPlanAddAndEdit.rurubu.questionToCustomer.note"]}</span>
                            </Form.Item>
                        </Col>
                    </Row>

                </>
            )}

            <Divider className={"mt-5"}></Divider>
            <Descriptions title={messages['page.apiPlanAddAndEdit.rurubu.title.heading.reservationPeriod']} />

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.selldate`}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.rurubu.title.fromSelldate"]}
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <div className="d-flex">
                            <Form.Item
                                wrapperCol={{ lg: 22, xs: 22 }}
                                name={`otaFieldContent.${tabKey}.fromSelldate`}
                                validate={(value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.rurubu.title.fromSelldate"],
                                    {
                                        required: messages["form.error.mixed.required"],
                                    },
                                    {
                                        required: true,
                                    }
                                )}
                            >
                                <DatePicker
                                    format={DATE_FORMAT}
                                    name={`otaFieldContent.${tabKey}.fromSelldate`}
                                    style={styleInputCommon}
                                    onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.fromSelldate`, date)}
                                />
                            </Form.Item>
                        </div>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.selldate`}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.rurubu.title.toSelldate"]}
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <div className="d-flex">
                            <Form.Item
                                wrapperCol={{ lg: 22, xs: 22 }}
                                name={`otaFieldContent.${tabKey}.toSelldate`}
                                validate={isEmpty(extendSellDateForeverValue) ? (value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.rurubu.title.toSelldate"],
                                    {
                                        required: messages["form.error.mixed.required"],
                                    },
                                    {
                                        required: true,
                                    }
                                ) : null}
                            >
                                <DatePicker
                                    format={DATE_FORMAT}
                                    name={`otaFieldContent.${tabKey}.toSelldate`}
                                    style={styleInputCommon}
                                    onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.toSelldate`, date)}
                                    disabled={!isEmpty(extendSellDateForeverValue)}
                                />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{ lg: 24, xs: 24 }}
                                name={`otaFieldContent.${tabKey}.extendSellDateForever`}
                            >
                                <Checkbox.Group
                                    name={`otaFieldContent.${tabKey}.extendSellDateForever`}
                                    onChange={(value) => {
                                        handleChangeCheckbox(`otaFieldContent.${tabKey}.extendSellDateForever`, value)
                                        setExtendSellDateForeverValue(value)
                                    }}
                                    value={extendSellDateForeverValue}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyRurubuApiPlanOption.extendSellDateForever) {
                                            return (
                                                <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                    {option.value}
                                                </Checkbox>
                                            )
                                        }
                                    })}
                                </Checkbox.Group>
                            </Form.Item>

                        </div>
                    </Form.Item>
                </Col>
            </Row>

            <Divider className={"mt-5"}></Divider>
            <Descriptions title={messages['page.apiPlanAddAndEdit.rurubu.title.heading.stayPeriod']} />

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.staydate`}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.rurubu.title.fromStaydate"]}
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <div className="d-flex">
                            <Form.Item
                                wrapperCol={{ lg: 22, xs: 22 }}
                                name={`otaFieldContent.${tabKey}.fromStaydate`}
                                validate={(value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.rurubu.title.fromStaydate"],
                                    {
                                        required: messages["form.error.mixed.required"],
                                    },
                                    {
                                        required: true,
                                    }
                                )}
                            >
                                <DatePicker
                                    format={DATE_FORMAT}
                                    name={`otaFieldContent.${tabKey}.fromStaydate`}
                                    style={styleInputCommon}
                                    onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.fromStaydate`, date)}
                                />
                            </Form.Item>
                        </div>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.staydate`}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.rurubu.title.toStaydate"]}
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <div className="d-flex">
                            <Form.Item
                                wrapperCol={{ lg: 22, xs: 22 }}
                                name={`otaFieldContent.${tabKey}.toStaydate`}
                                validate={isEmpty(extendSellDateForeverValue) ? (value) => ValidateField(
                                    value,
                                    messages["page.apiPlanAddAndEdit.rurubu.title.toStaydate"],
                                    {
                                        required: messages["form.error.mixed.required"],
                                    },
                                    {
                                        required: true,
                                    }
                                ) : null}
                            >
                                <DatePicker
                                    format={DATE_FORMAT}
                                    name={`otaFieldContent.${tabKey}.toStaydate`}
                                    style={styleInputCommon}
                                    onChange={(date) => onChangeDate(`otaFieldContent.${tabKey}.toStaydate`, date)}
                                    disabled={!isEmpty(extendStayDateForeverValue)}
                                />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{ lg: 24, xs: 24 }}
                                name={`otaFieldContent.${tabKey}.extendStayDateForever`}
                            >
                                <Checkbox.Group
                                    name={`otaFieldContent.${tabKey}.extendStayDateForever`}
                                    onChange={(value) => {
                                        handleChangeCheckbox(`otaFieldContent.${tabKey}.extendStayDateForever`, value)
                                        setExtendStayDateForeverValue(value)
                                    }}
                                    value={extendStayDateForeverValue}
                                >
                                    {optionValues.map((option, index) => {
                                        if (option.fieldKey === fieldKeyRurubuApiPlanOption.extendStayDateForever) {
                                            return (
                                                <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                    {option.value}
                                                </Checkbox>
                                            )
                                        }
                                    })}
                                </Checkbox.Group>
                            </Form.Item>
                        </div>
                    </Form.Item>
                </Col>
            </Row>

            <Divider className={"mt-5"}></Divider>

            <h1 className="heading">{messages['page.apiPlanAddAndEdit.rurubu.heading.mealConditionsAndPlanInclusions']}</h1>
            <Divider className={"mt-5"}></Divider>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.dietaryRequirements`}
                        label={messages["page.apiPlanAddAndEdit.rurubu.title.dietaryRequirements"]}
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.dietaryRequirements`}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.dietaryRequirements`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.dietaryRequirements`, value)
                                            setDietaryRequirementsValue(value)
                                        }}
                                        value={dietaryRequirementsValue}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.dietaryRequirements) {
                                                if (isEmpty(dietaryRequirementsValue)) {
                                                    if (option.value == BREAKFAST || option.value == DINNER) {
                                                        return (
                                                            <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                                {option.value}
                                                            </Checkbox>
                                                        )
                                                    }
                                                } else {
                                                    if (dietaryRequirementsValue.includes(DINNER)) {
                                                        return (
                                                            <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                                {option.value}
                                                            </Checkbox>
                                                        )
                                                    }

                                                    if (option.value == BREAKFAST || option.value == DINNER) {
                                                        return (
                                                            <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                                {option.value}
                                                            </Checkbox>
                                                        )
                                                    }
                                                }
                                            }
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.mealBreakfast`}
                        label={messages["page.apiPlanAddAndEdit.rurubu.title.mealBreakfast"]}
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.mealBreakfast`}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.mealBreakfast`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.mealBreakfast`, value)
                                            setMealBreakfastValue(value);
                                        }}
                                        value={mealBreakfastValue}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.mealBreakfast) {
                                                return (
                                                    <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                        {option.value}
                                                    </Checkbox>
                                                )
                                            }
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.mealDinner`}
                        label={messages["page.apiPlanAddAndEdit.rurubu.title.mealDinner"]}
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.mealDinner`}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.mealDinner`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.mealDinner`, value);
                                            setMealDinnerValue(value);
                                        }}
                                        value={mealDinnerValue}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.mealDinner) {
                                                return (
                                                    <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                        {option.value}
                                                    </Checkbox>
                                                )
                                            }
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.benefitFoodDrink`}
                        label={messages["page.apiPlanAddAndEdit.rurubu.title.benefitFoodDrink"]}
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.benefitFoodDrink`}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.benefitFoodDrink`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.benefitFoodDrink`, value);
                                            setBenefitFoodDrinkValue(value);
                                        }}
                                        value={benefitFoodDrinkValue}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.benefitFoodDrink) {
                                                return (
                                                    <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                        {option.value}
                                                    </Checkbox>
                                                )
                                            }
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.servicesBenefits`}
                        label={
                            <div>
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.title.servicesBenefits1"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.title.servicesBenefits2"]}</div>
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.servicesBenefits`}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.servicesBenefits`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.servicesBenefits`, value);
                                            setServicesBenefitsValue(value);
                                        }}
                                        value={servicesBenefitsValue}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.servicesBenefits) {
                                                return (
                                                    <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                        {option.value}
                                                    </Checkbox>
                                                )
                                            }
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>

                        </Row>

                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.transportBenefits`}
                        label={
                            <div>
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.title.transportBenefits1"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.title.transportBenefits2"]}</div>
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.transportBenefits`}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.transportBenefits`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.transportBenefits`, value);
                                            setTransportBenefitsValue(value);
                                        }}
                                        value={transportBenefitsValue}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.transportBenefits) {
                                                return (
                                                    <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                        {option.value}
                                                    </Checkbox>
                                                )
                                            }
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>

                        </Row>

                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.activityBenefits`}
                        label={
                            <div>
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.title.activityBenefits1"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.title.activityBenefits2"]}</div>
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.activityBenefits`}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.activityBenefits`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.activityBenefits`, value);
                                            setActivityBenefitsValue(value);
                                        }}
                                        value={activityBenefitsValue}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.activityBenefits) {
                                                return (
                                                    <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                        {option.value}
                                                    </Checkbox>
                                                )
                                            }
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>

                        </Row>

                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.voucherBenefits`}
                        label={
                            <div>
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.title.voucherBenefits1"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.title.voucherBenefits2"]}</div>
                            </div>
                        }
                        {...itemLeftLabelFullApi}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }}>
                                <Form.Item
                                    name={`otaFieldContent.${tabKey}.voucherBenefits`}
                                >
                                    <Checkbox.Group
                                        name={`otaFieldContent.${tabKey}.voucherBenefits`}
                                        onChange={(value) => {
                                            handleChangeCheckbox(`otaFieldContent.${tabKey}.voucherBenefits`, value);
                                            setVoucherBenefitsValue(value);
                                        }}
                                        value={voucherBenefitsValue}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.voucherBenefits) {
                                                return (
                                                    <Checkbox value={option.value} style={styleCheckboxOptionCommon}>
                                                        {option.value}
                                                    </Checkbox>
                                                )
                                            }
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>

                        </Row>

                    </Form.Item>
                </Col>
            </Row>

            <Divider className={"mt-5"}></Divider>
            <h1 className="heading">{messages['page.apiPlanAddAndEdit.rurubu.heading.planConditions']}</h1>
            <Divider className={"mt-5"}></Divider>

            <Row justify="start">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={
                            <Descriptions title={messages['page.apiPlanAddAndEdit.rurubu.title.heading.acceptGenderSetting']} />
                        }
                        name={`otaFieldContent.${tabKey}.acceptGenderSetting`}
                    >
                        <Radio.Group
                            name={`otaFieldContent.${tabKey}.acceptGenderSetting`}
                            onChange={(e) => {
                                handleChangeRadio(e, `acceptGenderSetting`)
                                setAcceptGenderSettingValue(e.target.value)
                            }}
                            value={acceptGenderSettingValue}
                        >
                            <Row>
                                {optionValues.map((option, index) => {
                                    if (option.fieldKey === fieldKeyRurubuApiPlanOption.acceptGenderSetting) {
                                        return (
                                            <Radio value={option.value} key={index}>
                                                <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                    {option.value}
                                                </span>
                                            </Radio>
                                        )
                                    }
                                })}
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Divider className={"mt-5"}></Divider>
            <Descriptions title={messages['page.apiPlanAddAndEdit.rurubu.title.heading.planSettings']} />

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.checkInTim`}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.rurubu.title.checkInTime"]}
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <Col className="d-flex flex-wrap">
                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.checkInStartTime`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.rurubu.title.checkInTime"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.checkInStartTime`}
                                        listHeight={320}
                                        style={styleSelectCommon}
                                        showSearch
                                        {...searchSelect}
                                        allowClear
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.checkInStartTime) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="d-flex align-item-baseline">
                                <Form.Item
                                    wrapperCol={{ lg: 22, xs: 22 }}
                                    name={`otaFieldContent.${tabKey}.checkInEndTime`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.apiPlanAddAndEdit.rurubu.title.checkInTime"],
                                        {
                                            required: messages["form.error.mixed.required"],
                                        },
                                        {
                                            required: true,
                                        }
                                    )}
                                >
                                    <Select
                                        name={`otaFieldContent.${tabKey}.checkInEndTime`}
                                        listHeight={320}
                                        style={styleSelectCommon}
                                        showSearch
                                        {...searchSelect}
                                        allowClear
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.checkInEndTime) {
                                                return (
                                                    <Option key={index} value={option.value}>
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.checkOutEndTime`}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.rurubu.title.checkOutEndTime"]}
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.apiPlanAddAndEdit.rurubu.title.checkOutEndTime"],
                            {
                                required: messages["form.error.mixed.required"],
                            },
                            {
                                required: true,
                            }
                        )}
                    >
                        <Select
                            name={`otaFieldContent.${tabKey}.checkOutEndTime`}
                            listHeight={320}
                            style={styleSelectCommon}
                            showSearch
                            {...searchSelect}
                            allowClear
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyRurubuApiPlanOption.checkOutEndTime) {
                                    return (
                                        <Option key={index} value={option.value}>
                                            {option.value}
                                        </Option>
                                    )
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.minGuests`}
                        label={messages["page.apiPlanAddAndEdit.rurubu.title.minGuests"]}
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <InputNumber
                            name={`otaFieldContent.${tabKey}.minGuests`}
                            max={10}
                            maxLength={2}
                            min={1}
                            style={styleWidthSmallInputCommon}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col xs={24} lg={{ span: 24 }}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 6, xs: 24 }}
                        label={
                            <div>
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.title.sellLimitPerDay1"]}</div>
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.title.sellLimitPerDay2"]}</div>
                            </div>
                        }
                        name={`otaFieldContent.${tabKey}.sellLimitPerDay`}
                        className="custom-required"
                    >
                        <InputNumber
                            name={`otaFieldContent.${tabKey}.sellLimitPerDay`}
                            maxLength={3}
                            min={1}
                            style={styleWidthSmallInputCommon}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.minDaysInAdvance`}
                        label={messages["page.apiPlanAddAndEdit.rurubu.title.minDaysInAdvance"]}
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <InputNumber
                            name={`otaFieldContent.${tabKey}.minDaysInAdvance`}
                            max={185}
                            maxLength={3}
                            min={1}
                            style={styleWidthSmallInputCommon}
                            onChange={(value) => setMinDaysInAdvanceValue(value)}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.maxDaysInAdvance`}
                        label={messages["page.apiPlanAddAndEdit.rurubu.title.maxDaysInAdvance"]}
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.apiPlanAddAndEdit.rurubu.title.maxDaysInAdvance"],
                            {
                                required: messages["form.error.mixed.required"],
                            },
                            {
                                required: true,
                            }
                        )}
                    >
                        <InputNumber
                            name={`otaFieldContent.${tabKey}.maxDaysInAdvance`}
                            maxLength={3}
                            max={185}
                            style={styleWidthSmallInputCommon}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.hourlyCutoffTime`}
                        label={
                            <div className="custom-required">
                                {messages["page.apiPlanAddAndEdit.rurubu.title.hourlyCutoffTime"]}
                            </div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                        validate={(minDaysInAdvanceValue == null) ? (value) => ValidateField(
                            value,
                            messages["page.apiPlanAddAndEdit.rurubu.title.hourlyCutoffTime"],
                            {
                                required: messages["form.error.mixed.required"],
                            },
                            {
                                required: true,
                            }
                        ) : null}
                    >
                        <Select
                            name={`otaFieldContent.${tabKey}.hourlyCutoffTime`}
                            listHeight={320}
                            style={styleSelectCommon}
                            showSearch
                            {...searchSelect}
                            allowClear
                            disabled={minDaysInAdvanceValue != null}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyRurubuApiPlanOption.hourlyCutoffTime) {
                                    return (
                                        <Option key={index} value={option.value}>
                                            {option.value}
                                        </Option>
                                    )
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.minLengthOfStay`}
                        label={messages["page.apiPlanAddAndEdit.rurubu.title.minLengthOfStay"]}
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                    >
                        <InputNumber
                            name={`otaFieldContent.${tabKey}.minLengthOfStay`}
                            max={12}
                            maxLength={2}
                            min={1}
                            style={styleWidthSmallInputCommon}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="start" className="mb-25px">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`otaFieldContent.${tabKey}.maxLengthOfStay`}
                        label={
                            <div className="custom-required">{messages["page.apiPlanAddAndEdit.rurubu.title.maxLengthOfStay"]}</div>
                        }
                        className="custom-label"
                        {...itemLeftLabelFullApi}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.apiPlanAddAndEdit.rurubu.title.maxLengthOfStay"],
                            {
                                required: messages["form.error.mixed.required"],
                            },
                            {
                                required: true,
                            }
                        )}
                    >
                        <InputNumber
                            name={`otaFieldContent.${tabKey}.maxLengthOfStay`}
                            max={(inventoryTypeValue == PACKAGED_GOODS) ? 13 : 12}
                            maxLength={2}
                            min={1}
                            style={styleWidthSmallInputCommon}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Divider className={"mt-5"}></Divider>

            <Row justify="start" className="mb-25px">
                <RoomRurubuExtendFields
                    dataOtas={values}
                    setFieldValue={setFieldValue}
                    otaId={otaId}
                    storeId={storeId}
                    tabKey={tabKey}
                    apiPlanOtaId={apiPlanOtaId}
                    setEventKodomoSubmit={setEventKodomoSubmit}
                    isCloneData={isCloneData}
                    activeTabKey={activeTabKey}
                    optionValues={optionValues}
                />
            </Row>

            <Modal
                title={messages["page.apiPlanAddAndEdit.titleLog"]}
                style={{ top: 20 }}
                width={700}
                visible={showLog}
                footer={null}
                onCancel={() => setShowLog(false)}
            >
                <Row style={{ maxHeight: '80vh', overflowY: 'auto', display: 'block' }}>
                    {syncErrorContent != null && syncErrorContent.length > 0 && syncErrorContent.map((x, index) => (
                        <Row style={{ marginTop: '10px' }} key={index}>
                            <p key={index}>{x}</p>
                        </Row>
                    ))}
                </Row>
                {syncErrorContent == null || syncErrorContent.length <= 0 && (
                    <Row style={{ padding: '10px', justifyContent: 'center' }}>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>{messages["antTable.emptyData"]}</span>
                            }
                        >
                        </Empty>
                    </Row>
                )}
            </Modal>
        </LayoutContentWrapper>
    );
}

Rurubu.propTypes = {
    setFieldValue: PropTypes.any,
};

const rurubuInitialValue = {
    inventoryType: 'るるぶトラベル',
    planName: null,
    ratePlanDescription: null,
    noticeInformation: null,
    paymentInformation: null,
    cancelPolicy: null,
    pointSettings: null,
    pricesFrom: '-1',
    rateCategoryUsePriceIncrease: '増額（+）',
    usePriceValue: null,
    chargeType: '1名/1泊',
    paymentChannel: ['現地払い', '決済代行', '法人一括精算'],
    searchKey: null,
    specialRequestListen: '聞かない',
    questionToCustomer: null,
    fromSelldate: null,
    toSelldate: null,
    extendSellDateForever: [],
    fromStaydate: null,
    toStaydate: null,
    extendStayDateForever: null,
    dietaryRequirements: [],
    mealBreakfast: [],
    mealDinner: [],
    benefitFoodDrink: [],
    servicesBenefits: [],
    transportBenefits: [],
    activityBenefits: [],
    voucherBenefits: [],
    acceptGenderSetting: '両方可',
    checkInStartTime: '15:00',
    checkInEndTime: '18:00',
    checkOutEndTime: '10:00',
    minGuests: null,
    sellLimitPerDay: null,
    minDaysInAdvance: null,
    maxDaysInAdvance: '185',
    hourlyCutoffTime: '24:00（当日予約可能な場合）',
    minLengthOfStay: null,
    maxLengthOfStay: 12
}

const styleInputCommon = {
    minWidth: 168,
    marginRight: 15
}

const styleSelectCommon = {
    width: 168,
    marginRight: 15
}

const styleWidthSmallInputCommon = {
    width: 168
}

const styleCheckboxOptionCommon = {
    margin: "0 10px"
}

export default memo(Rurubu);
