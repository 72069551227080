import actions from "./actions";
const initialState = {
    error: null,
    loading: false,
    crawler: {},
    isExistFile: false,
};
export default function crawlRankingAndScoreReducer(state = initialState, action) {
    switch (action.type) {
        case actions.CRAWL_RANKING_AND_SCORE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.CRAWL_RANKING_AND_SCORE_SUCCESS:
            return {
                ...state,
                crawler: action.crawler,
                loading: false
            };
        case actions.CRAWL_RANKING_AND_SCORE_ERROR:
            return {
                ...state,
                loading: false
            };
        case actions.GET_CRAWL_RANKING_AND_SCORE_REQUEST:
            return {
                ...state,
                loading: true,
                crawler: {},
            };
        case actions.GET_CRAWL_RANKING_AND_SCORE_SUCCESS:
            return {
                ...state,
                loading: false,
                crawler: action.crawler,
            };
        case actions.GET_CRAWL_RANKING_AND_SCORE_ERROR:
            return {
                ...state,
                loading: false,
                crawler: {},
            };
        case actions.GET_INFORMATION_STORE_REQUEST:
            return {
                ...state,
            };
        case actions.GET_INFORMATION_STORE_SUCCESS:
            return {
                ...state,
                isExistFile: action.isExistFile,
            };
        case actions.GET_INFORMATION_STORE_ERROR:
            return {
                ...state,
                isExistFile: false,
            };
        case actions.DELETE_INFORMATION_STORE_REQUEST:
            return {
                ...state,
            };
        case actions.DELETE_INFORMATION_STORE_SUCCESS:
            return {
                ...state,
            };
        case actions.DELETE_INFORMATION_STORE_ERROR:
            return {
                ...state,
            };
        default:
            return state;
    }
}
