import React, {memo, useState, useEffect, useCallback} from "react";
import { TabStyles } from "./TabStyle";
import {Col, Row, Button, Radio, Checkbox, Modal, Empty} from "antd";
import {
    colFull,
    colFullApi, colLeftApi,
    colSingleUnit,
    customCheckboxGroupFullRow,
    inputColLeft, inputColRight, itemLeftLabelApi,
    itemLeftLabelFullApi
} from "@iso/assets/styles/form.style";
import {colRight} from "@iso/containers/TaskLog";
import {Form, Input, InputNumber, Select} from "formik-antd";
import {ValidateField} from "@iso/validates/ValidateField";
import {useIntl} from "react-intl";
import {
    ERROR_STATUS,
    fieldKeyIkkyuApiRoomTypeOption, LIST_OPEN_SALE_STATUS, LIST_STATUS_SALE_PROCESSING, LIST_STATUS_SYNC,
    searchSelect, TYPE_ROOM_CRAWL, TYPE_ROOM_FORM, typeStatusCrawlOption
} from "@iso/constants/api.constant";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";
import {Textarea} from "@iso/components/uielements/input";
import {ReloadOutlined} from "@ant-design/icons";
import {MODE_SCREEN} from "@iso/constants/common.constant";
import roomAction from "@iso/redux/apiRoom/actions";

const IkkyuTab = ({
    values,
    dataOta,
    setFieldValue,
    tabKey,
    apiRoomId,
    apiOptionValues,
    dataRoom,
    mode
}) => {
    const { messages } = useIntl();
    const dispatch = useDispatch();
    const [optionValues, setOptionValues] = useState([])
    const [amenityEquipment, setAmenityEquipment] = useState([])
    const [roomAttribute, setRoomAttribute] = useState([])
    const [serviceAttribute, setServiceAttribute] = useState([])
    const [activeChildCosleeping, setActiveChildCosleeping] = useState(null)
    const [activeRoomFloorClassification, setActiveRoomFloorClassification] = useState(null)
    const [activeRoomListDisplayCategory, setActiveRoomListDisplayCategory] = useState(null)
    const [activeAmenityDisplay, setActiveAmenityDisplay] = useState(null)
    const [roomPRValue, setRoomPRValue] = useState(null)
    const [packageRoomPRValue, setPackageRoomPRValue] = useState(null)
    const [loadingRoom, setLoadingRoom] = useState(false)
    const [syncErrorContentFirst, setSyncErrorContentFirst] = useState(null)
    const [showLog, setShowLog] = useState(false);
    const [syncErrorContent, setSyncErrorContent] = useState([])
    const [isUpdateDataFromSite, setIsUpdateDataFromSite] = useState(false)
    const [isSyncDataToSite, setIsSyncDataToSite] = useState(false)
    const [disableSyncData, setDisableSyncData] = useState(true)
    const [apiRoomOtaId, setApiRoomOtaId] = useState(null)

    useEffect(() => {
        if (apiRoomId
            && !isEmpty(dataRoom.apiRoomOtas[tabKey])
            && (dataRoom.apiRoomOtas[tabKey].typeRoom == TYPE_ROOM_FORM
                || (dataRoom.apiRoomOtas[tabKey].typeRoom == TYPE_ROOM_CRAWL
                    && dataRoom.apiRoomOtas[tabKey].updateStatus == typeStatusCrawlOption.crawlSuccess
                ))
        ) {
            setDisableSyncData(false)
        } else {
            setDisableSyncData(true)
        }
    }, [dataRoom])

    useEffect(() => {
        setFieldValue(`apiRoomOtas[${tabKey}]['otaId']`, dataOta.otaId)
        if (apiRoomId && !isEmpty(values) && !isEmpty(values.apiRoomOtas[tabKey]) && !isEmpty(values.apiRoomOtas[tabKey].fieldContents)) {
            const amenityEquipment = values.apiRoomOtas[tabKey]?.fieldContents?.amenityEquipment;
            const roomAttribute = values.apiRoomOtas[tabKey]?.fieldContents?.roomAttribute;
            const serviceAttribute = values.apiRoomOtas[tabKey]?.fieldContents?.serviceAttribute;
            setAmenityEquipment(Array.isArray(amenityEquipment) ? amenityEquipment : []);
            setRoomAttribute(Array.isArray(roomAttribute) ? roomAttribute : []);
            setServiceAttribute(Array.isArray(serviceAttribute) ? serviceAttribute : []);
            setActiveAmenityDisplay(values.apiRoomOtas[tabKey].fieldContents.amenityDisplay)
            setActiveRoomListDisplayCategory(values.apiRoomOtas[tabKey].fieldContents.roomListDisplayCategory)
            setActiveRoomFloorClassification(values.apiRoomOtas[tabKey].fieldContents.roomFloorClassification)
            setActiveChildCosleeping(values.apiRoomOtas[tabKey].fieldContents.childCosleeping)
            setRoomPRValue(values.apiRoomOtas[tabKey].fieldContents.roomPR)
            setPackageRoomPRValue(values.apiRoomOtas[tabKey].fieldContents.packageRoomPR)
            const syncErrorFirst = getSyncError(values);
            setSyncErrorContentFirst(syncErrorFirst[0])
            const filteredArray = syncErrorFirst.filter(item => item.trim() !== '');
            setSyncErrorContent(filteredArray)
            setApiRoomOtaId(values.apiRoomOtas[tabKey]?.apiRoomOtaId)
            if (mode == MODE_SCREEN.COPY) {
                setSyncErrorContentFirst(null);
                setFieldValue(`apiRoomOtas[${tabKey}]['syncStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['updateStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['syncErrorContent']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['siteRoomId']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}]['openSaleStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}][fieldContents]['openSaleOtaStatus']`, null);
            }
        } else {
            if (!apiRoomId || Array.isArray(values.otaId) && values.otaId.includes(tabKey)) {
                setFieldValue(`apiRoomOtas[${tabKey}].fieldContents`, ikyyuInitialValue)
            }
        }
        if (!isEmpty(apiOptionValues)) {
            apiOptionValues.map((apiOptionValue) => {
                if (apiOptionValue.otaId == dataOta.otaId) {
                    setOptionValues(apiOptionValue.options)
                }
            })
        }
    }, [apiOptionValues, dataOta]);

    const getSyncError = (values) => {
        const syncErrorContent = values.apiRoomOtas[tabKey]?.syncErrorContent || '';
        const syncErrorArray = syncErrorContent.split("\n").map(item => item.trim());
        return syncErrorArray || [];
    }

    const onChangeSelectValue = (values, name) => {
        if (values === undefined) {
            values = null;
        }

        setFieldValue(name, values)
    };

    const onChangeSelectCheckinTime = (values, name) => {
        if (values === undefined) {
            values = null;
        }

        setFieldValue(name, values)
    };

    const changeRadioButtonValue = (value, name, state) => {
        setFieldValue(name, value.target.value);
        state(value.target.value);
    };

    const changeChildCosleepingValue = (value, name, state) => {
        setFieldValue(name, value.target.value);
        state(value.target.value);
        if (activeChildCosleeping == "添い寝可") {
            setFieldValue(`apiRoomOtas[${tabKey}]['fieldContents']['childCosleepingNumber']`, 0);
        }
    };

    const onChangeFilterCondition = (values, name, state) => {
        setFieldValue(name, values)
        state(values)
    };

    const changeTextAreaValue = (value, name, state, maxLength = null) => {
        if (maxLength !== null) {
            const input = value.target.value;
            const customLength = input.length + (input.match(/\n/g)?.length || 0);
            if (customLength <= maxLength) {
                state(input);
                setFieldValue(name, input);
            }
        } else {
            state(value.target.value)
            setFieldValue(name, value.target.value);
        }
    };

    const handleReloadRoom = () => {
        if (apiRoomId) {
            new Promise((resolve, reject) => {
                setLoadingRoom(true)
                dispatch(roomAction.reloadRoom({ apiRoomId, resolve, reject }))
            }).finally(() => {
                setLoadingRoom(false)
            })
        }
    }

    const updateDataFromSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsUpdateDataFromSite(true)
            return dispatch(roomAction.updateDataFromSite({ apiRoomOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.room.modal.updateSuccessTitle"],
                content: messages[`page.room.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.room.modal.inProgressSyncError`]
                : messages[`page.room.modal.updateError`]
            Modal.error({
                title: messages["page.room.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsUpdateDataFromSite(false)
        })
    });

    const syncDataToSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsSyncDataToSite(true)
            return dispatch(roomAction.syncDataToSite({ apiRoomOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.room.modal.updateSuccessTitle"],
                content: messages[`page.room.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.room.modal.inProgressSyncError`]
                : messages[`page.room.modal.updateError`]
            Modal.error({
                title: messages["page.room.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsSyncDataToSite(false)
        })
    });


    return (
        <TabStyles>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={messages["page.room.site.id"]}
                        name={`apiRoomOtas[${tabKey}]['siteRoomId']`}
                    >
                        <Input
                            disabled={true}
                            name={`apiRoomOtas[${tabKey}]['siteRoomId']`}
                        />
                    </Form.Item>
                </Col>
                <Col {...colRight} style={{display: 'flex' , justifyContent: 'end'}}>
                    <Button
                        type="primary"
                        className="mr-10 button"
                        block
                        hidden={apiRoomOtaId === null || apiRoomOtaId === undefined || mode == MODE_SCREEN.COPY}
                        loading={isSyncDataToSite}
                        onClick={syncDataToSite}
                        disabled={disableSyncData}
                    >
                        {messages["page.room.sync"]}
                    </Button>
                    <Button
                        type="primary"
                        className="mr-10 button"
                        block
                        hidden={apiRoomOtaId === null || apiRoomOtaId === undefined || mode == MODE_SCREEN.COPY}
                        loading={isUpdateDataFromSite}
                        onClick={updateDataFromSite}
                    >
                        {messages["page.room.crawlUpdate"]}
                    </Button>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={messages["page.room.ota.syncStatus"]}
                        name={`apiRoomOtas[${tabKey}]['syncStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['syncStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} className={'position-error-message'}>
                    <Row>
                        <Col xs={24} lg={2}>
                            <Button
                                type="primary"
                                className="mr-2"
                                loading={loadingRoom}
                                icon={<ReloadOutlined />}
                                onClick={() => handleReloadRoom()}>
                            </Button>
                        </Col>
                        <Col xs={24} lg={13}>
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['syncErrorContent']`}
                            >
                                <Input
                                    className="styleInputLog"
                                    onClick={() => setShowLog(true)}
                                    value={syncErrorContentFirst}
                                    name={`apiRoomOtas[${tabKey}]['syncErrorContent']`}
                                    readOnly
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={messages["page.room.ota.updateStatus"]}
                        name={`apiRoomOtas[${tabKey}]['updateStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['updateStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColRight}
                        label={messages["page.room.ota.openSaleStatus"]}
                        name={`apiRoomOtas[${tabKey}]['openSaleStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['openSaleStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SALE_PROCESSING.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColRight}
                        label={messages["page.room.ota.openSaleOtaStatus"]}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['openSaleOtaStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['openSaleOtaStatus']`}
                            disabled={true}
                        >
                            {LIST_OPEN_SALE_STATUS.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.ikkyu.roomCode"]}</div>
                                <div>
                                    <span>{messages["page.room.ikkyu.roomCodeNote"]}</span>
                                </div>
                            </div>

                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['roomCode']`}
                        className="custom-label"
                    >
                        <Input
                            maxLength={10}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['roomCode']`}
                        />
                        <div>
                            <span>{messages["page.room.ikkyu.roomCodeNameInputNote"]}</span>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['managerName']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.managementName"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required : true,
                            }
                        )}
                        label={
                            <div className="pb-25px">
                                <div className="custom-required">{messages["page.room.ikkyu.managementName"]}</div>
                                <div>
                                    <span>{messages["page.room.ikkyu.managementNameNote"]}</span>
                                </div>
                            </div>

                        }
                        labelCol={{ lg: 4, xs: 24  }}
                        wrapperCol={{ lg: 18, xs: 24 }}
                    >
                        <Row justify="start">
                            <Col span={8}>
                                <Input
                                    maxLength={10}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['managerName']`}
                                />
                            </Col>
                            <div className="roomName-custom">{messages["page.room.ikkyu.managementNameInputNote"]}</div>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div className="pb-25px">
                                <div className="custom-required">{messages["page.room.ikkyu.roomName"]}</div>
                            </div>

                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['name']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.roomName"],
                            {
                                required: messages["form.error.mixed.required"],
                                valueInvalid: messages["page.room.valueInvalid"]

                            },
                            {
                                required : true,
                                space : true
                            }
                        )}
                        className="custom-label"
                    >
                        <Input
                            maxLength={30}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['name']`}
                        />
                        <p>{messages['page.room.ikkyu.roomNameInputNote']}</p>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.ikkyu.yahooPackRoomName"]}</div>
                            </div>

                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['packageName']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.yahooPackRoomName"],
                            {
                                valueInvalid: messages["page.room.valueInvalid"]
                            },
                            {
                                space: true
                            }
                        )}
                        className="custom-label"
                    >
                        <Input
                            maxLength={30}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['packageName']`}
                        />
                        <div>
                            <span>{messages["page.room.ikkyu.yahooPackRoomNameInputNote3"]}</span>
                        </div>
                        <div>
                            <span>{messages["page.room.ikkyu.yahooPackRoomNameInputNote1"]}</span>
                        </div>
                        <div>
                            <span>{messages["page.room.ikkyu.yahooPackRoomNameInputNote2"]}</span>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        className="custom-label"
                        label={<div className="custom-required">{messages["page.room.ikkyu.roomType"]}</div>}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['roomType']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.roomType"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Select
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['roomType']`}
                            listHeight={320}
                            allowClear
                            showSearch
                            onChange={(value) => onChangeSelectValue(value, `apiRoomOtas[${tabKey}]['fieldContents']['roomType']`)}
                            {...searchSelect}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.roomType) {
                                    return (
                                        <Select.Option key={index} value={option.value}>
                                            {option.value}
                                        </Select.Option>
                                    )
                                }
                            })}
                        </Select>
                        <div>
                            <span>{messages["page.room.ikkyu.roomTypeNote"]}</span>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        className="custom-label"
                        label={<div className="custom-required">{messages["page.room.ikkyu.roomListDisplayCategory"]}</div>}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['roomListDisplayCategory']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.roomListDisplayCategory"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Row justify="start" className="mb-10">
                            <Col {...colFullApi}>
                                <Radio.Group
                                    onChange={(values) => changeRadioButtonValue(values, `apiRoomOtas[${tabKey}]['fieldContents']['roomListDisplayCategory']`, setActiveRoomListDisplayCategory)}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['roomListDisplayCategory']`}
                                    value={activeRoomListDisplayCategory}
                                    style={{ width: '100%' }}
                                >
                                    <Row>
                                        {optionValues.map((option, key) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.roomListDisplayCategory) {
                                                return (
                                                    <Radio
                                                        key={key}
                                                        value={option.value}
                                                        className="mr-1"
                                                    >
                                                        {option.value}
                                                    </Radio>
                                                )
                                            }
                                        })}
                                    </Row>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        className="custom-label"
                        label={<div className="custom-required">{messages["page.room.ikkyu.roomFloorClassification"]}</div>}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['roomFloorClassification']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.roomFloorClassification"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Row justify="start" className="mb-10">
                            <Col {...colFullApi}>
                                <Radio.Group
                                    onChange={(values) => changeRadioButtonValue(values, `apiRoomOtas[${tabKey}]['fieldContents']['roomFloorClassification']`, setActiveRoomFloorClassification)}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['roomFloorClassification']`}
                                    value={activeRoomFloorClassification}
                                    style={{ width: '100%' }}
                                >
                                    <Row>
                                        {optionValues.map((option, key) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.roomFloorClassification) {
                                                return (
                                                    <Radio
                                                        key={key}
                                                        value={option.value}
                                                        className="mr-1"
                                                    >
                                                        {option.value}
                                                    </Radio>
                                                )
                                            }
                                        })}
                                    </Row>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        {...itemLeftLabelFullApi}
                        className="custom-label"
                        label={<div className="custom-required">{messages["page.room.ikkyu.checkinTime"]}</div>}
                        name={`apiRoomOtas[${tabKey}]['fieldContents'][checkinTime]`}
                    >
                        <Row className="mb-25">
                            <Col {...colSingleUnitCustom}>
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['checkinFrom']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.ikkyu.checkinTime"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true
                                        }
                                    )}
                                >
                                    <Select
                                        className="select"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['checkinFrom']`}
                                        onChange={(value) => onChangeSelectCheckinTime(value, `apiRoomOtas[${tabKey}]['fieldContents']['checkinFrom']`)}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.checkinTimeOptions) {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.value}
                                                    </Select.Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <p className="m-2 d-flex mt-7">{messages["page.apiPlanAddAndEdit.Rakuten.specialChar"]}</p>
                            <Col {...colSingleUnitCustom}>
                                <Form.Item
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['checkinTo']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.ikkyu.checkinTime"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true
                                        }
                                    )}
                                >
                                    <Select
                                        className="select"
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['checkinTo']`}
                                        onChange={(value) => onChangeSelectCheckinTime(value, `apiRoomOtas[${tabKey}]['fieldContents']['checkinTo']`)}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.checkinTimeOptions) {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.value}
                                                    </Select.Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        className="custom-label"
                        label={<div className="custom-required">{messages["page.room.ikkyu.checkoutTime"]}</div>}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['checkout']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.checkoutTime"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true,
                            }
                        )}
                    >
                        <Select
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['checkout']`}
                            listHeight={320}
                            allowClear
                            showSearch
                            onChange={(value) => onChangeSelectValue(value, `apiRoomOtas[${tabKey}]['fieldContents']['checkout']`)}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.checkoutTimeOptions) {
                                    return (
                                        <Select.Option
                                            key={index}
                                            value={option.value}
                                        >
                                            {option.value}
                                        </Select.Option>
                                    );
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col
                    span={24}
                >
                    <Form.Item
                        label={
                            <div className="pb-25px">
                                <div className="custom-required">{messages["page.room.ikkyu.capacity"]}</div>
                                <div>
                                    <span>{messages["page.room.ikkyu.capacityNote"]}</span>
                                </div>
                            </div>
                        }
                        name="capacity"
                        labelCol={{xl: 4, lg: 5, xs: 24}}
                        wrapperCol={{xl:20, lg: 20, xs: 24}}
                    >
                        <Row justify="start">
                            <Col>
                                <Form.Item
                                    className="row-reverse mt-5px"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['capacityFrom']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.ikkyu.capacity"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true
                                        }
                                    )}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            min={0}
                                            max={9}
                                            parser={(value) => {
                                                return value.substring(0, 1)
                                            }}
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['capacityFrom']`}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.people"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <p className="m-2 d-flex mt-7">{messages["page.apiPlanAddAndEdit.Rakuten.specialChar"]}</p>
                            <Col>
                                <Form.Item
                                    className="row-reverse mt-5px"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['capacityTo']`}
                                    validate={(value) => ValidateField(
                                        value,
                                        messages["page.room.ikkyu.capacity"],
                                        {
                                            required: messages["form.error.mixed.required"]
                                        },
                                        {
                                            required: true
                                        }
                                    )}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            min={0}
                                            max={9}
                                            parser={(value) => {
                                                return value.substring(0, 1)
                                            }}
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['capacityTo']`}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.people"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <p className="m-2 d-flex mt-7">{messages["page.room.ikkyu.capacityInputNote"]}</p>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col
                    span={24}
                >
                    <Form.Item
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.ikkyu.numberOfSquareMeters"]}</div>
                                <div>
                                    <span
                                        className="text-note-row">{messages["page.room.ikkyu.numberOfSquareMetersNote"]}</span>
                                </div>
                            </div>
                        }
                        name="square"

                        labelCol={{xl: 4, lg: 5, xs: 24}}
                        wrapperCol={{xl: 20, lg: 20, xs: 24}}
                    >
                        <Row justify="start">
                            <Col>
                                <Form.Item
                                    className="row-reverse mt-5px"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['squareFrom']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            min={0}
                                            max={999999}
                                            parser={(value) => {
                                                return value.substring(0, 6)
                                            }}
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['squareFrom']`}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.squareMeters"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <p className="m-2 d-flex mt-7">{messages["page.apiPlanAddAndEdit.Rakuten.specialChar"]}</p>
                            <Col>
                                <Form.Item
                                    className="row-reverse mt-5px"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['squareTo']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            min={0}
                                            max={999999}
                                            parser={(value) => {
                                                return value.substring(0, 6)
                                            }}
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['squareTo']`}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.squareMeters"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <p className="m-2 d-flex mt-7">{messages["page.room.ikkyu.squareMetersInputNote"]}</p>
                        </Row>
                        <p className="d-flex">{messages["page.room.ikkyu.squareMetersInputNote2"]}</p>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div>{messages["page.room.ikkyu.numberOfTatamiMats"]}</div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['roomSizeLayout']`}
                        className="custom-label"
                    >
                        <Input
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['roomSizeLayout']`}
                            maxLength={40}
                        />
                        <p>{messages['page.room.ikkyu.numberOfTatamiMatsInputNote']}</p>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.ikkyu.roomPR"]}</div>
                                <div>
                                    <span>{messages["page.room.ikkyu.roomPRNote"]}</span>
                                </div>
                            </div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['roomPR']`}
                        className="custom-label"
                    >
                        <Textarea
                            onChange={(values) => changeTextAreaValue(values, `apiRoomOtas[${tabKey}]['fieldContents']['roomPR']`, setRoomPRValue, 650)}
                            value={roomPRValue}
                            maxLength={650}
                            type="textarea"
                            rows={5}
                        />
                        <p>{messages["page.room.ikkyu.roomPRNote2"]}</p>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.ikkyu.yahooPackRoomPR"]}</div>
                                <div>
                                    <span>{messages["page.room.ikkyu.yahooPackRoomPRNote"]}</span>
                                </div>
                            </div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['packageRoomPR']`}
                        className="custom-label"
                    >
                        <Textarea
                            onChange={(values) => changeTextAreaValue(values, `apiRoomOtas[${tabKey}]['fieldContents']['packageRoomPR']`, setPackageRoomPRValue, 650)}
                            value={packageRoomPRValue}
                            maxLength={650}
                            type="textarea"
                            rows={5}
                        />
                        <p>{messages["page.room.ikkyu.yahooPackRoomPRNote2"]}</p>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col
                    span={24}
                >
                    <Form.Item
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.ikkyu.numberOfFloor"]}</div>
                                <div>
                                    <span>{messages["page.room.ikkyu.numberOfFloorNote"]}</span>
                                </div>
                            </div>
                        }
                        name="numberOfFloor"
                        labelCol={{xl: 4, lg: 5, xs: 24}}
                        wrapperCol={{xl:20, lg: 20, xs: 24}}
                    >
                        <Row justify="start">
                            <Col>
                                <Form.Item
                                    className="row-reverse mt-5px"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['numberOfFloorFrom']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            min={0}
                                            max={255}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['numberOfFloorFrom']`}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.floor"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <p className="m-2 d-flex mt-7">{messages["page.apiPlanAddAndEdit.Rakuten.specialChar"]}</p>
                            <Col>
                                <Form.Item
                                    className="row-reverse mt-5px"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['numberOfFloorTo']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            min={0}
                                            max={255}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['numberOfFloorTo']`}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.floor"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <p className="m-2 d-flex mt-7">{messages["page.room.ikkyu.numberOfFloorInputNote"]}</p>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1']`}
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.ikkyu.regularBed1"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.room.ikkyu.regularBedNote"]}</span>
                                </div>
                            </div>
                        }
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 24, xs: 24 }}
                    >
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.width"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1Width']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1Width']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.length"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1Length']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1Length']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.height"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1Height']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1Height']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    labelCol={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0, }}
                                    wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, }}
                                    className="mr-1"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1Person']`}
                                >
                                    <div>
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1Person']`}
                                            min={0}
                                            max={9}
                                            parser={(value) => {
                                                return value.substring(0, 1)
                                            }}
                                            style={{float: "left"}}
                                        />
                                        <span style={{paddingTop: "5px", marginTop: "0px"}} className="item-center">
                                            {messages["page.room.ikkyu.regularBedInputNote"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1Unit']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed1Unit']`}
                                            min={0}
                                            max={9}
                                            parser={(value) => {
                                                return value.substring(0, 1)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.stand"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2']`}
                        label={messages["page.room.ikkyu.regularBed2"]}
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 24, xs: 24 }}
                    >
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.width"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2Width']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2Width']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.length"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2Length']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2Length']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.height"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2Height']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2Height']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    labelCol={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0, }}
                                    wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, }}
                                    className="mr-1"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2Person']`}
                                >
                                    <div>
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2Person']`}
                                            min={0}
                                            max={9}
                                            parser={(value) => {
                                                return value.substring(0, 1)
                                            }}
                                            style={{float: "left"}}
                                        />
                                        <span style={{paddingTop: "5px", marginTop: "0px"}} className="item-center">
                                            {messages["page.room.ikkyu.regularBedInputNote"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2Unit']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed2Unit']`}
                                            min={0}
                                            max={9}
                                            parser={(value) => {
                                                return value.substring(0, 1)
                                            }}
                                            style={{float: "left"}}
                                        />
                                        <span style={{paddingTop: "5px", marginTop: "0px"}} className="item-center">
                                            {messages["page.room.ikkyu.stand"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3']`}
                        label={messages["page.room.ikkyu.regularBed3"]}
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 24, xs: 24 }}
                    >
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.width"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3Width']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3Width']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.length"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3Length']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3Length']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.height"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3Height']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3Height']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    labelCol={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0, }}
                                    wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, }}
                                    className="mr-1"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3Person']`}
                                >
                                    <div>
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3Person']`}
                                            min={0}
                                            max={9}
                                            parser={(value) => {
                                                return value.substring(0, 1)
                                            }}
                                            style={{float: "left"}}
                                        />
                                        <span style={{paddingTop: "5px", marginTop: "0px"}} className="item-center">
                                            {messages["page.room.ikkyu.regularBedInputNote"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3Unit']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed3Unit']`}
                                            min={0}
                                            max={9}
                                            parser={(value) => {
                                                return value.substring(0, 1)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.stand"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4']`}
                        label={messages["page.room.ikkyu.regularBed4"]}
                        labelCol={{ lg: 4, xs: 24 }}
                        wrapperCol={{ lg: 24, xs: 24 }}
                    >
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.width"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4Width']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4Width']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                            style={{float: "left"}}
                                        />
                                        <span style={{paddingTop: "5px", marginTop: "0px"}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.length"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4Length']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4Length']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    label={<div>{messages["page.room.ikkyu.height"]}</div>}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4Height']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4Height']`}
                                            min={0}
                                            max={999}
                                            parser={(value) => {
                                                return value.substring(0, 3)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.cm"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    labelCol={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0, }}
                                    wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, }}
                                    className="mr-1"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4Person']`}
                                >
                                    <div>
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4Person']`}
                                            min={0}
                                            max={9}
                                            parser={(value) => {
                                                return value.substring(0, 1)
                                            }}
                                            style={{float: "left"}}
                                        />
                                        <span style={{paddingTop: "5px", marginTop: "0px"}} className="item-center">
                                            {messages["page.room.ikkyu.regularBedInputNote"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    {...colInputWithLabelIkyyuRoom}
                                    className="mr-1"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4Unit']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['regularBed4Unit']`}
                                            min={0}
                                            max={9}
                                            parser={(value) => {
                                                return value.substring(0, 1)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.stand"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col
                    span={24}
                >
                    <Form.Item
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.ikkyu.numberOfAdditionalBeds"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.room.ikkyu.numberOfAdditionalBedNote"]}</span>
                                </div>
                            </div>
                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['numberOfAdditionalBed']`}
                        labelCol={{xl: 4, lg: 5, xs: 24}}
                        wrapperCol={{xl:20, lg: 20, xs: 24}}
                    >
                        <Row justify="start">
                            <Col>
                                <div className="d-flex align-items-center">
                                    <InputNumber
                                        name={`apiRoomOtas[${tabKey}]['fieldContents']['numberOfAdditionalBed']`}
                                        min={0}
                                        max={9}
                                        parser={(value) => {
                                            return value.substring(0, 1)
                                        }}
                                    />
                                    <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.stand"]}
                                        </span>
                                </div>
                            </Col>
                            <p className="m-2 d-flex mt-7">{messages["page.room.ikkyu.numberOfAdditionalBedInputNote"]}</p>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['additionalBedType']`}
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.ikkyu.additionalBedTypes"]}</div>
                                <div>
                                    <span className="text-note-row">{messages["page.room.ikkyu.additionalBedTypesNote"]}</span>
                                </div>
                            </div>
                        }
                        labelCol={{xl: 4, lg: 4, xs: 24}}
                        wrapperCol={{xl:11, lg: 11, xs: 24}}
                    >
                        <Row justify="start">
                            <Col span={13}>
                                <Input
                                    maxLength={40}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['additionalBedType']`}
                                />
                            </Col>
                            <div className="roomName-custom">{messages["page.room.ikkyu.additionalBedTypesInputNote"]}</div>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start">
                <Col span={24}>
                    <Form.Item
                        labelCol={{xl: 4, lg: 4, xs: 24}}
                        wrapperCol={{xl: 14, lg: 14, xs: 24}}
                        className="custom-label"
                        label={<div className="custom-required">{messages["page.room.ikkyu.childCosleeping"]}</div>}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['childCosleeping']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.childCosleeping"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Row justify="start" className="mb-10">
                            <Col {...colFullApi}>
                                <Radio.Group
                                    onChange={(values) => changeChildCosleepingValue(values, `apiRoomOtas[${tabKey}]['fieldContents']['childCosleeping']`, setActiveChildCosleeping)}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['childCosleeping']`}
                                    value={activeChildCosleeping}
                                    style={{ width: '100%' }}
                                >
                                    <Row>
                                        {optionValues.map((option, key) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.childCosleeping) {
                                                return (
                                                    <Radio
                                                        key={key}
                                                        value={option.value}
                                                        className="mr-1"
                                                    >
                                                        {option.value}
                                                    </Radio>
                                                )
                                            }
                                        })}
                                    </Row>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        labelCol={{xl: 4, lg: 4, xs: 24}}
                        wrapperCol={{xl: 14, lg: 14, xs: 24}}
                        className="custom-label"
                        label=" "
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['childCosleepingNumber']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.person"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Row justify="start">
                            <Col span={3}>
                                <InputNumber
                                    disabled={activeChildCosleeping == "添い寝可" ? false : true}
                                    min={0}
                                    max={9}
                                    parser={(value) => {
                                        return value.substring(0, 1)
                                    }}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['childCosleepingNumber']`}
                                />
                            </Col>
                            <div
                                className="roomName-custom">{messages["page.room.ikkyu.childCosleepingInputNote"]}</div>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        className="custom-label"
                        label={<div className="custom-required">{messages["page.room.ikkyu.viewAmenities"]}</div>}
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['amenityDisplay']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.viewAmenities"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                    >
                        <Row justify="start" className="mb-10">
                            <Col {...colFullApi}>
                                <Radio.Group
                                    onChange={(values) => changeRadioButtonValue(values, `apiRoomOtas[${tabKey}]['fieldContents']['amenityDisplay']`, setActiveAmenityDisplay)}
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['amenityDisplay']`}
                                    value={activeAmenityDisplay}
                                    style={{ width: '100%' }}
                                >
                                    <Row>
                                        {optionValues.map((option, key) => {
                                            if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.amenityDisplay) {
                                                return (
                                                    <Radio
                                                        key={key}
                                                        value={option.value}
                                                        className="mr-1"
                                                    >
                                                        {option.value}
                                                    </Radio>
                                                )
                                            }
                                        })}
                                    </Row>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col  {...colFull}>
                    <Form.Item
                        label={
                            <div>
                                <div>{messages["page.room.ikkyu.amenityEquipment"]}</div>
                                <div>{messages["page.room.ikkyu.amenityEquipmentNote"]}</div>
                            </div>

                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['amenityEquipment']`}
                        {...customCheckboxGroupFullRow}
                    >
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['amenityEquipment']`}
                            value={amenityEquipment}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas[${tabKey}]['fieldContents']['amenityEquipment']`, setAmenityEquipment)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.amenityEquipment) {
                                        return (
                                            <Col span={8} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col  {...colFull}>
                    <Form.Item
                        label={
                            <div>
                                <div>{messages["page.room.ikkyu.roomAttribute"]}</div>
                                <div>{messages["page.room.ikkyu.roomAttributeNote"]}</div>
                            </div>

                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['roomAttribute']`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.ikkyu.roomAttribute"],
                            {
                                required: messages["form.error.mixed.required"]
                            },
                            {
                                required: true
                            }
                        )}
                        {...customCheckboxGroupFullRow}
                    >
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['roomAttribute']`}
                            value={roomAttribute}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas[${tabKey}]['fieldContents']['roomAttribute']`, setRoomAttribute)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.roomAttribute) {
                                        return (
                                            <Col span={8} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col  {...colFull}>
                    <Form.Item
                        label={
                            <div>
                                <div>{messages["page.room.ikkyu.serviceAttribute"]}</div>
                                <div>{messages["page.room.ikkyu.serviceAttributeNote"]}</div>
                            </div>

                        }
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['serviceAttribute']`}
                        {...customCheckboxGroupFullRow}
                    >
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            name={`apiRoomOtas[${tabKey}]['fieldContents']['serviceAttribute']`}
                            value={serviceAttribute}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas[${tabKey}]['fieldContents']['serviceAttribute']`, setServiceAttribute)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyIkkyuApiRoomTypeOption.serviceAttribute) {
                                        return (
                                            <Col span={8} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colFullApi}>
                    <Form.Item
                        name="lastRenewalDate"
                        label={
                            <div>
                                <div>{messages["page.room.ikkyu.lastRenewalDate"]}</div>
                                <div>{messages["page.room.ikkyu.lastRenewalDateNote"]}</div>
                            </div>

                        }
                        {...itemLeftLabelFullApi}
                    >
                        <Row justify="start" className="mb-25">
                            <Col {...colInputApi}>
                                <Form.Item
                                    labelCol={0}
                                    wrapperCol={5}
                                    className="mr-2"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['lastRenewalYear']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['lastRenewalYear']`}
                                            min={0}
                                            max={9999}
                                            parser={(value) => {
                                                return value.substring(0, 4)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.year"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    labelCol={0}
                                    wrapperCol={5}
                                    className="mr-2"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['lastRenewalMonth']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['lastRenewalMonth']`}
                                            min={0}
                                            max={12}
                                            parser={(value) => {
                                                return value.substring(0, 2)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.month"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col {...colInputApi}>
                                <Form.Item
                                    labelCol={0}
                                    wrapperCol={5}
                                    className="mr-2"
                                    name={`apiRoomOtas[${tabKey}]['fieldContents']['lastRenewalDay']`}
                                >
                                    <div className="d-flex align-items-center">
                                        <InputNumber
                                            name={`apiRoomOtas[${tabKey}]['fieldContents']['lastRenewalDay']`}
                                            min={0}
                                            max={31}
                                            parser={(value) => {
                                                return value.substring(0, 2)
                                            }}
                                        />
                                        <span style={{marginLeft: "5px", whiteSpace: 'nowrap'}} className="item-center">
                                            {messages["page.room.ikkyu.day"]}
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        name={`apiRoomOtas[${tabKey}]['fieldContents']['residentialAccommodationBusinessLawNumber']`}
                        label={
                            <div>{messages["page.room.ikkyu.privateLodging"]}</div>
                        }
                        {...inputColLeft}
                    >
                        <Row justify="start">
                            <Input
                                disabled={true}
                                name={`apiRoomOtas[${tabKey}]['fieldContents']['residentialAccommodationBusinessLawNumber']`}
                            />
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Modal
                title={messages["page.crawlRoom.titleLog"]}
                style={{ top: 20 }}
                width={700}
                visible={showLog}
                footer={null}
                onCancel={() => setShowLog(false)}
            >
                <Row style={{maxHeight: '80vh', overflowY: 'auto', display: 'block'}}>
                    {syncErrorContent != null && syncErrorContent.length > 0 && syncErrorContent.map((x, index) => (
                        <Row style={{marginTop: '10px' }}>
                            <p key={index}>{x}</p>
                        </Row>
                    ))}
                </Row>
                {syncErrorContent == null || syncErrorContent === '' || syncErrorContent.length <= 0 && (
                    <Row  style={{ padding: '10px', justifyContent: 'center' }}>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>{messages["antTable.emptyData"]}</span>
                            }
                        >
                        </Empty>
                    </Row>
                )}
            </Modal>
        </TabStyles>
    )
}

export const colSingleUnitCustom = {
    xs: { span: 14 },
    md: { span: 5},
    lg: { span: 3},
    xl: { span: 3},
};

const colInputWithLabelIkyyuRoom = {
    labelCol: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6, },
    wrapperCol: { xs: 24, sm: 24, md: 24, lg: 14, xl: 15, },
}

const colInputApi = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 4, offset: 0 },
    xl: { span: 4, offset: 0 },
    xxl: { span: 4, offset: 0 },
}

const ikyyuInitialValue = {
    roomCode: null,
    room_cd: null,
    managerName: null,
    name: null,
    packageName: null,
    roomType: null,
    roomListDisplayCategory: null,
    roomFloorClassification: null,
    checkinFrom: "15:00",
    checkinTo: "24:00",
    checkout: "10:00",
    capacityFrom: null,
    capacityTo: null,
    squareFrom: null,
    squareTo: null,
    roomSizeLayout: null,
    roomPR: null,
    packageRoomPR: null,
    numberOfFloorFrom: null,
    numberOfFloorTo: null,
    regularBed1Width: 0,
    regularBed1Length: 0,
    regularBed1Height: 0,
    regularBed1Person: 0,
    regularBed1Unit: 0,
    regularBed2Width: 0,
    regularBed2Length: 0,
    regularBed2Height: 0,
    regularBed2Person: 0,
    regularBed2Unit: 0,
    regularBed3Width: 0,
    regularBed3Length: 0,
    regularBed3Height: 0,
    regularBed3Person: 0,
    regularBed3Unit: 0,
    regularBed4Width: 0,
    regularBed4Length: 0,
    regularBed4Height: 0,
    regularBed4Person: 0,
    regularBed4Unit: 0,
    numberOfAdditionalBed: null,
    additionalBedType: null,
    childCosleeping: null,
    childCosleepingNumber: 0,
    amenityDisplay: null,
    amenityEquipment: [],
    roomAttribute: [],
    serviceAttribute: [],
    lastRenewalYear: null,
    lastRenewalMonth: null,
    lastRenewalDay: null,
    residentialAccommodationBusinessLawNumber: null,
}

export default memo(IkkyuTab);
