const actions = {
  GET_STORE_DETAIL_REQUEST: "STORE/GET_STORE_DETAIL_REQUEST",
  GET_STORE_DETAIL_SUCCESS: "STORE/GET_STORE_DETAIL_SUCCESS",
  GET_STORE_DETAIL_ERROR: "STORE/GET_STORE_DETAIL_ERROR",

  GET_STORE_METADATA_REQUEST: "STORE/GET_STORE_METADATA_REQUEST",
  GET_STORE_METADATA_SUCCESS: "STORE/GET_STORE_METADATA_SUCCESS",
  GET_STORE_METADATA_ERROR: "STORE/GET_STORE_METADATA_ERROR",

  ADD_STORE_REQUEST: "STORE/ADD_STORE_REQUEST",
  ADD_STORE_SUCCESS: "STORE/ADD_STORE_SUCCESS",
  ADD_STORE_ERROR: "STORE/ADD_STORE_ERROR",

  EDIT_STORE_REQUEST: "STORE/EDIT_STORE_REQUEST",
  EDIT_STORE_SUCCESS: "STORE/EDIT_STORE_SUCCESS",
  EDIT_STORE_ERROR: "STORE/EDIT_STORE_ERROR",

  RESET_STORE_ADD_EDIT_STORE: "STORE/RESET_STORE_ADD_EDIT_STORE",

  UPDATE_EXPIRED_REQUEST: "STORE/UPDATE_EXPIRED_REQUEST",
  UPDATE_EXPIRED_SUCCESS: "STORE/UPDATE_EXPIRED_SUCCESS",
  UPDATE_EXPIRED_ERROR: "STORE/UPDATE_EXPIRED_ERROR",

  DELETE_STORE_REQUEST: "STORE/DELETE_STORE_REQUEST",
  DELETE_STORE_SUCCESS: "STORE/DELETE_STORE_SUCCESS",
  DELETE_STORE_ERROR: "STORE/DELETE_STORE_ERROR",

  GET_ACCOUNT_BY_STORE_REQUEST: "STORE/GET_ACCOUNT_BY_STORE_REQUEST",
  GET_ACCOUNT_BY_STORE_SUCCESS: "STORE/GET_ACCOUNT_BY_STORE_SUCCESS",
  GET_ACCOUNT_BY_STORE_ERROR: "STORE/GET_ACCOUNT_BY_STORE_ERROR",

  getStoreDetail: (payload) => ({
    type: actions.GET_STORE_DETAIL_REQUEST,
    payload,
  }),

  getStoreMetadata: (payload) => ({
    type: actions.GET_STORE_METADATA_REQUEST,
    payload: payload,
  }),

  createStore: (payload) => ({
    type: actions.ADD_STORE_REQUEST,
    payload: payload,
  }),

  editStore: (payload) => ({
    type: actions.EDIT_STORE_REQUEST,
    payload: payload,
  }),

  updateExpired: (payload) => ({
    type: actions.UPDATE_EXPIRED_REQUEST,
    payload,
  }),

  deleteStore: (payload) => ({
    type: actions.DELETE_STORE_REQUEST,
    payload: payload
  }),

  getAccountByStore: (payload) => ({
    type: actions.GET_ACCOUNT_BY_STORE_REQUEST,
    payload: payload
  }),
};

export default actions;
