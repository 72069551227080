import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";
export default class CrawlRankingAndScoreService extends BaseService {
    crawlRankingAndScore = (data) => {
        return this.axios.post(API_ENDPOINTS.CRAWL_RANKING_AND_SCORE, data);
    }
    getCrawlRankingAndScore = () => {
        return this.axios.get(API_ENDPOINTS.GET_CRAWL_RANKING_AND_SCORE);
    }
    deleteInformationStore = (data) => {
        return this.axios.get(API_ENDPOINTS.DELETE_INFORMATION_STORES);
    }
    getInformationStore = () => {
        return this.axios.get(API_ENDPOINTS.GET_INFORMATION_STORES);
    }
}