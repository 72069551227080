import { all, takeLatest, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import { crawlRankingAndScoreService} from "@iso/services";
export function* crawlRankingAndScore() {
    yield takeLatest(actions.CRAWL_RANKING_AND_SCORE_REQUEST, function* ({ payload }) {
        const {
            aggregationDate,
            otas,
            resolve,
            reject
        } = payload;
        try {
            const response = yield call(crawlRankingAndScoreService.crawlRankingAndScore, {
                aggregationDate,
                otas,
                resolve,
                reject
            });
            yield put({
                type: actions.CRAWL_RANKING_AND_SCORE_SUCCESS,
                crawler: response,
            });
            yield resolve(response);
        } catch (e) {
            yield put({ type: actions.CRAWL_RANKING_AND_SCORE_ERROR });
            reject(e);
        }
    });
}
export function* getCrawlRankingAndScore() {
    yield takeLatest(actions.GET_CRAWL_RANKING_AND_SCORE_REQUEST, function* () {
        try {
            const report = yield call(crawlRankingAndScoreService.getCrawlRankingAndScore);
            yield put({
                type: actions.GET_CRAWL_RANKING_AND_SCORE_SUCCESS,
                crawler: report,
            });
        } catch (error) {
            yield put({
                type: actions.GET_CRAWL_RANKING_AND_SCORE_ERROR,
                error: error.response,
            });
        }
    });
}
export function* deleteInformationStore() {
    yield takeLatest(actions.DELETE_INFORMATION_STORE_REQUEST, function* ({ payload }) {
        const {
            resolve,
            reject
        } = payload;
        try {
            const response = yield call(crawlRankingAndScoreService.deleteInformationStore, {
                resolve,
                reject
            });
            yield put({
                type: actions.DELETE_INFORMATION_STORE_SUCCESS,
            });
            yield resolve(response);
        } catch (error) {
            yield put({
                type: actions.DELETE_INFORMATION_STORE_ERROR,
            });
            reject(error);
        }
    });
}
export function* getInformationStore() {
    yield takeLatest(actions.GET_INFORMATION_STORE_REQUEST, function* () {
        try {
            const response = yield call(crawlRankingAndScoreService.getInformationStore);
            yield put({
                type: actions.GET_INFORMATION_STORE_SUCCESS,
                isExistFile: response,
            });
        } catch (error) {
            yield put({
                type: actions.GET_INFORMATION_STORE_ERROR,
                isExistFile: false,
            });
        }
    });
}
export default function* rootSaga() {
    yield all([fork(crawlRankingAndScore)]);
    yield all([fork(getCrawlRankingAndScore)]);
    yield all([fork(deleteInformationStore)]);
    yield all([fork(getInformationStore)]);
}