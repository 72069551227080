import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

import BaseService from "./base.service";

export default class TeamService extends BaseService {
    getTeams = (data) => {
        return this.axios.post(API_ENDPOINTS.TEAM_SEARCH, data);
    };

    createTeam = (data) => {
        return this.axios.post(API_ENDPOINTS.CREATE_TEAM, data);
    };
    
    updateTeam = (data) => {
        return this.axios.post(API_ENDPOINTS.UPDATE_TEAM.replace("{teamId}", data.teamId), data);
    };

    deleteTeam = (teamId) => {
        return this.axios.post(API_ENDPOINTS.DELETE_TEAM.replace("{teamId}", teamId));
    };
    
    fetchTeam = ({ teamId }) => {
        return this.axios.get(API_ENDPOINTS.DETAIL_TEAM.replace("{teamId}", teamId));
    };

    getParentTeams = () => {
        return this.axios.post(API_ENDPOINTS.GET_PARENT_TEAMS);
    };

    getTeamOptions = () => {
        return this.axios.post(API_ENDPOINTS.GET_TEAM_OPTIONS);
    };

    getListPersonInCharge = () => {
        return this.axios.post(API_ENDPOINTS.GET_PERSON_IN_CHARGE);
    };
}
