import React, {memo, useState, useEffect, useCallback} from "react";
import { TabStyles } from "./TabStyle";
import {Col, Row, Button, Radio, Checkbox, Modal, Empty, Table, Collapse} from "antd";
import {
    colFullApi, colLeftApi,
    colSingleUnit,
    inputColLeft, inputColRight, itemLeftLabelApi,
    itemLeftLabelFullApi
} from "@iso/assets/styles/form.style";
import {colRight} from "@iso/containers/TaskLog";
import {Form, Input, InputNumber, Select} from "formik-antd";
import {ValidateField} from "@iso/validates/ValidateField";
import {useIntl} from "react-intl";
import {
    ERROR_STATUS,
    fieldKeyRurubuApiRoomTypeOption,
    LIST_OPEN_SALE_STATUS,
    LIST_STATUS_SALE_PROCESSING,
    LIST_STATUS_SYNC,
    searchSelect,
    TYPE_ROOM_CRAWL,
    TYPE_ROOM_FORM,
    typeStatusCrawlOption
} from "@iso/constants/api.constant";
import {useDispatch} from "react-redux";
import _, {isEmpty} from "lodash";
import {Textarea} from "@iso/components/uielements/input";
import {ReloadOutlined} from "@ant-design/icons";
import {MODE_SCREEN} from "@iso/constants/common.constant";
import roomAction from "@iso/redux/apiRoom/actions";

const RurubuTab = ({
    values,
    dataOta,
    setFieldValue,
    tabKey,
    apiRoomId,
    apiOptionValues,
    dataRoom,
    mode,
    setValues
}) => {
    const { Panel } = Collapse;
    const { messages } = useIntl();
    const dispatch = useDispatch();
    const [optionValues, setOptionValues] = useState([]);
    const [roomView, setRoomView] = useState([]);
    const [bathAmenities, setBathAmenities] = useState([]);
    const [entertainmentAmenities, setEntertainmentAmenities] = useState([]);
    const [layoutAmenities, setLayoutAmenities] = useState([]);
    const [safetyAmenities, setSafetyAmenities] = useState([]);
    const [access, setAccess] = useState([]);
    const [comfortAmenities, setComfortAmenities] = useState([]);
    const [clothingAmenities, setClothingAmenities] = useState([]);
    const [diningAmenities, setDiningAmenities] = useState([]);
    const [accessibility, setAccessibility] = useState([]);
    const [activeRoomType, setActiveRoomType] = useState(null);
    const [roomDescriptionValue, setRoomDescriptionValue] = useState(null);
    const [loadingRoom, setLoadingRoom] = useState(false);
    const [syncErrorContentFirst, setSyncErrorContentFirst] = useState(null);
    const [syncErrorContent, setSyncErrorContent] = useState([])
    const [isUpdateDataFromSite, setIsUpdateDataFromSite] = useState(false);
    const [isSyncDataToSite, setIsSyncDataToSite] = useState(false);
    const [disableSyncData, setDisableSyncData] = useState(true);
    const [apiRoomOtaId, setApiRoomOtaId] = useState(null);
    const [showLog, setShowLog] = useState(false);
    const [disabledSelectBedrooms, setDisabledSelectBedrooms] = useState(true);
    const [showBeddingSetting, setShowBeddingSetting] = useState("0");
    const [listRoom, setListRoom] = useState([
        [
            [
                {
                    beddingType: null,
                    numberOfBedding: null
                }
            ]
        ]
    ]);

    useEffect(() => {
        if (apiRoomId
            && !isEmpty(dataRoom.apiRoomOtas[tabKey])
            && (dataRoom.apiRoomOtas[tabKey].typeRoom == TYPE_ROOM_FORM
                || (dataRoom.apiRoomOtas[tabKey].typeRoom == TYPE_ROOM_CRAWL
                    && dataRoom.apiRoomOtas[tabKey].updateStatus == typeStatusCrawlOption.crawlSuccess
                ))
        ) {
            setDisableSyncData(false)
        } else {
            setDisableSyncData(true)
        }
    }, [dataRoom])

    useEffect(() => {
        setFieldValue(`apiRoomOtas.${tabKey}.otaId`, dataOta.otaId)
        setActiveRoomType(rurubuInitialValue.roomType.toString());
        if (apiRoomId && !isEmpty(values) && !isEmpty(values.apiRoomOtas[tabKey]) && !isEmpty(values.apiRoomOtas[tabKey].fieldContents)) {
            const roomView = values.apiRoomOtas[tabKey]?.fieldContents?.roomView;
            setRoomView(Array.isArray(roomView) ? roomView : []);
            const bathAmenities = values.apiRoomOtas[tabKey]?.fieldContents?.bathAmenities;
            setBathAmenities(Array.isArray(bathAmenities) ? bathAmenities : []);
            const entertainmentAmenities = values.apiRoomOtas[tabKey]?.fieldContents?.entertainmentAmenities;
            setEntertainmentAmenities(Array.isArray(entertainmentAmenities) ? entertainmentAmenities : []);
            const layoutAmenities = values.apiRoomOtas[tabKey]?.fieldContents?.layoutAmenities;
            setLayoutAmenities(Array.isArray(layoutAmenities) ? layoutAmenities : []);
            const safetyAmenities = values.apiRoomOtas[tabKey]?.fieldContents?.safetyAmenities;
            setSafetyAmenities(Array.isArray(safetyAmenities) ? safetyAmenities : []);
            const access = values.apiRoomOtas[tabKey]?.fieldContents?.access;
            setAccess(Array.isArray(access) ? access : []);
            const comfortAmenities = values.apiRoomOtas[tabKey]?.fieldContents?.comfortAmenities;
            setComfortAmenities(Array.isArray(comfortAmenities) ? comfortAmenities : []);
            const clothingAmenities = values.apiRoomOtas[tabKey]?.fieldContents?.clothingAmenities;
            setClothingAmenities(Array.isArray(clothingAmenities) ? clothingAmenities : []);
            const diningAmenities = values.apiRoomOtas[tabKey]?.fieldContents?.diningAmenities;
            setDiningAmenities(Array.isArray(diningAmenities) ? diningAmenities : []);
            const accessibility = values.apiRoomOtas[tabKey]?.fieldContents?.accessibility;
            setAccessibility(Array.isArray(accessibility) ? accessibility : []);
            setRoomDescriptionValue(values.apiRoomOtas[tabKey].fieldContents.roomDescription)
            const syncErrorFirst = getSyncError(values);
            setActiveRoomType(values.apiRoomOtas[tabKey]?.fieldContents?.roomType?.toString() ?? "1");
            setListRoom(values.apiRoomOtas[tabKey].fieldContents?.listRoomValue ?? [])
            setSyncErrorContentFirst(syncErrorFirst[0])
            const filteredArray = syncErrorFirst.filter(item => item.trim() !== '');
            setSyncErrorContent(filteredArray)
            setApiRoomOtaId(values.apiRoomOtas[tabKey]?.apiRoomOtaId);
            setShowBeddingSetting(values.apiRoomOtas[tabKey]?.fieldContents.showBeddingSetting ?? []);
            if (mode == MODE_SCREEN.COPY) {
                setSyncErrorContentFirst(null);
                setFieldValue(`apiRoomOtas[${tabKey}]['syncStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['updateStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['syncErrorContent']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}]['siteRoomId']`, null)
                setFieldValue(`apiRoomOtas[${tabKey}]['openSaleStatus']`, null);
                setFieldValue(`apiRoomOtas[${tabKey}][fieldContents]['openSaleOtaStatus']`, null);
            }
        } else {
            if (!apiRoomId || Array.isArray(values.otaId) && values.otaId.includes(tabKey)) {
                setFieldValue(`apiRoomOtas[${tabKey}].fieldContents`, rurubuInitialValue);
            }
        }
        if (!isEmpty(apiOptionValues)) {
            apiOptionValues.map((apiOptionValue) => {
                if (apiOptionValue.otaId == dataOta.otaId) {
                    setOptionValues(apiOptionValue.options)
                }
            })
        }
    }, [apiOptionValues, dataOta]);

    useEffect(() => {
        if (activeRoomType === messages['page.room.rurubu.roomTypeOneRoom'] || activeRoomType === null || activeRoomType === undefined || activeRoomType.length === 0) {
            setDisabledSelectBedrooms(true);
        } else {
            setDisabledSelectBedrooms(false);
        }
    }, [activeRoomType])

    const getSyncError = (values) => {
        const syncErrorContent = values.apiRoomOtas[tabKey]?.syncErrorContent || '';
        const syncErrorArray = syncErrorContent.split("\n").map(item => item.trim());
        return syncErrorArray || [];
    }

    const onChangeSelectValue = (values, name) => {
        if (values === undefined) {
            values = null;
        }

        setFieldValue(name, values)
    };

    const onChangeSelectBedroomsValue = (values, name) => {
        if (values === undefined) {
            values = null;
        }

        addAndRemoveBedroom(values);
        setFieldValue(name, values)
    };

    const changeRadioButtonValue = (value, name, state) => {
        setFieldValue(name, value.target.value);
        state(value.target.value);
    };

    const changeRoomTypeValue = (value, name, state) => {
        let temp = [...listRoom];
        if (value.target.value === messages['page.room.rurubu.roomTypeOneRoom']) {
            let listRoomDefault = temp.shift();
            setListRoom([listRoomDefault]);
            setFieldValue(`apiRoomOtas[${tabKey}].fieldContents.listRoomValue`, [listRoomDefault])
            setFieldValue(`apiRoomOtas.${tabKey}.fieldContents.selectBedrooms`, 1)
        } else if (value.target.value === messages['page.room.rurubu.roomTypeMultiRoom']) {
            let numberOfBedroom = values.apiRoomOtas[tabKey]?.fieldContents?.selectBedrooms ?? 0
            addAndRemoveBedroom(numberOfBedroom);
        }
        setFieldValue(name, value.target.value);
        state(value.target.value);
    };

    const onChangeFilterCondition = (values, name, state) => {
        setFieldValue(name, values)
        state(values)
    };

    const changeTextAreaValue = (value, name, state, maxLength = null) => {
        state(value.target.value)
        setFieldValue(name, value.target.value);
    };

    const handleShowBeddingSetting = (keys) => {
        setShowBeddingSetting(keys);
        setFieldValue(`apiRoomOtas.${tabKey}.fieldContents.showBeddingSetting`, keys)
    };

    const handleReloadRoom = () => {
        if (apiRoomId) {
            new Promise((resolve, reject) => {
                setLoadingRoom(true)
                dispatch(roomAction.reloadRoom({ apiRoomId, resolve, reject }))
            }).finally(() => {
                setLoadingRoom(false)
            })
        }
    }

    const updateDataFromSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsUpdateDataFromSite(true)
            return dispatch(roomAction.updateDataFromSite({ apiRoomOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.room.modal.updateSuccessTitle"],
                content: messages[`page.room.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.room.modal.inProgressSyncError`]
                : messages[`page.room.modal.updateError`]
            Modal.error({
                title: messages["page.room.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsUpdateDataFromSite(false)
        })
    });

    const syncDataToSite = useCallback(() => {
        new Promise((resolve, reject) => {
            setIsSyncDataToSite(true)
            return dispatch(roomAction.syncDataToSite({ apiRoomOtaId, resolve, reject }));
        }).then(() => {
            Modal.success({
                title: messages["page.room.modal.updateSuccessTitle"],
                content: messages[`page.room.modal.updateSuccess`],
            });
        }).catch((e) => {
            let messageError = e.response != null && e.response.status === ERROR_STATUS.IN_PROGRESS_SYNC
                ? messages[`page.room.modal.inProgressSyncError`]
                : messages[`page.room.modal.updateError`]
            Modal.error({
                title: messages["page.room.modal.errorTitle"],
                content: messageError,
            });
        }).finally(() => {
            setIsSyncDataToSite(false)
        })
    });

    const addAndRemoveBedroom = (value) => {
        let temp = [...listRoom];
        if (value === 1 && temp.length === 1) {
            temp.push(
                [
                    [
                        {
                            beddingType: null,
                            numberOfBedding: null
                        }
                    ]
                ]
            );
        } else if (value > temp.length - 1) {
            let numberOfRoomAdd = value;
            if (temp.length > 1) {
                numberOfRoomAdd = value - (temp.length - 1);
            }

            for (let i = 0; i < numberOfRoomAdd; i++) {
                temp.push(
                    [
                        [
                            {
                                beddingType: null,
                                numberOfBedding: null
                            }
                        ]
                    ]
                );
            }
        } else if (value < temp.length) {
            let arrLastIndex = temp.length - 1;
            let updatedValues = { ...values };
            while (arrLastIndex > value) {
                if (temp.length > 2) {
                    temp.splice(arrLastIndex, 1);
                    if (updatedValues.apiRoomOtas[tabKey].fieldContents.listRoomValue !== null && updatedValues.apiRoomOtas[tabKey].fieldContents.listRoomValue !== undefined) {
                        updatedValues.apiRoomOtas[tabKey].fieldContents.listRoomValue = updatedValues.apiRoomOtas[tabKey].fieldContents.listRoomValue.filter((element, index) => index !== arrLastIndex)
                        setValues(updatedValues)
                    }
                }

                arrLastIndex--;
            }
        }

        setListRoom([...temp]);
        setFieldValue(`apiRoomOtas[${tabKey}].fieldContents.listRoomValue`, [...temp])
    }

    const addBeddingOption = (roomIndex, patternIndex) => {
        let temp = [...listRoom];
        temp[roomIndex][patternIndex].push(
            {
                beddingType: null,
                numberOfBedding: null
            }
        );

        setListRoom([...temp]);
        setFieldValue(`apiRoomOtas[${tabKey}].fieldContents.listRoomValue`, [...temp])
    }

    const removeBeddingOption = (roomIndex, patternIndex, recordIndex) => {
        if (roomIndex === 0 && patternIndex === 0 && listRoom[roomIndex][patternIndex].length === 1) {
            let temp = listRoom[roomIndex][patternIndex].filter((_, index) => index === 0);
            listRoom[roomIndex][patternIndex] = temp;
        } else {
            if (listRoom.length - 1 <= 1 && patternIndex === 0 && listRoom[roomIndex][patternIndex].length <= 1) {
                return;
            }

            let temp = listRoom[roomIndex][patternIndex].filter((_, index) => index !== recordIndex);
            listRoom[roomIndex][patternIndex] = temp;
            if (temp.length === 0) {
                listRoom[roomIndex].splice(patternIndex, 1);
            }
        }

        if (listRoom[roomIndex].length === 0 && listRoom.length - 1 > 1) {
            listRoom.splice(roomIndex, 1);
            setFieldValue(`apiRoomOtas.${tabKey}.fieldContents.selectBedrooms`, listRoom.length - 1)
        }

        setListRoom([...listRoom]);
        setFieldValue(`apiRoomOtas[${tabKey}].fieldContents.listRoomValue`, [...listRoom])
    }

    const onChangePatternSetting = (name, value, roomIndex, patternIndex, recordIndex) => {
        listRoom[roomIndex][patternIndex][recordIndex][name] = value;
        setListRoom([...listRoom]);
    }

    const addPattern = (roomIndex) => {
        if (listRoom[roomIndex].length < 2) {
            let temp = [...listRoom];
            temp[roomIndex].push(
                [
                    {
                        beddingType: null,
                        numberOfBedding: null
                    }
                ]
            );

            setListRoom([...temp]);
            setFieldValue(`apiRoomOtas[${tabKey}].fieldContents.listRoomValue`, [...temp])
        }
    }

    const columnTableRoom = (roomIndex, patternIndex) => [
        {
            title: messages['page.room.rurubu.pattern'] + (patternIndex + 1),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: messages['page.room.rurubu.beddingType'],
            dataIndex: "beddingType",
            key: "beddingType",
            render: (text, record, recordIndex) => {
                return (
                    <Form.Item
                        name={`apiRoomOtas.${tabKey}.fieldContents.listRoomValue.${roomIndex}.${patternIndex}.${recordIndex}.beddingType`}
                        className={"mb-0"}
                        validate={ (value) => (
                            ((value === "" || value === null || value === undefined) && showBeddingSetting == 1) ?
                                messages["form.error.mixed.required"].replace("${path}", messages['page.room.rurubu.beddingType']) : null
                        )}
                    >
                        <Select
                            name={`apiRoomOtas.${tabKey}.fieldContents.listRoomValue.${roomIndex}.${patternIndex}.${recordIndex}.beddingType`}
                            listHeight={320}
                            style={{width: "280px"}}
                            defaultValue={record.beddingType}
                            showSearch
                            placeholder={messages['page.room.rurubu.beddingTypePlaceholder']}
                            {...searchSelect}
                            onChange={(value) => onChangePatternSetting("beddingType", value, roomIndex, patternIndex, recordIndex)}
                        >
                            {optionValues.map((option, optionIndex) => {
                                if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.beddingType) {
                                    return (
                                        <Select.Option key={optionIndex} value={option.value}>
                                            {option.value}
                                        </Select.Option>
                                    )
                                }
                            })}
                        </Select>
                    </Form.Item>
                )
            }
        },
        {
            title: messages['page.room.rurubu.numberOfBedding'],
            dataIndex: 'numberOfBedding',
            key: 'numberOfBedding',
            render: (item, record, recordIndex) => {
                return (
                    <Row>
                        <Form.Item
                            name={`apiRoomOtas.${tabKey}.fieldContents.listRoomValue.${roomIndex}.${patternIndex}.${recordIndex}.numberOfBedding`}
                            className={"mb-0"}
                            validate={ (value) => (
                                ((value === "" || value === null || value === undefined) && showBeddingSetting == 1) ?
                                    messages["form.error.mixed.required"].replace("${path}", messages['page.room.rurubu.numberOfBedding']) : null
                            )}
                        >
                            <Row>
                                <Select
                                    name={`apiRoomOtas.${tabKey}.fieldContents.listRoomValue.${roomIndex}.${patternIndex}.${recordIndex}.numberOfBedding`}
                                    listHeight={320}
                                    style={{width: "115px"}}
                                    showSearch
                                    {...searchSelect}
                                    defaultValue={record.numberOfBedding}
                                    placeholder={messages['page.room.rurubu.numberOfBeddingPlaceholder']}
                                    onChange={(value) => onChangePatternSetting("numberOfBedding", value, roomIndex, patternIndex, recordIndex)}
                                >
                                    {optionValues.map((option, optionIndex) => {
                                        if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.numberOfBedding) {
                                            return (
                                                <Select.Option key={optionIndex} value={option.value}>
                                                    {option.value}
                                                </Select.Option>
                                            )
                                        }
                                    })}
                                </Select>
                                <Button style={{marginRight: "5px", marginLeft: "5px"}} disabled={roomIndex === 0 && patternIndex === 0 && listRoom[roomIndex][patternIndex].length === 1}
                                        onClick={() => {
                                            removeBeddingOption(roomIndex, patternIndex, recordIndex)
                                        }}
                                >
                                    -
                                </Button>
                                <Button onClick={() => {
                                    addBeddingOption(roomIndex, patternIndex)
                                }}>
                                    +
                                </Button>
                            </Row>
                        </Form.Item>
                    </Row>
                )
            }
        },
    ];


    return (
        <TabStyles>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={messages["page.room.site.id"]}
                        name={`apiRoomOtas[${tabKey}]['siteRoomId']`}
                    >
                        <Input
                            disabled={true}
                            name={`apiRoomOtas[${tabKey}]['siteRoomId']`}
                        />
                    </Form.Item>
                </Col>
                <Col {...colRight} style={{display: 'flex' , justifyContent: 'end'}}>
                    <Button
                        type="primary"
                        className="mr-10 button"
                        block
                        hidden={apiRoomOtaId === null || apiRoomOtaId === undefined || mode == MODE_SCREEN.COPY}
                        loading={isSyncDataToSite}
                        onClick={syncDataToSite}
                        disabled={disableSyncData}
                    >
                        {messages["page.room.sync"]}
                    </Button>
                    <Button
                        type="primary"
                        className="mr-10 button"
                        block
                        hidden={apiRoomOtaId === null || apiRoomOtaId === undefined || mode == MODE_SCREEN.COPY}
                        loading={isUpdateDataFromSite}
                        onClick={updateDataFromSite}
                    >
                        {messages["page.room.crawlUpdate"]}
                    </Button>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={messages["page.room.ota.syncStatus"]}
                        name={`apiRoomOtas[${tabKey}]['syncStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['syncStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} className={'position-error-message'}>
                    <Row>
                        <Col xs={24} lg={2}>
                            <Button
                                type="primary"
                                className="mr-2"
                                loading={loadingRoom}
                                icon={<ReloadOutlined />}
                                onClick={() => handleReloadRoom()}>
                            </Button>
                        </Col>
                        <Col xs={24} lg={13}>
                            <Form.Item
                                name={`apiRoomOtas[${tabKey}]['syncErrorContent']`}
                            >
                                <Input
                                    className="styleInputLog"
                                    onClick={() => setShowLog(true)}
                                    value={syncErrorContentFirst}
                                    name={`apiRoomOtas[${tabKey}]['syncErrorContent']`}
                                    readOnly
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={messages["page.room.ota.updateStatus"]}
                        name={`apiRoomOtas[${tabKey}]['updateStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['updateStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SYNC.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColRight}
                        label={messages["page.room.ota.openSaleStatus"]}
                        name={`apiRoomOtas[${tabKey}]['openSaleStatus']`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas[${tabKey}]['openSaleStatus']`}
                            disabled={true}
                        >
                            {LIST_STATUS_SALE_PROCESSING.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColRight}
                        label={messages["page.room.ota.openSaleOtaStatus"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.openSaleOtaStatus`}
                    >
                        <Select
                            className="select"
                            name={`apiRoomOtas.${tabKey}.fieldContents.openSaleOtaStatus`}
                            disabled={true}
                        >
                            {LIST_OPEN_SALE_STATUS.map((select, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={select.id}
                                    >
                                        {select.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div className="pb-25px">
                                <div className="custom-required">{messages["page.room.rurubu.roomName"]}</div>
                            </div>
                        }
                        name={`apiRoomOtas.${tabKey}.fieldContents.name`}
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.rurubu.roomName"],
                            {
                                required: messages["form.error.mixed.required"]

                            },
                            {
                                required : true
                            }
                        )}
                        className="custom-label"
                    >
                        <Input
                            maxLength={30}
                            name={`apiRoomOtas.${tabKey}.fieldContents.name`}
                        />
                        <p>{messages['page.room.rurubu.roomNameInputNote']}</p>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colSingleUnit}>
                    <Form.Item
                        {...inputColLeft}
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.rurubu.roomDescription"]}</div>
                            </div>
                        }
                        name={`apiRoomOtas.${tabKey}.fieldContents.roomDescription`}
                        className="custom-label"
                    >
                        <Textarea
                            onChange={(values) => changeTextAreaValue(values, `apiRoomOtas.${tabKey}.fieldContents.roomDescription`, setRoomDescriptionValue, 100)}
                            value={roomDescriptionValue}
                            maxLength={100}
                            type="textarea"
                            rows={5}
                        />
                        <p>{messages["page.room.rurubu.roomDescriptionNote"]}</p>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={
                            <div className="pb-25px">
                                <div className="custom-required">{messages["page.room.rurubu.roomStyle"]}</div>
                            </div>
                        }
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.rurubu.roomStyle"],
                            {
                                required: messages["form.error.mixed.required"],
                            },
                            {
                                required: true,
                            }
                        )}
                        name={`apiRoomOtas.${tabKey}.fieldContents.roomStyle`}
                    >
                        <Select
                            name={`apiRoomOtas.${tabKey}.fieldContents.roomStyle`}
                            listHeight={320}
                            allowClear
                            showSearch
                            placeholder={messages['page.room.rurubu.roomStylePlaceholder']}
                            onChange={(value) => onChangeSelectValue(value, `apiRoomOtas.${tabKey}.fieldContents.roomStyle`)}
                            {...searchSelect}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.roomStyle) {
                                    return (
                                        <Select.Option key={index} value={option.value}>
                                            {option.value}
                                        </Select.Option>
                                    )
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        labelCol={{ lg: 4, xs: 24  }}
                        wrapperCol={{ lg: 18, xs: 24 }}
                        label={
                            <div className="pb-25px">
                                <div>{messages["page.room.rurubu.roomSetupMinRate"]}</div>
                                <div>{messages["page.room.rurubu.roomSetupMinRateNote"]}</div>
                            </div>
                        }
                        name={`apiRoomOtas.${tabKey}.fieldContents.roomSetupMinRate`}
                        className="custom-label"
                    >
                        <Row justify="start">
                            <Col span={8}>
                                <InputNumber
                                    style={{ width: "100%" }}
                                    min={1}
                                    parser={(value) => {
                                        return value.substring(0, 15)
                                    }}
                                    name={`apiRoomOtas.${tabKey}.fieldContents.roomSetupMinRate`}
                                />
                            </Col>
                            <div className="roomName-custom">{messages["page.room.rurubu.yenUnit"]}</div>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} lg={{ span: 24 }} className="mb-10">
                    <h2 className={"custom-title label-custom"}>{messages["page.room.rurubu.roomDetailTitle"]}</h2>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={
                            <div className="pb-25px">
                                <div className="custom-required">{messages["page.room.rurubu.numberOfGuests"]}</div>
                            </div>
                        }
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.rurubu.numberOfGuests"],
                            {
                                required: messages["form.error.mixed.required"],
                            },
                            {
                                required: true,
                            }
                        )}
                        name={`apiRoomOtas.${tabKey}.fieldContents.numberOfGuests`}
                    >
                        <Select
                            name={`apiRoomOtas.${tabKey}.fieldContents.numberOfGuests`}
                            listHeight={320}
                            allowClear
                            showSearch
                            onChange={(value) => onChangeSelectValue(value, `apiRoomOtas.${tabKey}.fieldContents.numberOfGuests`)}
                            {...searchSelect}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.numberOfGuests) {
                                    return (
                                        <Select.Option key={index} value={option.value}>
                                            {option.value}
                                        </Select.Option>
                                    )
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={
                            <div className="pb-25px">
                                <div className="custom-required">{messages["page.room.rurubu.smokingPermittedInRoom"]}</div>
                            </div>
                        }
                        validate={(value) => ValidateField(
                            value,
                            messages["page.room.rurubu.smokingPermittedInRoom"],
                            {
                                required: messages["form.error.mixed.required"],
                            },
                            {
                                required: true,
                            }
                        )}
                        name={`apiRoomOtas.${tabKey}.fieldContents.smokingPermittedInRoom`}
                    >
                        <Select
                            name={`apiRoomOtas.${tabKey}.fieldContents.smokingPermittedInRoom`}
                            listHeight={320}
                            allowClear
                            showSearch
                            placeholder={messages['page.room.rurubu.smokingPermittedInRoomPlaceholder']}
                            onChange={(value) => onChangeSelectValue(value, `apiRoomOtas.${tabKey}.fieldContents.smokingPermittedInRoom`)}
                            {...searchSelect}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.smokingPermittedInRoom) {
                                    return (
                                        <Select.Option key={index} value={option.value}>
                                            {option.value}
                                        </Select.Option>
                                    )
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} lg={{ span: 24 }} className="mb-10">
                    <h3 className={"custom-title label-custom"}>{messages["page.room.rurubu.roomSizeTitle"]}</h3>
                </Col>
            </Row>
            <Row justify="start">
                <Col {...colFullApi}>
                    <Form.Item
                        {...itemLeftLabelFullApi}
                        className="custom-label"
                        label={messages["page.room.rurubu.roomSize"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.roomSizeGroup`}
                    >
                        <Row className="mb-25">
                            <Col {...colSingleUnitCustom2} style={{paddingRight: "5px"}}>
                                <Form.Item
                                    name={`apiRoomOtas.${tabKey}.fieldContents.roomSize`}
                                    className="pr-2"
                                >
                                    <InputNumber
                                        min={0}
                                        max={999}
                                        parser={(value) => {
                                            return value.substring(0, 3)
                                        }}
                                        placeholder={messages['page.room.rurubu.roomSizePlaceholder']}
                                        style={{ width: "100%" }}
                                        name={`apiRoomOtas.${tabKey}.fieldContents.roomSize`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...colSingleUnitCustom}>
                                <Form.Item
                                    name={`apiRoomOtas.${tabKey}.fieldContents.roomSizeUnit`}
                                >
                                    <Select
                                        className="select"
                                        name={`apiRoomOtas.${tabKey}.fieldContents.roomSizeUnit`}
                                        onChange={(value) => onChangeSelectValue(value, `apiRoomOtas.${tabKey}.fieldContents.roomSizeUnit`)}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.sizeRoomUnit) {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.value}
                                                    </Select.Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col {...colLeftApi}>
                    <Form.Item
                        {...itemLeftLabelApi}
                        label={messages["page.room.rurubu.roomSizeTypeExtension"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.roomSizeTypeExtension`}
                    >
                        <Select
                            name={`apiRoomOtas.${tabKey}.fieldContents.roomSizeTypeExtension`}
                            listHeight={320}
                            allowClear
                            showSearch
                            placeholder={messages['page.room.rurubu.roomSizeTypeExtensionPlaceholder']}
                            onChange={(value) => onChangeSelectValue(value, `apiRoomOtas.${tabKey}.fieldContents.roomSizeTypeExtension`)}
                            {...searchSelect}
                        >
                            {optionValues.map((option, index) => {
                                if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.roomSizeTypeExtension) {
                                    return (
                                        <Select.Option key={index} value={option.value}>
                                            {option.value}
                                        </Select.Option>
                                    )
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start">
                <Col {...colFullApi}>
                    <Form.Item
                        {...itemLeftLabelFullApi}
                        className="custom-label"
                        label={messages["page.room.rurubu.extensionSize"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.extensionSizeGroup`}
                    >
                        <Row className="mb-25">
                            <Col {...colSingleUnitCustom2} style={{paddingRight: "5px"}}>
                                <Form.Item
                                    name={`apiRoomOtas.${tabKey}.fieldContents.extensionSize`}
                                    className="pr-2"
                                >
                                    <InputNumber
                                        min={0}
                                        max={999}
                                        parser={(value) => {
                                            return value.substring(0, 3)
                                        }}
                                        placeholder={messages['page.room.rurubu.roomSizePlaceholder']}
                                        style={{ width: "100%" }}
                                        name={`apiRoomOtas.${tabKey}.fieldContents.extensionSize`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...colSingleUnitCustom}>
                                <Form.Item
                                    name={`apiRoomOtas.${tabKey}.fieldContents.extensionSizeUnit`}
                                >
                                    <Select
                                        className="select"
                                        name={`apiRoomOtas.${tabKey}.fieldContents.extensionSizeUnit`}
                                        onChange={(value) => onChangeSelectValue(value, `apiRoomOtas.${tabKey}.fieldContents.extensionSizeUnit`)}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.sizeRoomUnit) {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.value}
                                                    </Select.Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        {...inputColRightCustom}
                        label={messages["page.room.rurubu.roomViewTitle"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.roomView`}
                        className="ant-col-custom"
                    >
                        <Checkbox.Group
                            name={`apiRoomOtas.${tabKey}.fieldContents.roomView`}
                            value={roomView}
                            style={{ width: "100%" }}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas.${tabKey}.fieldContents.roomView`, setRoomView)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.roomView) {
                                        return (
                                            <Col span={12} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="mb-25">
                <Collapse accordion  style={{ width: "100%" }} activeKey={ showBeddingSetting !== null && showBeddingSetting !== undefined ? showBeddingSetting : null } onChange={handleShowBeddingSetting}>
                    <Panel header={messages["page.room.rurubu.roomTypeTitle"]} key="1">
                        <Row justify="start" className="mb-25">
                            <Col {...colSingleUnit}>
                                <Form.Item
                                    labelCol={{xl: 24, lg: 24, xs: 24}}
                                    wrapperCol={{xl:24, lg: 24, xs: 24}}
                                    className="custom-label"
                                    label={messages["page.room.rurubu.roomType"]}
                                    name={`apiRoomOtas.${tabKey}.fieldContents.roomType`}
                                >
                                    <Row justify="start" className="mb-10">
                                        <Col {...colSingleUnit} style={{ marginRight: "120px" }}>
                                            <Radio.Group
                                                onChange={(values) => changeRoomTypeValue(values, `apiRoomOtas.${tabKey}.fieldContents.roomType`, setActiveRoomType)}
                                                name={`apiRoomOtas.${tabKey}.fieldContents.roomType`}
                                                value={activeRoomType}
                                                style={{ width: '100%' }}
                                            >
                                                {optionValues.map((option, key) => {
                                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.roomType) {
                                                        return (
                                                            <Radio
                                                                key={key}
                                                                value={option.value}
                                                                style={{width: "100%", marginBottom: "15px", display: "inline-flex"}}
                                                                className="rurubu-radio-custome"
                                                            >
                                                                <span>{option.value}</span>
                                                            </Radio>
                                                        )
                                                    }
                                                })}
                                            </Radio.Group>
                                        </Col>
                                        <Col span={3} style={{ display: "flex", alignItems: "end", paddingBottom: "15px" }}>
                                            <Select
                                                className="select"
                                                name={`apiRoomOtas.${tabKey}.fieldContents.selectBedrooms`}
                                                onChange={(value) => onChangeSelectBedroomsValue(value, `apiRoomOtas.${tabKey}.fieldContents.selectBedrooms`)}
                                                disabled={disabledSelectBedrooms}
                                            >
                                                {optionValues.map((option, index) => {
                                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.selectBedrooms) {
                                                        return (
                                                            <Select.Option
                                                                key={index}
                                                                value={option.value}
                                                            >
                                                                {option.value}
                                                            </Select.Option>
                                                        );
                                                    }
                                                })}
                                            </Select>
                                            <span></span>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            listRoom.map((room, roomIndex) => {
                                return (
                                    <>
                                        <Col xs={24} lg={{ span: 24 }} className="mb-10">
                                            <Row>
                                                <Col xs={24} lg={{ span: 24 }} className="mb-10">
                                                    <h3 className={"custom-title label-custom"}>
                                                        {listRoom.length > 1 && roomIndex === listRoom.length - 1 ? messages["page.room.rurubu.roomTitle"].replace("${roomNumber}", "") : messages["page.room.rurubu.roomTitle"].replace("${roomNumber}", roomIndex + 1)}
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} lg={{ span: 18 }} className="mb-10">
                                            <Row>
                                                {
                                                    room.map((pattern, patternIndex) => {
                                                        return (
                                                            <Col xs={24} lg={{ span: 24 }} className="mb-10">
                                                                { pattern.length > 0 && (
                                                                    <Table
                                                                        dataSource={_.cloneDeep(pattern)}
                                                                        columns={columnTableRoom(roomIndex, patternIndex)}
                                                                        pagination={false}
                                                                    />)
                                                                }
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Col>
                                        <Button
                                            type="primary"
                                            block
                                            style={{width: "180px", marginBottom: "15px"}}
                                            disabled={room.length >= 2}
                                            onClick={() => addPattern(roomIndex)}
                                        >
                                            {messages["page.room.rurubu.addPattern"]}
                                        </Button>
                                    </>
                                )
                            })
                        }
                    </Panel>
                </Collapse>
            </Row>

            <Row>
                <Col xs={24} lg={{ span: 24 }} className="mb-10">
                    <h2 className={"custom-title label-custom"}>{messages["page.room.rurubu.amenities"]}</h2>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        {...inputColRightCustom}
                        label={messages["page.room.rurubu.bathAmenities"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.bathAmenities`}
                        className="ant-col-custom"
                    >
                        <Checkbox.Group
                            name={`apiRoomOtas.${tabKey}.fieldContents.bathAmenities`}
                            value={bathAmenities}
                            style={{ width: "100%" }}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas.${tabKey}.fieldContents.bathAmenities`, setBathAmenities)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.bathAmenities) {
                                        return (
                                            <Col span={12} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        {...inputColRightCustom}
                        label={messages["page.room.rurubu.entertainmentAmenities"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.entertainmentAmenities`}
                        className="ant-col-custom"
                    >
                        <Checkbox.Group
                            name={`apiRoomOtas.${tabKey}.fieldContents.entertainmentAmenities`}
                            value={entertainmentAmenities}
                            style={{ width: "100%" }}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas.${tabKey}.fieldContents.entertainmentAmenities`, setEntertainmentAmenities)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.entertainmentAmenities) {
                                        return (
                                            <Col span={12} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        {...inputColRightCustom}
                        label={messages["page.room.rurubu.layoutAmenities"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.layoutAmenities`}
                        className="ant-col-custom"
                    >
                        <Checkbox.Group
                            style={{ width: "100%" }}
                            name={`apiRoomOtas.${tabKey}.fieldContents.layoutAmenities`}
                            value={layoutAmenities}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas.${tabKey}.fieldContents.layoutAmenities`, setLayoutAmenities)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.layoutAmenities) {
                                        return (
                                            <Col span={12} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        {...inputColRightCustom}
                        label={messages["page.room.rurubu.safetyAmenities"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.safetyAmenities`}
                        className="ant-col-custom"
                    >
                        <Checkbox.Group
                            style={{ width: "100%" }}
                            name={`apiRoomOtas.${tabKey}.fieldContents.safetyAmenities`}
                            value={safetyAmenities}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas.${tabKey}.fieldContents.safetyAmenities`, setSafetyAmenities)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.safetyAmenities) {
                                        return (
                                            <Col span={12} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        {...inputColRightCustom}
                        label={messages["page.room.rurubu.access"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.access`}
                        className="ant-col-custom"
                    >
                        <Checkbox.Group
                            name={`apiRoomOtas.${tabKey}.fieldContents.access`}
                            value={access}
                            style={{ width: "100%" }}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas.${tabKey}.fieldContents.access`, setAccess)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.access) {
                                        return (
                                            <Col span={12} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        {...inputColRightCustom}
                        label={messages["page.room.rurubu.comfortAmenities"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.comfortAmenities`}
                        className="ant-col-custom"
                    >
                        <Checkbox.Group
                            name={`apiRoomOtas.${tabKey}.fieldContents.comfortAmenities`}
                            value={comfortAmenities}
                            style={{ width: "100%" }}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas.${tabKey}.fieldContents.comfortAmenities`, setComfortAmenities)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.comfortAmenities) {
                                        return (
                                            <Col span={12} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        {...inputColRightCustom}
                        label={messages["page.room.rurubu.clothingAmenities"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.clothingAmenities`}
                        className="ant-col-custom"
                    >
                        <Checkbox.Group
                            style={{ width: "100%" }}
                            name={`apiRoomOtas.${tabKey}.fieldContents.clothingAmenities`}
                            value={clothingAmenities}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas.${tabKey}.fieldContents.clothingAmenities`, setClothingAmenities)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.clothingAmenities) {
                                        return (
                                            <Col span={12} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        {...inputColRightCustom}
                        label={messages["page.room.rurubu.diningAmenities"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.diningAmenities`}
                        className="ant-col-custom"
                    >
                        <Checkbox.Group
                            style={{ width: "100%" }}
                            name={`apiRoomOtas.${tabKey}.fieldContents.diningAmenities`}
                            value={diningAmenities}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas.${tabKey}.fieldContents.diningAmenities`, setDiningAmenities)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.diningAmenities) {
                                        return (
                                            <Col span={12} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" className="mb-25">
                <Col span={24}>
                    <Form.Item
                        {...inputColRightCustom}
                        label={messages["page.room.rurubu.accessibility"]}
                        name={`apiRoomOtas.${tabKey}.fieldContents.accessibility`}
                        className="ant-col-custom"
                    >
                        <Checkbox.Group
                            name={`apiRoomOtas.${tabKey}.fieldContents.accessibility`}
                            value={accessibility}
                            style={{ width: "100%" }}
                            onChange={(values) => onChangeFilterCondition(values, `apiRoomOtas.${tabKey}.fieldContents.accessibility`, setAccessibility)}
                        >
                            <Row>
                                {optionValues.map((option, key) => {
                                    if (option.fieldKey === fieldKeyRurubuApiRoomTypeOption.accessibility) {
                                        return (
                                            <Col span={12} key={key} className="mb-5">
                                                <Checkbox value={option.value}>
                                                    {option.value}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Modal
                title={messages["page.crawlRoom.titleLog"]}
                style={{ top: 20 }}
                width={700}
                visible={showLog}
                footer={null}
                onCancel={() => setShowLog(false)}
            >
                <Row style={{maxHeight: '80vh', overflowY: 'auto', display: 'block'}}>
                    {syncErrorContent != null && syncErrorContent.length > 0 && syncErrorContent.map((x, index) => (
                        <Row style={{marginTop: '10px' }}>
                            <p key={index}>{x}</p>
                        </Row>
                    ))}
                </Row>
                {syncErrorContent == null || syncErrorContent === '' || syncErrorContent.length <= 0 && (
                    <Row  style={{ padding: '10px', justifyContent: 'center' }}>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>{messages["antTable.emptyData"]}</span>
                            }
                        >
                        </Empty>
                    </Row>
                )}
            </Modal>
        </TabStyles>
    )
}

export const colSingleUnitCustom = {
    xs: { span: 14 },
    md: { span: 5},
    lg: { span: 3},
    xl: { span: 3},
};

export const colSingleUnitCustom2 = {
    xs: { span: 20 },
    md: { span: 5},
    lg: { span: 3},
    xl: { span: 5},
}

export const inputColRightCustom = {
    labelCol: { lg: 4, xs: 24 },
    wrapperCol: { lg: 13, xs: 24 }
};

const rurubuInitialValue = {
    name: null,
    roomCode: null,
    roomDescription: null,
    roomStyle: null,
    roomSetupMinRate: null,
    numberOfGuests: 1,
    smokingPermittedInRoom: null,
    roomSize: null,
    roomSizeUnit: "m²",
    roomSizeTypeExtension: '選択',
    extensionSize: null,
    extensionSizeUnit: "m²",
    roomView: [],
    roomType: "一つの部屋にのみ寝具を配置する場合",
    selectBedrooms: 1,
    beddingType: null,
    numberOfBedding: null,
    bathAmenities: [],
    entertainmentAmenities: [],
    layoutAmenities: [],
    safetyAmenities: [],
    access: [],
    comfortAmenities: [],
    clothingAmenities: [],
    diningAmenities: [],
    accessibility: [],
    showBeddingSetting: null,
    listRoomValue: [
        [
            [
                {
                    beddingType: null,
                    numberOfBedding: null
                }
            ]
        ]
    ]
}

export default memo(RurubuTab);
