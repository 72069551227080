import { ROLES } from "@iso/constants/common.constant";

export const SELECT_ROLES_ADMIN = [
  ROLES.ADMIN,
  ROLES.SUBADMIN,
  ROLES.CHAIN,
  ROLES.STORE,
  ROLES.USER,
];
export const SELECT_ROLES_CHAIN = [ROLES.CHAIN, ROLES.STORE];
export const SELECT_ROLES_STORE = [ROLES.STORE];

export const SELECT_STATUS = ["利用中", "停止"];

export const SELECT_DISABLE_CHAIN = [ROLES.ADMIN, ROLES.USER, ROLES.SUBADMIN, ""];
export const SELECT_DISABLE_STORE = [
  ROLES.ADMIN,
  ROLES.CHAIN,
  ROLES.USER,
  ROLES.SUBADMIN,
  "",
];
export const INPUT_DISABLE_EMAIL = [ROLES.CHAIN, ROLES.STORE];

export const SELECT_CONTRACT_STATUS = ["契約中", "解約", "停止"];

export const SELECT_TASK_PRIORITY_LIST = {
  OPTIONS: ["高", "中", "低"],
  DEFAULT: "中",
};

export const TASK_STATUS_MAPPING_COLOR = {
  未着手: "#F0C3C0",
  作業中: "#ACC6DF",
  担当者確認中: "#ABCFDB",
  施設確認中: "#B8D9D3",
  デザイン部依頼中: "#FCBA03",
  保留: "#A7A7A7",
  処理済: "#C6C8D9",
  施設チェック中: "#F5A6B4",
  完了: "#D7DCAC",
  依頼中: "#AA80FF",
  user: "transparent",
};

export const TASK_STATUS_PROCESSED = "処理済";
export const SELECT_TASK_STATUS_LIST = {
  OPTIONS: [
    "未着手",
    "作業中",
    "担当者確認中",
    "施設確認中",
    "デザイン部依頼中",
    "保留",
    "依頼中",
    "処理済",
    "施設チェック中",
    "完了",
  ],
  OPTIONS_CHAIN_STORE: [
    "完了",
  ],
  OPTIONS_GANTT_CHART: [
    "全て",
    "未着手",
    "作業中",
    "担当者確認中",
    "施設確認中",
    "デザイン部依頼中",
    "保留",
    "依頼中",
    "処理済",
    "施設チェック中",
    "完了",
  ],
  DEFAULT: "未着手",
  TASK_CHAIN_STORE_DEFAULT: "作業中",
  NOT_CLOSED: "完了以外",
  NOT_ON_HOLD: "保留以外",
  NOT_ASIGNEE_DROPDOWN: "全て",
  IN_PROGRESS_OPTIONS: [
    "未着手",
    "作業中",
    "担当者確認中",
    "施設確認中",
    "デザイン部依頼中",
    "保留",
    "依頼中",
    "処理済",
    "施設チェック中",
    "完了"
  ],
};

export const TASK_DETAIL_LOG_TABLE_TYPE = {
  SUMMARY: "summary",
  DETAIL: "detail",
};
export const TASK_DETAIL_COMMENT_TYPE = {
  USER: "USER",
  STORE: "STORE",
};

export const TASK_STOP_WATCH = {
  ACTIONS: {
    STOP: "STOP",
    START: "START",
  },
  TYPES: {
    EXECUTE: "EXECUTE",
    CONFIRM: "CONFIRM",
  },
};

export const SELECT_TEMPLATE = {
  UNIT: ["円", "%"],
  RECEIVE: ["受入不可", "受入可"],
  INCREASE_DECREASE: ["+", "-"],
};

export const SELECT_DISABLE_TEAM = [ROLES.CHAIN, ROLES.STORE, ""];

export const TASK_CONTENT_TYPES = {
  EXECUTE: "EXECUTE",
  CONFIRM: "CONFIRM",
};

export const DAY_OF_WEEK = [
  { value: 'monday', name: '月' },
  { value: 'tuesday', name: '火' },
  { value: 'wednesday', name: '水' },
  { value: 'thursday', name: '木' },
  { value: 'friday', name: '金' }
];

export const SELECT_TYPE_AUTO_COPY_TASK = {
  DAY_OF_THE_WEEK: 1,
  DAY: 2,
  END_OF_THE_MONTH: 3,
  BEGINNING_OF_THE_MONTH: 4,
  END_OF_LAST_MONTH_AND_MIDDLE_OF_THIS_MONTH: 5,
  EVERY_DAY: 6
};

export const DP_SETTINGS = [
  { value: '有', label: 'page.taskAddEdit.extraField.presence'},
  { value: '無', label: 'page.taskAddEdit.extraField.absence'},
]

export const TEMPLATE_SALE_PLAN = [
  { value: '延長の場合', label: 'page.taskAddEdit.extraField.periodExtension'},
  { value: 'プラン新規作成の場合', label: 'page.taskAddEdit.extraField.createNewPlan'},
]

export const TASK_FORMAT_OPTIONS = [
  { value: '清書', label: 'page.tasks.taskFormat.origin'},
  { value: '下書き', label: 'page.tasks.taskFormat.draft'},
]

export const MODE_REPORT_OPTIONS = [
  { value: 1, label: 'page.crawlAdditionalRequest.onHand'},
  { value: 2, label: 'page.crawlAdditionalRequest.dor'},
  { value: 3, label: 'page.crawlAdditionalRequest.roomOccupancyRate'},
  { value: 4, label: 'page.crawlAdditionalRequest.leadTime'},
]

export const LIST_TASK_COLUMN_CSV = [
  {id: 0, value: 'url', name: 'page.tasks.url' },
  {id: 1, value: 'storeName', name: 'page.tasks.store' },
  {id: 2, value: 'title', name: 'page.tasks.title'},
  {id: 3, value: 'assigneeName', name: 'page.tasks.assignee'},
  {id: 4, value: 'status', name: 'page.tasks.status'},
  {id: 5, value: 'memo', name: 'page.tasks.memo'},
  {id: 6, value: 'priority', name: 'page.tasks.priority'},
  {id: 7, value: 'registerDate', name: 'page.tasks.registerDate'},
  {id: 8, value: 'startDate', name: 'page.tasks.startDate'},
  {id: 9, value: 'dueDate', name: 'page.tasks.dueDate'},
  {id: 10, value: 'deliveryDate', name: 'page.tasks.actualDeliveryDate'},
  {id: 11, value: 'estTime', name: 'page.tasks.estimateTimeWork'},
  {id: 12, value: 'estPoint', name: 'page.tasks.estimateTimeCheck'},
  {id: 13, value: 'executeTime', name: 'page.tasks.executeTime'},
  {id: 14, value: 'confirmTime', name: 'page.tasks.confirmTime'},
  {id: 15, value: 'totalExecuteTime', name: 'page.tasks.totalWorkTime'},
  {id: 16, value: 'directorName', name: 'page.tasks.director'},
  {id: 17, value: 'personInChargeName', name: 'page.tasks.personInCharge'},
  {id: 18, value: 'registerPersonName', name: 'page.tasks.registerPerson'},
  {id: 19, value: 'executedTeamName', name: 'page.tasks.executedTeam'},
  {id: 20, value: 'fixedDeadline', name: 'page.tasks.fixedDeadline'},
];

export const INFANT_KODOMO_RURUBU = ['こどもA', 'こどもB', 'こどもC', 'こどもD'];

export const DATA_RANKING_SCORE_OTAS = [
  {id: 1, value: '楽天', name: '楽天'},
  {id: 2, value: 'じゃらん', name: 'じゃらん'},
  {id: 3, value: '一休', name: '一休'},
  {id: 4, value: 'るるぶ', name: 'るるぶ'},
];

export const DATA_COLLECTION_OTAS = [
  {id: 1, value: '楽天', name: '楽天'},
  {id: 2, value: 'じゃらん', name: 'じゃらん'},
];
