import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";
import {createApiPlan, deleteApiPlanOta} from "@iso/redux/apiPlan/saga";

export default class ApiPlanOtaService extends BaseService {
  getApiPlans = (
      {
        storeIds,
        otaIds,
        planName,
        status,
        checkInDate,
        checkOutDate,
        periodDateStart,
        periodDateEnd,
        registeredPerson,
        registrationDate,
        updateStatus,
        typePlans,
        openSalesStatus,
        openSaleTypes,
        page,
        size
      }) => {
    const data = {
      storeIds,
      otaIds,
      planName,
      status,
      checkInDate,
      checkOutDate,
      periodDateStart,
      periodDateEnd,
      registeredPerson,
      registrationDate,
      updateStatus,
      typePlans,
      openSalesStatus,
      openSaleTypes,
      page,
      size
    }
    return this.axios.post(API_ENDPOINTS.GET_LIST_API_PLAN, data);
  };

  getApiPlanDetail = ({apiPlanId}) => {
    return this.axios.get(API_ENDPOINTS.GET_API_PLAN_DETAIL.replace("{planId}", apiPlanId));
  }

  createApiPlan = (data) => {
    return this.axios.post(API_ENDPOINTS.ADD_API_PLAN, data);
  }

  updateApiPlan = (data) => {
    return this.axios.post(API_ENDPOINTS.UPDATE_API_PLAN.replace("{apiPlanId}", data.apiPlanId), data);
  }

  deleteApiPlanOta = (data) => {
    return this.axios.delete(API_ENDPOINTS.DELETE_API_PLAN_OTA.replace("{apiPlanOtaId}", data.apiPlanOtaId).replace("{apiPlanId}", data.apiPlanId));
  }

  syncDataPlanToSite = (data) => {
    return this.axios.post(API_ENDPOINTS.SYNC_PLAN_OTA.replace("{apiPlanOtaId}", data.apiPlanOtaId));
  }

  getSyncPlanStatus = (data) => {
    return this.axios.post(API_ENDPOINTS.GET_SYNC_PLAN_STATUS, data);
  };

  updateDataFromSite = (data) => {
    return this.axios.post(API_ENDPOINTS.UPDATE_PLAN_OTA.replace("{apiPlanOtaId}", data.apiPlanOtaId));
  };

  crawlAllPlan = ({ ...data }) => {
    return this.axios.post(API_ENDPOINTS.CRAWL_ALL_PLAN, {
      ...data,
    });
  };

  updateMultipleFromSite = (data) => {
    return this.axios.post(API_ENDPOINTS.UPDATE_MULTIPLE_PLAN_OTA, data);
  };

  openSaleFromSite = (data) => {
    return this.axios.post(API_ENDPOINTS.OPEN_SALE_PLAN, data);
  };
  
  stopSellingFromSite = (data) => {
    return this.axios.post(API_ENDPOINTS.STOP_SELLING_PLAN, data);
  };

  getApiPlanLinkPrices = (data) => {
    return this.axios.post(API_ENDPOINTS.PLAN_LINK_PRICES + `?storeId=${data.storeId}&otaId=${data.otaId}`);
  };

}
