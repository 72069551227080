const actions = {
    GET_CRAWL_RANKING_AND_SCORE_REQUEST: "GET_CRAWL_RANKING_AND_SCORE_REQUEST",
    GET_CRAWL_RANKING_AND_SCORE_SUCCESS: "GET_CRAWL_RANKING_AND_SCORE_SUCCESS",
    GET_CRAWL_RANKING_AND_SCORE_ERROR: "GET_CRAWL_RANKING_AND_SCORE_ERROR",

    CRAWL_RANKING_AND_SCORE_REQUEST: "CRAWL_RANKING_AND_SCORE_REQUEST",
    CRAWL_RANKING_AND_SCORE_SUCCESS: "CRAWL_RANKING_AND_SCORE_SUCCESS",
    CRAWL_RANKING_AND_SCORE_ERROR: "CRAWL_RANKING_AND_SCORE_ERROR",

    GET_INFORMATION_STORE_REQUEST: "CRAWL_RANKING_AND_SCORE/GET_INFORMATION_STORE_REQUEST",
    GET_INFORMATION_STORE_SUCCESS: "CRAWL_RANKING_AND_SCORE/GET_INFORMATION_STORE_SUCCESS",
    GET_INFORMATION_STORE_ERROR: "CRAWL_RANKING_AND_SCORE/GET_INFORMATION_STORE_ERROR",

    DELETE_INFORMATION_STORE_REQUEST: "CRAWL_RANKING_AND_SCORE/DELETE_INFORMATION_STORE_REQUEST",
    DELETE_INFORMATION_STORE_SUCCESS: "CRAWL_RANKING_AND_SCORE/DELETE_INFORMATION_STORE_SUCCESS",
    DELETE_INFORMATION_STORE_ERROR: "CRAWL_RANKING_AND_SCORE/DELETE_INFORMATION_STORE_ERROR",

    crawlRankingAndScore: (payload) => ({
        type: actions.CRAWL_RANKING_AND_SCORE_REQUEST,
        payload,
    }),

    getCrawlRankingAndScore: (payload) => ({
        type: actions.GET_CRAWL_RANKING_AND_SCORE_REQUEST,
        payload,
    }),

    getInformationStore: () => ({
        type: actions.GET_INFORMATION_STORE_REQUEST,
    }),

    deleteInformationStore: (payload) => ({
        type: actions.DELETE_INFORMATION_STORE_REQUEST,
        payload,
    }),
};
export default actions;