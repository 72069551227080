import { all, takeLatest, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import { crawlDataCollectionService} from "@iso/services";

export function* crawlDataCollection() {
  yield takeLatest(actions.CRAWL_DATA_COLLECTION_REQUEST, function* ({ payload }) {
    const {
      aggregationDate,
      otas,
      storeIds,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlDataCollectionService.crawlDataCollection, {
        aggregationDate,
        otas,
        storeIds,
        resolve,
        reject
      });
      yield put({
        type: actions.CRAWL_DATA_COLLECTION_SUCCESS,
        crawler: response,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.CRAWL_DATA_COLLECTION_ERROR });
      reject(e);
    }
  });
}

export function* getCrawlDataCollection() {
  yield takeLatest(actions.GET_CRAWL_DATA_COLLECTION_REQUEST, function* () {
    try {
      const report = yield call(crawlDataCollectionService.getCrawlDataCollection);
      yield put({
        type: actions.GET_CRAWL_DATA_COLLECTION_SUCCESS,
        crawler: report,
      });
    } catch (error) {
      yield put({
        type: actions.GET_CRAWL_DATA_COLLECTION_ERROR,
        error: error.response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(crawlDataCollection)]);
  yield all([fork(getCrawlDataCollection)]);
}
