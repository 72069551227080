const actions = {
  GET_STORES_DATA_REQUEST: "GET_STORES_DATA_REQUEST",
  GET_STORES_DATA_SUCCESS: "GET_STORES_DATA_SUCCESS",
  GET_STORES_DATA_ERROR: "GET_STORES_DATA_ERROR",

  GET_STORES_METADATA_REQUEST: "GET_STORES_METADATA_REQUEST",
  GET_STORES_METADATA_SUCCESS: "GET_STORES_METADATA_SUCCESS",
  GET_STORES_METADATA_ERROR: "GET_STORES_METADATA_ERROR",

  RESET_STORES_STORE: "RESET_STORES_STORE",

  GET_STORE_OTAS_REQUEST: "STORE/GET_STORE_OTAS_REQUEST",
  GET_STORE_OTAS_SUCCESS: "STORE/GET_STORE_OTAS_SUCCESS",
  GET_STORE_OTAS_ERROR: "STORE/GET_STORE_OTAS_ERROR",

  GET_STORE_BY_ID: "GET_STORE_BY_ID",
  GET_STORE_BY_ID_SUCCESS: "GET_STORE_BY_ID_SUCCESS",
  GET_STORE_BY_ID_ERROR: "GET_STORE_BY_ID_ERROR",
  RESET_OTA: "RESET_OTA",

  GET_ACCOUNT_IN_STORE_REQUEST: "STORE/GET_ACCOUNT_IN_STORE_REQUEST",
  GET_ACCOUNT_IN_STORE_SUCCESS: "STORE/GET_ACCOUNT_IN_STORE_SUCCESS",
  GET_ACCOUNT_IN_STORE_ERROR: "STORE/GET_ACCOUNT_IN_STORE_ERROR",

  getStoresData: (payload) => ({
    type: actions.GET_STORES_DATA_REQUEST,
    payload,
  }),

  getStoresMetaData: () => ({ type: actions.GET_STORES_METADATA_REQUEST }),

  getStoreOTAs: (storeId) => ({
    type: actions.GET_STORE_OTAS_REQUEST,
    storeId,
  }),

  getStoreById: (storeId) => ({
    type: actions.GET_STORE_BY_ID,
    storeId,
  }),

  rsOTA: () => ({
    type: actions.RESET_OTA,
  }),

  getAccountInStore: (payload) => ({
    type: actions.GET_ACCOUNT_IN_STORE_REQUEST,
    payload
  })
};

export default actions;
