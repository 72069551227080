import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class CrawlDataCollectionService extends BaseService {
  crawlDataCollection = (data) => {
    return this.axios.post(API_ENDPOINTS.CRAWL_DATA_COLLECTION, data);
  }

  getCrawlDataCollection = () => {
    return this.axios.get(API_ENDPOINTS.GET_CRAWL_DATA_COLLECTION);
  }
}
