import {getTeamInCharge} from "@iso/redux/team/saga";

const actions = {
  GET_TEAM_REQUEST: "TEAM/GET_TEAM_REQUEST",
  GET_TEAM_SUCCESS: "TEAM/GET_TEAM_SUCCESS",
  GET_TEAM_ERROR: "TEAM/GET_TEAM_ERROR",

  CREATE_TEAM_REQUEST: "TEAM/CREATE_TEAM_REQUEST",
  CREATE_TEAM_SUCCESS: "TEAM/CREATE_TEAM_SUCCESS",
  CREATE_TEAM_ERROR: "TEAM/CREATE_TEAM_ERROR",
  
  UPDATE_TEAM_REQUEST: "TEAM/UPDATE_TEAM_REQUEST",
  UPDATE_TEAM_SUCCESS: "TEAM/UPDATE_TEAM_SUCCESS",
  UPDATE_TEAM_ERROR: "TEAM/UPDATE_TEAM_ERROR",

  DETAIL_TEAM_REQUEST: "TEAM/DETAIL_TEAM_REQUEST",
  DETAIL_TEAM_SUCCESS: "TEAM/DETAIL_TEAM_SUCCESS",
  DETAIL_TEAM_ERROR: "TEAM/DETAIL_TEAM_ERROR",

  DELETE_TEAM_REQUEST: "TEAM/DELETE_TEAM_REQUEST",
  DELETE_TEAM_SUCCESS: "TEAM/DELETE_TEAM_SUCCESS",
  DELETE_TEAM_ERROR: "TEAM/DELETE_TEAM_ERROR",

  GET_PARENT_TEAM_REQUEST: "TEAM/GET_PARENT_TEAM_REQUEST",
  GET_PARENT_TEAM_SUCCESS: "TEAM/GET_PARENT_TEAM_SUCCESS",
  GET_PARENT_TEAM_ERROR: "TEAM/GET_PARENT_TEAM_ERROR",

  GET_TEAM_OPTION_REQUEST: "TEAM/GET_TEAM_OPTION_REQUEST",
  GET_TEAM_OPTION_SUCCESS: "TEAM/GET_TEAM_OPTION_SUCCESS",
  GET_TEAM_OPTION_ERROR: "TEAM/GET_TEAM_OPTION_ERROR",

  GET_PERSON_IN_CHARGE_REQUEST: "TEAM/GET_PERSON_IN_CHARGE_REQUEST",
  GET_PERSON_IN_CHARGE_SUCCESS: "TEAM/GET_PERSON_IN_CHARGE_SUCCESS",
  GET_PERSON_IN_CHARGE_ERROR: "TEAM/GET_PERSON_IN_CHARGE_ERROR",

  getTeams: (payload) => ({
    type: actions.GET_TEAM_REQUEST,
    payload,
  }),

  createTeam: (payload) => ({
    type: actions.CREATE_TEAM_REQUEST,
    payload,
  }),

  updateTeam: (payload) => ({
    type: actions.UPDATE_TEAM_REQUEST,
    payload,
  }),

  deleteTeam: (payload) => ({
    type: actions.DELETE_TEAM_REQUEST,
    payload,
  }),

  getTeamDetail: (payload) => ({
    type: actions.DETAIL_TEAM_REQUEST,
    payload,
  }),

  getParentTeams: () => ({
    type: actions.GET_PARENT_TEAM_REQUEST,
  }),

  getTeamOptions: () => ({
    type: actions.GET_TEAM_OPTION_REQUEST,
  }),

  getListPersonInCharge: () => ({
    type: actions.GET_PERSON_IN_CHARGE_REQUEST,
  }),
};

export default actions;
