import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Radio, Input, Checkbox, InputNumber, Select } from "antd";
import { useIntl } from "react-intl";
import { LayoutContentWrapper } from "@iso/components/PlanOta/PlanOta.styles"
import { colFullApi, leftLabelFullItem, itemLeftLabelFullApi } from "@iso/assets/styles/form.style";
import {
    ADVANCED_SETTING,
    fieldKeyRurubuApiPlanOption,
    PERCENT,
    YES,
} from "@iso/constants/api.constant";
import { isEmpty, values } from "lodash";
import { INFANT_KODOMO_RURUBU } from "@iso/constants/select.constant";

const KodomoRurubu = ({
    setFieldValue,
    dataRoom,
    tabKey,
    dataOtaTab,
    closeMoneyRegisterPopup,
    setEventKodomoSubmit,
    optionValues,
    listApiRoom
}) => {
    const { messages } = useIntl();
    const [form] = Form.useForm();
    const { Option } = Select;

    const [kodomoContentBody, setKodomoContentBody] = useState([]);
    const [childRateValue, setChildRateValue] = useState(null);
    const [childRateTypeValue, setChildRateTypeValue] = useState(null);
    const [childCategoryValue, setChildCategoryValue] = useState({
        childCategory1: kodomoRurubuInitialValue.childCategory1,
        childCategory2: kodomoRurubuInitialValue.childCategory2,
        childCategory3: kodomoRurubuInitialValue.childCategory3,
        childCategory4: kodomoRurubuInitialValue.childCategory4
    });

    const [priceTypeValue, setPriceTypeValue] = useState({
        priceType1: kodomoRurubuInitialValue.priceType1,
        priceType2: kodomoRurubuInitialValue.priceType2,
        priceType3: kodomoRurubuInitialValue.priceType3,
        priceType4: kodomoRurubuInitialValue.priceType4
    });

    useEffect(() => {
        const dataKodomo = dataOtaTab.kodomo[dataRoom.siteRoomId];

        if (!isEmpty(dataKodomo)) {
            form.resetFields();
            form.setFieldsValue(dataKodomo);

            setChildRateValue(dataKodomo.childRate)
            setChildRateTypeValue(dataKodomo.childRateType)
            setChildCategoryValue({
                childCategory1: dataKodomo.childCategory1,
                childCategory2: dataKodomo.childCategory2,
                childCategory3: dataKodomo.childCategory3,
                childCategory4: dataKodomo.childCategory4
            })
            setPriceTypeValue({
                priceType1: dataKodomo.priceType1,
                priceType2: dataKodomo.priceType2,
                priceType3: dataKodomo.priceType3,
                priceType4: dataKodomo.priceType4
            })
        } else {
            form.setFieldsValue(kodomoRurubuInitialValue)
        }
    }, [dataOtaTab, dataRoom.siteRoomId])

    useEffect(() => {
        let contentBody = [];
        for (let index = 1; index <= 4; index++) {
            contentBody.push(
                <>
                    <tr key={index}>
                        <td style={{ textAlign: 'left' }}>
                            <Form.Item
                                name={`childCategory${index}`}
                                label={``}
                            >
                                <Checkbox.Group
                                    name={`childCategory${index}`}
                                    onChange={(value) => {
                                        handleChangeCheckbox(`childCategory${index}`, value)
                                    }}
                                >
                                    <Checkbox value={INFANT_KODOMO_RURUBU[index - 1]}>
                                        {INFANT_KODOMO_RURUBU[index - 1]}

                                        <div>{messages[`page.apiPlanAddAndEdit.rurubu.kodomo.childCategory.infan${index}.text`]}</div>
                                    </Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                        </td>

                        <td>
                            <div className="d-flex flex-wrap">
                                <Form.Item
                                    name={`fee${index}`}
                                    label={``}
                                >
                                    <InputNumber
                                        name={`fee${index}`}
                                        style={{ width: "80px", marginRight: "10px" }}
                                        disabled={isEmpty(childCategoryValue[`childCategory${index}`])}
                                        min={0}
                                        max={priceTypeValue[`priceType${index}`] === PERCENT ? 100 : Infinity}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name={`priceType${index}`}
                                    label={``}
                                >

                                    <Select
                                        name={`priceType${index}`}
                                        listHeight={320}
                                        style={{ width: "120px" }}
                                        disabled={isEmpty(childCategoryValue[`childCategory${index}`])}
                                        onChange={(value) => {
                                            handleChangeSelect(`priceType${index}`, value)
                                        }}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.priceType) {
                                                return (
                                                    <Option
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.value}
                                                    </Option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Form.Item>

                            </div>
                        </td>

                        <td>
                            {(childRateTypeValue == ADVANCED_SETTING)
                                ?
                                <Form.Item
                                    name={`isCountAsRoomOccupancy${index}`}
                                    label={``}
                                >
                                    <Radio.Group
                                        name={`isCountAsRoomOccupancy${index}`}
                                        disabled={isEmpty(childCategoryValue[`childCategory${index}`])}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.isCountAsRoomOccupancy) {
                                                return (
                                                    <Radio value={option.value} key={index}>
                                                        <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                            {option.value}
                                                        </span>
                                                    </Radio>
                                                )
                                            }
                                        }
                                        )}
                                    </Radio.Group>
                                </Form.Item>
                                : (
                                    <div>{messages[`page.apiPlanAddAndEdit.rurubu.kodomo.detail.infan${index}.text`]}</div>
                                )}

                        </td>
                    </tr>

                    {(childRateTypeValue == ADVANCED_SETTING) && (
                        <tr style={{ textAlign: 'left' }}>
                            <td colspan="3">
                                <div>{messages["page.apiPlanAddAndEdit.rurubu.kodomo.Additional.title"]}</div>
                                <Form.Item
                                    name={`customDetails${index}`}
                                    label={``}
                                >
                                    <Input
                                        name={`customDetails${index}`}
                                        style={{ width: "60%" }}
                                        maxLength={50}
                                        disabled={isEmpty(childCategoryValue[`childCategory${index}`])}
                                    />
                                </Form.Item>
                            </td>
                        </tr>
                    )}
                </>
            )
        }
        setKodomoContentBody(contentBody)
    }, [childRateTypeValue, childCategoryValue, priceTypeValue]);

    const handleChangeRadio = (field, value) => {
        switch (field) {
            case 'childRate':
                if (value != YES) {
                    form.setFieldsValue(kodomoRurubuInitialValue)
                }
                break;
            case 'childRateType':
                form.setFieldsValue({
                    childCategory1: null,
                    fee1: 0,
                    priceType1: '円（実額）',
                    isCountAsRoomOccupancy1: 'はい',
                    customDetails1: null,
                    childCategory2: null,
                    fee2: 0,
                    priceType2: '円（実額）',
                    isCountAsRoomOccupancy2: 'はい',
                    customDetails2: null,
                    childCategory3: null,
                    fee3: 0,
                    priceType3: '円（実額）',
                    isCountAsRoomOccupancy3: 'いいえ',
                    customDetails3: null,
                    childCategory4: null,
                    fee4: 0,
                    priceType4: '円（実額）',
                    isCountAsRoomOccupancy4: 'いいえ',
                    customDetails4: null,
                })

                setChildCategoryValue({
                    childCategory1: kodomoRurubuInitialValue.childCategory1,
                    childCategory2: kodomoRurubuInitialValue.childCategory2,
                    childCategory3: kodomoRurubuInitialValue.childCategory3,
                    childCategory4: kodomoRurubuInitialValue.childCategory4
                })

                setPriceTypeValue({
                    priceType1: kodomoRurubuInitialValue.priceType1,
                    priceType2: kodomoRurubuInitialValue.priceType2,
                    priceType3: kodomoRurubuInitialValue.priceType3,
                    priceType4: kodomoRurubuInitialValue.priceType4
                })
                break;
            default:
                break;
        }
    }

    const handleChangeCheckbox = (field, value) => {
        setChildCategoryValue((prevChildCategoryValue) => ({
            ...prevChildCategoryValue,
            [field]: value,
        }));

        switch (field) {
            case 'childCategory1':
                form.setFieldsValue({
                    fee1: 0,
                    priceType1: '円（実額）',
                    isCountAsRoomOccupancy1: 'はい',
                    customDetails1: null,
                })
                break;
            case 'childCategory2':
                form.setFieldsValue({
                    fee2: 0,
                    priceType2: '円（実額）',
                    isCountAsRoomOccupancy2: 'はい',
                    customDetails2: null,
                })
                break;
            case 'childCategory3':
                form.setFieldsValue({
                    fee3: 0,
                    priceType3: '円（実額）',
                    isCountAsRoomOccupancy3: 'いいえ',
                    customDetails3: null,
                })
                break;
            case 'childCategory4':
                form.setFieldsValue({
                    fee4: 0,
                    priceType4: '円（実額）',
                    isCountAsRoomOccupancy4: 'いいえ',
                    customDetails4: null,
                })
                break;
            default:
                break;
        }
    }

    const handleChangeSelect = (field, value) => {
        setPriceTypeValue((prevPriceTypeValue) => ({
            ...prevPriceTypeValue,
            [field]: value,
        }));
    }

    const handleSubmitKodomo = (data) => {
        const arrayKodomo = dataOtaTab.kodomo;
        const dataSubmit = { ...kodomoRurubuInitialValue, ...data };

        if (!isEmpty(listApiRoom) && listApiRoom.length > 1) {
            listApiRoom.map((apiRoom) => {
                arrayKodomo[`${apiRoom.siteRoomId}`] = dataSubmit;
            })
        } else {
            arrayKodomo[`${dataRoom.siteRoomId}`] = dataSubmit;
        }

        setFieldValue(`otaFieldContent.${tabKey}.kodomo`, arrayKodomo)
        closeMoneyRegisterPopup();
    }

    return (
        <LayoutContentWrapper>
            <Form
                onFinish={handleSubmitKodomo}
                colon={false}
                form={form}
                initialValues={kodomoRurubuInitialValue}
            >
                <Row justify="start" className="mb-25px">
                    <Col {...colFullApi}>
                        <Form.Item
                            name="childRate"
                            label={messages["page.apiPlanAddAndEdit.rurubu.kodomo.childRate.title"]}
                            {...itemLeftLabelFullApi}
                            className="kodomo-rurubu"
                        >
                            <Radio.Group
                                name="childRate"
                                onChange={(e) => {
                                    handleChangeRadio('childRate', e.target.value)
                                    setChildRateValue(e.target.value)
                                }}
                            >
                                {optionValues.map((option, index) => {
                                    if (option.fieldKey === fieldKeyRurubuApiPlanOption.childRate) {
                                        return (
                                            <Radio value={option.value} key={index}>
                                                <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                    {option.value}
                                                </span>
                                            </Radio>
                                        )
                                    }
                                }
                                )}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                {(childRateValue == YES) && (
                    <>
                        <Row justify="start" className="mb-25px">
                            <Col {...colFullApi}>
                                <Form.Item
                                    name="childRateType"
                                    label={messages["page.apiPlanAddAndEdit.rurubu.kodomo.childRateType.title"]}
                                    {...itemLeftLabelFullApi}
                                    className="kodomo-rurubu"
                                >
                                    <Radio.Group
                                        name="childRateType"
                                        onChange={(e) => {
                                            handleChangeRadio('childRateType', e.target.value)
                                            setChildRateTypeValue(e.target.value)
                                        }}
                                    >
                                        {optionValues.map((option, index) => {
                                            if (option.fieldKey === fieldKeyRurubuApiPlanOption.childRateType) {
                                                return (
                                                    <Radio value={option.value} key={index}>
                                                        <span style={{ marginLeft: 10, marginRight: 10 }}>
                                                            {option.value}
                                                        </span>
                                                    </Radio>
                                                )
                                            }
                                        }
                                        )}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="start" className="mb-25px">
                            <Col {...colFullApi}>
                                <table
                                    cellSpacing="0"
                                    className="kodomo-rurubu-table"
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    width: '30%'
                                                }}
                                            >
                                                <p>
                                                    {messages["page.apiPlanAddAndEdit.rurubu.kodomo.childCategory.title"]}
                                                </p>
                                            </th>
                                            <th
                                                style={{
                                                    width: '30%'
                                                }}
                                            >
                                                <p>
                                                    {messages["page.apiPlanAddAndEdit.rurubu.kodomo.fee.title"]}
                                                </p>
                                            </th>
                                            <th
                                                style={{
                                                    width: '40%'
                                                }}
                                            >
                                                <p>
                                                    {(childRateTypeValue == ADVANCED_SETTING)
                                                        ? messages["page.apiPlanAddAndEdit.rurubu.kodomo.includedInRoomPricing.title"]
                                                        : messages["page.apiPlanAddAndEdit.rurubu.kodomo.detail.title"]
                                                    }
                                                </p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {kodomoContentBody}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </>
                )}


                <Row justify="center" style={{ marginTop: "20px" }}>
                    <Button onClick={closeMoneyRegisterPopup} style={{ marginRight: "10px" }}>
                        {messages["page.Account.buttonCancel"]}
                    </Button>
                    <Button type="primary" htmlType="submit" onClick={() => setEventKodomoSubmit(true)}>
                        {messages["popup.selectStore.decision"]}
                    </Button>
                </Row>
            </Form>
        </LayoutContentWrapper>
    );
};

const kodomoRurubuInitialValue = {
    childRate: 'いいえ',
    childRateType: '標準設定',
    childCategory1: null,
    fee1: 0,
    priceType1: '円（実額）',
    isCountAsRoomOccupancy1: 'はい',
    customDetails1: null,
    childCategory2: null,
    fee2: 0,
    priceType2: '円（実額）',
    isCountAsRoomOccupancy2: 'はい',
    customDetails2: null,
    childCategory3: null,
    fee3: 0,
    priceType3: '円（実額）',
    isCountAsRoomOccupancy3: 'いいえ',
    customDetails3: null,
    childCategory4: null,
    fee4: 0,
    priceType4: '円（実額）',
    isCountAsRoomOccupancy4: 'いいえ',
    customDetails4: null,
}

export default KodomoRurubu;
