import actions from "./actions";

const initialState = {
  error: null,
  total: 0,
  loading: false,
  detailTeamLoading: false,
  createTeamLoading: false,
  updateTeamLoading: false,
  deleteTeamLoading: false,
  teams: [],
  parentTeams: [],
  teamDetail: [],
  teamOptions: [],
  personInChargeList: [],
};

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.total,
        teams: action.teams
      };
    case actions.GET_TEAM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actions.CREATE_TEAM_REQUEST:
      return {
        ...state,
        createTeamLoading: true,
      };
    case actions.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        createTeamLoading: false,
      };
    case actions.CREATE_TEAM_ERROR:
      return {
        ...state,
        createTeamLoading: false,
        error: action.error,
      };

    case actions.DETAIL_TEAM_REQUEST:
      return {
        ...state,
        detailTeamLoading: true,
      };
    case actions.DETAIL_TEAM_SUCCESS:
      return {
        ...state,
        detailTeamLoading: false,
        teamDetail: action.team
      };
    case actions.DETAIL_TEAM_ERROR:
      return {
        ...state,
        detailTeamLoading: false,
      };

    case actions.UPDATE_TEAM_REQUEST:
      return {
        ...state,
        updateTeamLoading: true,
      };
    case actions.UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        updateTeamLoading: false,
      };
    case actions.UPDATE_TEAM_ERROR:
      return {
        ...state,
        updateTeamLoading: false,
        error: action.error,
      };

    case actions.DELETE_TEAM_REQUEST:
      return {
        ...state,
        deleteTeamLoading: true,
      };
    case actions.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        deleteTeamLoading: false,
      };
    case actions.DELETE_TEAM_ERROR:
      return {
        ...state,
        deleteTeamLoading: false,
        error: action.error,
      };

    case actions.GET_PARENT_TEAM_REQUEST:
      return {
        ...state,
      };
    case actions.GET_PARENT_TEAM_SUCCESS:
      return {
        ...state,
        parentTeams: action.teams
      };
    case actions.GET_PARENT_TEAM_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case actions.GET_TEAM_OPTION_REQUEST:
      return {
        ...state,
      };
    case actions.GET_TEAM_OPTION_SUCCESS:
      return {
        ...state,
        teamOptions: action.teams
      };
    case actions.GET_TEAM_OPTION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.GET_PERSON_IN_CHARGE_REQUEST:
      return {
        ...state,
      };
    case actions.GET_PERSON_IN_CHARGE_SUCCESS:
      return {
        ...state,
        personInChargeList: action.personInChargeList
      };
    case actions.GET_PERSON_IN_CHARGE_ERROR:
      return {
        ...state,
        error: action.error,
      };
    
    default:
      return state;
  }
}
